<div class="evaluationresult-container">
    <div class="row">
        <div class="col-12"  style="padding: 20px 0;">
            <p class="title">EVALUATION DURATION</p>
        </div>

        <div class="col-12">
            <p class="time-cls">{{hours}}<span>H</span> &nbsp;&nbsp;{{minutes}}<span>M</span>&nbsp;&nbsp;{{seconds}}<span>S</span></p>
        </div>
        <div class="col-12" style="padding: 20px 0;">
            <p class="title">Evaluation Status</p>
        </div>
        <div class="col-12" style="text-align: center;">
            <mat-radio-group class="row" aria-label="Select an option" [(ngModel)]="evaluation_status" (change)="changeevent()"
            >
            <mat-radio-button  [value]="label1" [disabled]="passbtn">
            <span class="radiotext"> {{label1}}</span>
            </mat-radio-button>

            <mat-radio-button  [value]="label2" [disabled]="failbtn">
            <span class="radiotext"> {{label2}}</span>
            </mat-radio-button>
            
            </mat-radio-group>
        </div>

        <div class="col-12" style="padding: 40px 0 0 0;">
            <button mat-button class="close-btn" (click)="submit()" [disabled]="submitbtn"> SUBMIT </button>
        </div>
    </div>
</div>