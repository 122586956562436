<div class="spinner_overlay" *ngIf="loadspinner===true">
</div>
<mat-spinner class="spinner" *ngIf="loadspinner===true"></mat-spinner>


<div class="filtermaincontainer" id="filtermaincontainerid" (keydown)="EnterSubmit($event)">
  <div class="row headerrow" *ngIf="type==='pdf'">

  <div class="col-10">
  </div>
  <div class="col-2 cleariconcol">
    <mat-icon class="clearicon" alt="clear" (click)="cancelbtn()">clear</mat-icon>
  </div>



    <div class="col-12" syle="height:100%;" id='viewtranscript-print'>

      <!-- <iframe id="ifrm" style="width: 100%; height: 83vh;"></iframe> -->

      <pdf-viewer [src]="base64response12" [show-all]="false" [page]="page"
        (after-load-complete)="afterLoadComplete($event)" [original-size]="false" [render-text]="true"></pdf-viewer>
    </div>

    <div class="col-12" style="text-align: center;" *ngIf="showbutton===true">
      <p class="labelname">{{ page }} / {{ totalPages }}</p>
      <button mat-button class="Filterbtnpagination" (click)="previousPage()" [disabled]="page === 1">
        <mat-icon>chevron_left</mat-icon>
      </button>
      &nbsp;
      <button mat-button class="Filterbtnpagination" (click)="nextPage()"
        [disabled]="totalPages === 1 || page === totalPages">
        <mat-icon>chevron_right</mat-icon>
      </button>
    </div>

    <div class="col-12" style="text-align:center" *ngIf="totalPages === 1 || page === totalPages">
      <br>
      <button mat-button class="Filterbtn" (click)="Submit()" *ngIf="showbutton===true && btntype==='Launch'">
        <mat-icon class="filterbtnicon">done</mat-icon>
        Submit
      </button>

      <button mat-button class="Filterbtn" (click)="cancelbtn()" *ngIf="showbutton===true && btntype!='Launch'">
        Close
      </button>
    </div>
  </div>






  <div class="row headerrow" *ngIf="type!='pdf'">

    <div class="col-10">
    </div>
    <div class="col-2 cleariconcol">
      <mat-icon class="clearicon" alt="clear" (click)="cancelbtn()">clear</mat-icon>
    </div>

    <div class="col-12" style="height:100%;text-align:center" id='viewtranscript-print'>
      <video controls width="600" height="450" #videoPlayer>
        <source [src]="base64response12" type="video/mp4">
      </video>
    </div>

    <div class="col-12" style="text-align:center">
      <br>
      <button mat-button class="Filterbtn" (click)="Submit()" *ngIf="btntype==='Launch'">
        <mat-icon class="filterbtnicon">done</mat-icon>
        Submit
      </button>

      <button mat-button class="Filterbtn" (click)="cancelbtn()" *ngIf="btntype!='Launch'">
        Close
      </button>
    </div>



  </div>


</div>