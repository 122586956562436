import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiServiceService } from 'src/app/Services/api-service.service';
import { EnrollmentComponent } from 'src/app/Administrator/assignable-content/enrollment/enrollment.component'

@Component({
  selector: 'app-enroll-course-filter',
  templateUrl: './enroll-course-filter.component.html',
  styleUrls: ['./enroll-course-filter.component.scss']
})
export class EnrollCourseFilterComponent implements OnInit {
  filterform: FormGroup;
  companylistdata: any = [""];
  groupslist: any = [];
  statuslist: any = [""];
  statuslist2: any = [""];
  loadspinner: any = false;
  apibodycontent: any;
  disablebutton:any;
  courseId: any;
  isDisabled: boolean;
  constructor(@Inject(MAT_DIALOG_DATA) public data: string, private formBuilder: FormBuilder, private apiService: ApiServiceService, public dialog: MatDialogRef<EnrollmentComponent>) { 
    this.courseId= data['courseId'];
    this.filterform = this.formBuilder.group({
      CompanyId: [''],
      Groups: [''],
      Name: [''],
      Login: [''],
      email: [''],
      CompanyStatus: ['']
    });
    this.apicallgetdefaultdropdown();
    
  }

  ngOnInit(): void {
  }
  apicallgetdefaultdropdown() {
    this.loadspinner = true;
    const endpoint1 = 'lookup';
    this.apiService.Simpleget(endpoint1, 'GET')
      .subscribe((response) => {
        this.loadspinner = false;
        this.companylistdata = response["companies"];
        this.statuslist = 
      [
          { "status_id": 3, "statusName": "Not Started" },
          { "status_id": 4, "statusName": "Completed" },
          { "status_id": 5, "statusName": "Failed" },
          { "status_id": 6, "statusName": "In Progress" },
          { "status_id": 6, "statusName": "Passed" }
        ]
        this.setdefaultdropdownvalues();
      },
        (err) => {
          this.loadspinner = false;
         // console.log(err)

        });
  }
  setdefaultdropdownvalues() {
    let cacheddata = localStorage.getItem('filterdata');
    if (cacheddata === '' || cacheddata === undefined || cacheddata === null) {
      this.disablebutton=true;
    }
    // if cached data present -- once filter btn was clicked
    else {
      this.disablebutton=false;
      let data = JSON.parse(cacheddata);
      this.filterform.controls['CompanyId'].setValue(data["Company (COID)"]);
      this.selectCompanyID(data["Company (COID)"],"");
      this.filterform.controls['Groups'].setValue(data["Groups"]);
      this.filterform.controls['Name'].setValue(data["Name"]);
      this.filterform.controls['Login'].setValue(data["Login"]);
      this.filterform.controls['email'].setValue(data["Email ID"]);
      this.filterform.controls['CompanyStatus'].setValue(data["Status"]);
      this.clearfilterfunction();
    }
    let compId = localStorage.getItem('procCompId');
    let compName = localStorage.getItem('proccompanyName');
    let rolecacheddata = localStorage.getItem('role');
    if (rolecacheddata != "System Administrator") {
      let data = compName + ' ' + '(' + compId + ')'
      this.filterform.controls['CompanyId'].setValue(data);
      this.selectCompanyID(data,"");
      this.disablebutton = false;
      this.isDisabled=true;
    }
    else {
      this.isDisabled=false;
    }
  }

  clearfilterfunction() {
    // if filterchip to remove filter is selcted remove the data on modal
    let clearfilteritemkey = JSON.parse(localStorage.getItem('clearfilteritemkey'));
    if (clearfilteritemkey === "" || clearfilteritemkey === undefined || clearfilteritemkey === null) {
    }
    else {
      for (let i = 0; i < clearfilteritemkey.length; i++) {
        if(clearfilteritemkey[i]==="Company (COID)"){
          this.filterform.controls['CompanyId'].setValue("");
        }
        else if(clearfilteritemkey[i]==="Groups"){
          this.filterform.controls['Groups'].setValue("");
        }
        else if(clearfilteritemkey[i]==="Name"){
          this.filterform.controls['Name'].setValue("");
        }
        else if(clearfilteritemkey[i]==="Login"){
          this.filterform.controls['Login'].setValue("");
        }
        else if(clearfilteritemkey[i]==="Email ID"){
          this.filterform.controls['email'].setValue("");
        }
        else if(clearfilteritemkey[i]==="Status"){
          this.filterform.controls['CompanyStatus'].setValue("");
        }
        else{
          this.filterform.controls[clearfilteritemkey[i]].setValue("");
        }

      }
    }
  }


  
  selectCompanyID(value, property) {
    if (value === "" || value === undefined || value === null) {
      // if none select set value as ''
      this.filterform.controls['CompanyId'].setValue("");
      this.groupslist = [];
   
      this.filterform.controls['Groups'].setValue("");
    
      this.dynamicEnableDisablebtn();

    }
    else {
      this.disablebutton = false;
    
      // change selected name to ID to call api
      let companylistdataid = "";
      for (let i = 0; i < this.companylistdata.length; i++) {
        if (this.companylistdata[i].companyName + ' ' + '(' + this.companylistdata[i].companyId + ')' === value) {
          companylistdataid = this.companylistdata[i].companyId;
        }
      }
      // on select Company (COID) -- get all other dropdown data ---- api call
      this.loadspinner = true;
      const endpoint1 = 'lookup';
      const queryparameter1 = companylistdataid;
      this.apiService.getwithparam(queryparameter1, endpoint1, 'GET')
        .subscribe((response) => {
          this.loadspinner = false;
          this.groupslist = response["groups"];
        
          if (property === "onchange") {
            this.filterform.controls['Groups'].setValue("");
           
          }

        },
          (err) => {
            this.loadspinner = false;
            //  console.log(err)

          });
    }
  }

  selectGroups(value) {
    let formcontrolname = "Groups";
    this.dynamicEnableDisablebtnDrodown(value, formcontrolname);
  }

  selectStatusName(value){
    if(value==="" || value === undefined || value===null){
      // if none select set value as ''
      this.filterform.controls['CompanyStatus'].setValue("");
      this.dynamicEnableDisablebtn();
    }
    else{
      this.disablebutton=false;
    }
  }

   // on keyup enable filter button
   inputchangeEmail(value) {
    this.dynamicEnableDisablebtn();
  }

inputchangeName(value) {
  this.dynamicEnableDisablebtn();
}
  inputchangeLogin(value) {
    this.dynamicEnableDisablebtn();
  }

  
  cancelbtn() {
    // if filterdata not present --- on first load cancel - clear data
    let data = JSON.parse(localStorage.getItem('clearfilteritemkey'));
    if (data === "" || data === undefined || data === null) {
    }
    else {
      let groupfilterdata = this.getbodycontent();
      localStorage.setItem('filterdata', JSON.stringify(groupfilterdata));
      localStorage.removeItem('clearfilteritemkey');
    }
    this.dialog.close({ method: 'close' })
    let element = document.getElementById("course-content")
    element.classList.remove('blur-content');
  }
  Filter(){
    if (this.filterform.valid) {
      localStorage.removeItem('filterdata');
      localStorage.removeItem('chipdisplayfilterdata');
      localStorage.removeItem('clearfilteritemkey');
      let filterdata = this.getbodycontent();
      localStorage.setItem('filterdata', JSON.stringify(filterdata));
      this.dialog.close({ data: filterdata, apibodycontent: this.apibodycontent, method: 'filter' });
    }

  }

  EnterSubmit(event){
    if(event.keyCode === 13){
     //this.Filter();
     this.dynamicEnableDisablebtn();
     if(this.disablebutton===true){
      event.preventDefault();
     }
     else{
      event.preventDefault();
      this.Filter();
     }

    }
  }

  getbodycontent() {
    let CompanyID = "";
    let rolecacheddata = localStorage.getItem('role');
    if (rolecacheddata != "System Administrator") {
      CompanyID = "";
    }
    else {
      CompanyID = this.filterform.value.CompanyId
    }

    let filterdata = {
      "Email ID": this.filterform.value.email.trim(),
      "Company (COID)": CompanyID,
      "Name": this.filterform.value.Name.trim(),
      "Login": this.filterform.value.Login.trim(),
      "Groups": this.filterform.value.Groups,
      "Status": this.filterform.value.CompanyStatus.trim(),
    }
    this.getapibodycontent();
    return filterdata

  }

  getapibodycontent() {
    let companylistdataid= "";
    console.log(this.companylistdata)
    for (let i = 0; i < this.companylistdata.length; i++) {
      if (this.companylistdata[i].companyName + ' ' +'('+ this.companylistdata[i].companyId + ')' === this.filterform.value.CompanyId) {
        
        companylistdataid = this.companylistdata[i].companyId;
        console.log(companylistdataid)
      }
    }
   console.log(companylistdataid)
    this.apibodycontent = {
      
      "companyId": companylistdataid,
      "courseId":this.courseId,
      "courseStatus": this.filterform.value.CompanyStatus.trim(),
      "displayName":  this.filterform.value.Name.trim(),
      "email": this.filterform.value.email.trim(),
      "groupName": this.filterform.value.Groups,
      "loginName": this.filterform.value.Login.trim(),
      "managerName": "",
      "roleName": "",
      "userStatus": ""
    }
  }

  checkformvalue(){
    let formempty;
    if(this.filterform.value.email.trim()==="" && this.filterform.value.CompanyId.trim()==="" &&
    this.filterform.value.Name.trim()==="" && this.filterform.value.Login.trim()===""  && 
    this.filterform.value.Groups.trim()==="" && this.filterform.value.CompanyStatus.trim()===""){
      formempty=true;
    }
    else {
      formempty = false;
    }
    return formempty;
  }
  dynamicEnableDisablebtn(){
    let checkformfield= this.checkformvalue();
    if(checkformfield===true){
      this.disablebutton=true;
    }
    else{
      this.disablebutton=false;
    }
  }
  dynamicEnableDisablebtnDrodown(value, formcontrolname) {
    if (value === "" || value === undefined || value === null) {
      // if none select set value as ''
      this.filterform.controls[formcontrolname].setValue("");
      this.dynamicEnableDisablebtn()
    }
    else {
      this.disablebutton = false;
     
    }
  }
}
