import { ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as $ from 'jquery';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { ApiServiceService } from 'src/app/Services/api-service.service';
import { CommondataserviceService } from 'src/app/Services/commondataservice.service';
import { SidemenudataserviceService } from 'src/app/Services/sidemenudataservice.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {
  @Output() searchInputvalue = new EventEmitter();
  @Output() selectcompanylistvalue = new EventEmitter();

  @Input() data: any;
  minisidebar: any;
  appPages: any;
  p: any = false;
  pi: any;
  showLevel1: any;
  showMenu: any;
  screenwidth: any;
  resizeObservable$: Observable<Event>
  resizeSubscription$: Subscription;
  logo: any;
  name: any;
  role: any;
  companylistdata: any;
  filterValue: any = '';
  modeSelect: any;
  rolearray: any;
  headerrole: any;
  a: any;
  header_breadcrumb: any;
  breadecrumpservicedata: any;
  commondata: any;
  static breadcrumb_data;
  impersonate_div: any;
  footerlist: any;
  cacheddata: any;
  SETTING_MENU: any;
  hideHeader: any = false;

  ImpersonatedUser: any;
  companylogo: string;
  constructor(private router: Router, private apiService: ApiServiceService, private commonService: CommondataserviceService, private activatedRoute: ActivatedRoute, private SidemenudataserviceService: SidemenudataserviceService, private zone: NgZone) {
    const currentRoute = this.router.url;
    const currentRouteVal = currentRoute.split("/").pop()
    if (currentRouteVal == "AccessDenied") {
      this.hideHeader = false;
    } else {
      this.hideHeader = true;
    }
    this.impersonate_div = JSON.parse(localStorage.getItem("impersonatemode"));
    this.footerlist = this.SidemenudataserviceService.GetSidemenuDatafooter();
    let impersonateName = this.commonService.getimpersonate_user();
    if(impersonateName != undefined){
      var match = impersonateName.split(',');
      var impersonateValue=match[1] + ' ' + match[0];
      this.ImpersonatedUser = impersonateValue;
    }
    if (this.impersonate_div === true) {
      localStorage.setItem('role', "Learner");
      localStorage.setItem('loginRole', "Learner");
      document.querySelector("body").style.cssText = "--formcursorval: not-allowed";
      document.querySelector("body").style.cssText = "--formpointerval: none";
      this.SidemenudataserviceService.GetSidemenuData();
    }
    this.appPages = this.SidemenudataserviceService.GetSidemenuData();
    Window["myComponent"] = this;
    // console.log( console.log(this.router.url.split('=')[1]));
    this.activatedRoute.queryParamMap.subscribe(params => {
     // this.AfterViewInitfn();
      this.a = params;
      let current_val = this.a.params;
      current_val = Object.keys(current_val).length;
      // console.log(this.a.params.menuTitle)

      if (current_val === 1) {
        //this.headerrole = this.a.params.menuTitle;
        this.headerrole = this.commonService.getName(this.a.params.menuTitle);
        // this.header_breadcrumb = this.a.params.menuTitle;
        this.header_breadcrumb = this.commonService.getName(this.a.params.menuTitle);
        let data = localStorage.getItem("selectTabBreadCrumbs");
        let data1 = localStorage.getItem("selectTabIndexName");
        if (data === "" || data === undefined || data === null) {
          setTimeout(() => {
            document.getElementById("headerrole").innerHTML = this.headerrole;
            let spandata = this.getBreadcrumbdata(this.header_breadcrumb);
            document.getElementById("header_breadcrumb").innerHTML = spandata;
            let tooltipdata = "";
            tooltipdata += '<div>' + this.headerrole + '<br>' + spandata;
            document.getElementById("tooltiptextId").innerHTML = tooltipdata;
          }, 100);
        }
        else {
          this.headerrole = this.commonService.getName(data1);
          this.header_breadcrumb = data;
          setTimeout(() => {
            document.getElementById("headerrole").innerHTML = this.headerrole;
            let spandata = this.getBreadcrumbdata(this.header_breadcrumb);
            document.getElementById("header_breadcrumb").innerHTML = spandata;
            let tooltipdata = "";
            tooltipdata += '<div>' + this.headerrole + '<br>' + spandata;
            document.getElementById("tooltiptextId").innerHTML = tooltipdata;
          }, 100);
        }


      } else {
        let currentUrl = this.router.url;
        var array = currentUrl.split("/");
        //  console.log(array);

        var total_len = array.length;
        //this.headerrole = array[total_len - 1];
        this.headerrole = this.commonService.getName(array[total_len - 1]);
        var sparseArray = [];
        for (var i = 0; i < array.length; i++) {
          array[i] = this.commonService.getName(array[i]);

          sparseArray.push(array[i]);
        }
        sparseArray = sparseArray.filter(item => item);
        this.header_breadcrumb = sparseArray.join(' > ');
        setTimeout(() => {
          document.getElementById("headerrole").innerHTML = this.headerrole;
          document.getElementById("header_breadcrumb").innerHTML = this.header_breadcrumb;
          let tooltipdata = "";
          tooltipdata += '<div>' + this.headerrole + '<br>' + this.header_breadcrumb;
          document.getElementById("tooltiptextId").innerHTML = tooltipdata;
        }, 100);
      }
    });

    // this.companylistdata = [
    //   { value: 'All Companies', viewValue: 'All Companies' },
    //   { value: 'A & L Constructions(AALC)', viewValue: 'A & L Constructions(AALC)' },
    //   { value: 'AJ Ventures Inc(INVJ)', viewValue: 'AJ Ventures Inc(INVJ)' },
    // ];

     // get default value on load companylist -- api call
     const endpoint1 = 'lookup';
     this.apiService.Simpleget(endpoint1, 'GET')
       .subscribe((response) => {
         this.companylistdata = response["companies"];
            // get cached cpmpanylist
            let cached_companylistdata = localStorage.getItem('globalcompanyIDselected');
            if (cached_companylistdata === null || cached_companylistdata === '' || cached_companylistdata === undefined) {
              // this.modeSelect = this.companylistdata[0].companyId;
            }
            else {
              this.modeSelect = cached_companylistdata;
            }
       },
         (err) => {
           
         });

 

    // get search value
    let cached_searchvalue = localStorage.getItem('searchvalue');
    if (cached_searchvalue === null || cached_searchvalue === '' || cached_searchvalue === undefined) {

    }
    else {
      this.filterValue = cached_searchvalue;
    }

    this.commondata = this.commonService.getsidebarHeaderData();
    this.logo = this.commondata["profile_pic"]
    this.name = this.commondata["name"];
    this.rolearray = this.commondata["rolearray"];

    // get cached role
    this.cacheddata = localStorage.getItem('role');
    if (this.cacheddata === null || this.cacheddata === '' || this.cacheddata === undefined) {
      this.role = this.commondata["role"];
    }
    else {
      this.role = localStorage.getItem('role');
    }
  }

  updateHeaderBreadcrumb(value, data) {
    if (value === "" || value === null || value === undefined) {
    }
    else {
      this.headerrole = this.commonService.getName(value);
      this.header_breadcrumb = this.commonService.getName(data);
      HeaderComponent.breadcrumb_data = this.header_breadcrumb;
      setTimeout(() => {
        document.getElementById("headerrole").innerHTML = this.headerrole;
        let spandata = this.getBreadcrumbdata(this.header_breadcrumb);
        document.getElementById("header_breadcrumb").innerHTML = spandata;
        // HeaderComponent.breadcrumb_data = spandata;
        let tooltipdata = "";
        tooltipdata += '<div>' + this.headerrole + '<br>' + spandata;
        document.getElementById("tooltiptextId").innerHTML = tooltipdata;
      }, 100);
    }
  }


  getBreadcrumbdata(header_breadcrumb) {
    let spandata = "";
    let array_p = [];
    for (let i = 0; i < header_breadcrumb.length; i++) {
      if (header_breadcrumb[i] === '>') {
        array_p = header_breadcrumb.split(">");
      }
    }

    // if array consist ">" ie length>0
    if (array_p.length > 0) {
      for (let i = 0; i < array_p.length; i++) {
        spandata += '<span style="cursor: pointer;" onclick="Window.myComponent.breadcrumbclick(\'' + array_p[i] + '\')">' + array_p[i] + '</span>';
        // spandata += array_p[i] + '<span>'
        if (i < array_p.length - 1) {
          spandata += ' > ';
        }
      }
    }

    else {
      spandata += '<span style="cursor: pointer;" onclick="Window.myComponent.breadcrumbclick(\'' + header_breadcrumb + '\')">' + header_breadcrumb + '</span>';
    }
    return spandata;
  }
  reloadcomponent() {
    //cache value for display breadcrumb and refresh component
    let data = localStorage.getItem("selectTabIndexName");
    let data1 = localStorage.getItem("selectTabBreadCrumbs");
    let selectTabIndex = localStorage.getItem("selectTabIndex");

    let currentUrl = this.router.url.split("?");
    let menutitle = this.router.url.split("=");
    console.log(this.router.url);
    console.log(currentUrl)
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl[0]], { queryParams: { menuTitle: menutitle[1] } });
    localStorage.setItem("selectTabIndex", selectTabIndex);
    localStorage.setItem("selectTabIndexName", data);
    localStorage.setItem("selectTabBreadCrumbs", data1);
  }

  breadcrumbclick(text) {
    // console.log(text);
    text = text.trim();
    let p = this.commonService.getbreadrumbclickrouteName(text);
    let q = this.commonService.getbreadrumbmenutitleName(text);
    let tab_val;
    //console.log("............", text);
    if (p === undefined) {

      this.activatedRoute.queryParamMap.subscribe(params => {

        this.a = params;
        let current_val = this.a.params;
        current_val = Object.keys(current_val).length;
        // console.log(this.a.params.menuTitle);
        if (this.a.params.menuTitle === "Users") {
          //  console.log(window.location.href);
          var str = window.location.href;
          var words = str.split("?");
          // console.log(words);
          var lastword = words[0].split("/").pop();
          //  console.log(lastword);
          if (lastword === "viewmanager") {
            text = "Managed Users List";
            p = this.commonService.getbreadrumbclickrouteName(text);
            q = this.commonService.getbreadrumbmenutitleName(text);
            tab_val = 2;
          }
          else if (lastword === "viewevaluator") {
            text = "Evaluator List";
            p = this.commonService.getbreadrumbclickrouteName(text);
            q = this.commonService.getbreadrumbmenutitleName(text);
            tab_val = 3;
          }
          else if (lastword === "vieworeditgroup") {
            text = "View Group Details";
            p = this.commonService.getbreadrumbclickrouteName(text);
            q = this.commonService.getbreadrumbmenutitleName(text);
            tab_val = 1;
          } else if (lastword === "vieworedit_User" || lastword === "addtask") {
            if (text != 'QR Code' && text!='Shared Records') {
              text = "User Details";
              p = this.commonService.getbreadrumbclickrouteName(text);
              q = this.commonService.getbreadrumbmenutitleName(text);
              tab_val = 0;
            }
          }
          else if (lastword === 'viewassignment') {
            text = "User Details";
            p = this.commonService.getbreadrumbclickrouteName(text);
            q = this.commonService.getbreadrumbmenutitleName(text);
            tab_val = 0;
          }
          else {
            // text = "User Details";
            // p = this.commonService.getbreadrumbclickrouteName(text);
            // q = this.commonService.getbreadrumbmenutitleName(text);
            // tab_val = 0;
            //  this.reloadcomponent();

          }

        } else if (this.a.params.menuTitle === "Announcements") {

          //  console.log(window.location.href);
          var str = window.location.href;
          var words = str.split("?");
          // console.log(words);
          var lastword = words[0].split("/").pop();
          // console.log(lastword);

          if (lastword === "Vieworedittermsconditions") {
            text = "Terms & Conditions Details";
            p = this.commonService.getbreadrumbclickrouteName(text);
            q = this.commonService.getbreadrumbmenutitleName(text);
            tab_val = 0;
            localStorage.setItem("termsconditions_index", '0');
          } else if (lastword == "Vieworeditannouncement") {
            text = "Announcement Details";
            p = this.commonService.getbreadrumbclickrouteName(text);
            q = this.commonService.getbreadrumbmenutitleName(text);
            tab_val = 0;
            localStorage.setItem("announcement_index", '0');
          }

        }
        else if (this.a.params.menuTitle === "CompanyManagement") {
          //  console.log(window.location.href);
          var str = window.location.href;
          var words = str.split("?");
          //  console.log(words);
          var lastword = words[0].split("/").pop();
          //   console.log(lastword);
          if (lastword === "View_Company") {
            //   console.log("............", HeaderComponent.breadcrumb_data);
            var str1 = HeaderComponent.breadcrumb_data;
            var words1;
            if (str1 === undefined) {
              words1 = "";
            }
            else {
              words1 = str1.split(" > ");
            }

            // for groupstat breadcrumb
            let cachedcompanyname = localStorage.getItem("companymanagecompanynane");
            if (words1.length === 4 && words1[2] === "Group Stats" && text.trim() === cachedcompanyname) {
              p = "CompanyManagement/View_Company";
              q = "CompanyManagement";
              tab_val = 0;
              localStorage.removeItem("isselected_company");
            }

            else if (words1.length === 4 && words1[2] === "Group Stats" && text.trim() != cachedcompanyname) {
               // do nothing
            }
            else if (text.trim() === "Active Users" ||text.trim() === "Inactive Users" || text.trim() === "Suspended Users" || text.trim() === "Total License Users" || text.trim() === "Archived Users" || text.trim() === "Company Admins" || text.trim() === "Managers" || text.trim() === "Evaluators" || text.trim() === "Proctors" && words1[2] === "User Stats") {
              // do nothing
            } else if (words1[2] === "User Stats"  && text.trim() != cachedcompanyname) {
              // // do nothing
            }else if (words1[2] === "User Stats"  && text.trim() === 'User Stats') {
              p = "CompanyManagement/View_Company";
              q = "CompanyManagement";
              tab_val = 1;
              localStorage.setItem("isselected_company", "true");
            } else {
              text = "Company Details";
              p = this.commonService.getbreadrumbclickrouteName(text);
              q = this.commonService.getbreadrumbmenutitleName(text);
              tab_val = 0;
              localStorage.removeItem("isselected_company");
            }

          } else {
            p = "CompanyManagement/View_Company";
            q = "CompanyManagement";
            tab_val = 0;
            localStorage.removeItem("isselected_company");
          }
        } else if (this.a.params.menuTitle === "AssignableContent") {
          // console.log(window.location.href);
          var str = window.location.href;
          var words = str.split("?");
          //  console.log(words);
          var lastword = words[0].split("/").pop();
          //console.log(lastword);

          if (lastword === "Vieworeditcourse") {
            text = "Vieworeditcourse";
            p = this.commonService.getbreadrumbclickrouteName(text);
            q = this.commonService.getbreadrumbmenutitleName(text);
            tab_val = 0;
          } else if (lastword === "Vieworeditcurriculam") {
            text = "Vieworeditcurriculam";
            p = this.commonService.getbreadrumbclickrouteName(text);
            q = this.commonService.getbreadrumbmenutitleName(text);
          } else if (this.a.params.menuTitle === "Tasks") {
            //  console.log(window.location.href);
            var str = window.location.href;
            var words = str.split("?");
            //   console.log(words);
            var lastword = words[0].split("/").pop();
            //  console.log(lastword);

            if (lastword === "createtasklist") {
              text = "createtasklist";
              p = this.commonService.getbreadrumbclickrouteName(text);
              q = this.commonService.getbreadrumbmenutitleName(text);
              tab_val = 0;
            }
          }
        }
        else if (this.a.params.menuTitle === "Tasks") {
          // console.log(window.location.href);
          var str = window.location.href;
          var words = str.split("?");
          var lastword = words[0].split("/").pop();
          if (lastword == "editorviewlistname") {
            var str1 = HeaderComponent.breadcrumb_data;
            var words1 = str1.split(" > ");
            // console.log(words1)
            if (text != words1[words1.length - 1]) {
              p = "Tasks/viewtask";
              q = "Tasks";
            }
          } else if (lastword == "createtasklistname") {
            text = "Task List Name";
            p = this.commonService.getbreadrumbclickrouteName(text);
            q = this.commonService.getbreadrumbmenutitleName(text);
          } else if (lastword == "admintaskrule") {
            if (text != 'Task Rule') {
              text = "Task List Name";
              p = this.commonService.getbreadrumbclickrouteName(text);
              q = this.commonService.getbreadrumbmenutitleName(text);
              //   console.log(p)
            }
          }
          else {
            window.location.reload();
            //this.reloadcomponent();
          }

        }
      })
    } else {
      if (text === "Users" || text === "Company Management" || text === "User Details" || text === "Companies List" || text === "View User" || text === "Edit User" || text === "Tasks" || text === "Company Details" || text === "Content List" || text == 'Assignments' || text == 'Available'
        || text === "Exam Management" || text === "View Exam" || text === "Edit Exam" || text === "Assignable Content") {
        tab_val = 0;
      } else if (text === "Groups" || text === "User Stats" || text === "Assignment" || text === "License Pending" || text === "Task Lists" || text === "Assignment List" || text == 'Assigned' || text == 'Createquestion'
        || text === "Questions"
      ) {
        localStorage.setItem("selectTabIndex", '1');
        localStorage.setItem("redirectpage", "createtasklistscreen");
        tab_val = 1;
        localStorage.removeItem("isselected_company");
      } else if (text === "Manager" || text === "Catalogs" || text === "External Training" || text === "Group Stats" || text === "License Approved" || text == 'Curricula' || text === "Exam Pools") {
        tab_val = 2;
        localStorage.removeItem("viewgrouplistusers");
      } else if (text === "Badges" || text == 'Task List Name' || text === "Evaluator" || text === "Transcript" || text === "Question Pools") {
        tab_val = 3;
      } else if (text === "Audit Trails") {
        tab_val = 4;
      } else if (text == 'Announcements') {
        localStorage.setItem("back_TermsselectTabIndex", '0')
      } else if (text == "Terms & Conditions") {
        localStorage.setItem("back_TermsselectTabIndex", '1')
      } 
      else if (text == "Categories") {
        localStorage.setItem("back_TermsselectTabIndex", '2')
      }else if (text == 'Task List') {
        localStorage.setItem("fromlearnertaskistview", '1');
      }


    }
    this.zone.run(() => {
      this.commonService.set_tabvalue(tab_val, 'header');
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate([p], { queryParams: { menuTitle: q } });
    });

  }


  ngOnInit() {
    this.setdefaultpermission();
    this.AfterViewInitfn();
    this.screenwidth = window.innerWidth;
    //  console.log(this.screenwidth)
    this.resizeObservable$ = fromEvent(window, 'resize')
    this.resizeSubscription$ = this.resizeObservable$.subscribe(evt => {
      //  console.log(evt.target['screen'].availWidth);
      //this.screenwidth = evt.target['screen'].availWidth;
      this.screenwidth = evt.target["innerWidth"];
      let data = localStorage.getItem("selectTabBreadCrumbs");
      let value = localStorage.getItem("selectTabIndexName");
      if (data === null) {
        value = this.headerrole;
        data = this.header_breadcrumb
      }
      this.updateHeaderBreadcrumb(value, data);
      let cacheddata = localStorage.getItem('role');
      if (cacheddata === null || cacheddata === '' || cacheddata === undefined) {
        this.role = this.commondata["role"];

      }
      else {
        this.role = localStorage.getItem('role');
      }
    })

    this.companylogo = localStorage.getItem('companylogo');
 
     if(this.companylogo == null || this.companylogo == "" || this.companylogo == "null"){
       this.companylogo = "https://uat.stellar-oqcs.com/assets/images/mea-logo.svg";
     } else {
       this.companylogo = "https://"+this.companylogo;
     }
  }

  setdefaultpermission() {
    let a = localStorage.getItem("Permissiongroup");
    let Permissiongroupdata = JSON.parse(a)
    console.log(Permissiongroupdata)
    let login_role = localStorage.getItem("loginRole");

    if (login_role == 'System Administrator') {
      if (Object.keys(Permissiongroupdata["rolesPermissions"]["System Administrator"]).length != 0) {
        this.SETTING_MENU = Permissiongroupdata["rolesPermissions"]["System Administrator"]["SETTING_MENU"];
      }
      else {
        this.SETTING_MENU = true;
      }
    }
    else if (login_role == 'Primary Administrator') {
      if (Object.keys(Permissiongroupdata["rolesPermissions"]["Primary Administrator"]).length != 0) {
        this.SETTING_MENU = Permissiongroupdata["rolesPermissions"]["Primary Administrator"]["SETTING_MENU"];
      }
      else {
        this.SETTING_MENU = false;
      }
    }
    else if (login_role == 'Company Administrator') {
      if (Object.keys(Permissiongroupdata["rolesPermissions"]["Company Administrator"]).length != 0) {
        this.SETTING_MENU = Permissiongroupdata["rolesPermissions"]["Company Administrator"]["SETTING_MENU"];
      }
      else {
        this.SETTING_MENU = false;
      }
    }
    else if (login_role == 'Manager') {
      if (Object.keys(Permissiongroupdata["rolesPermissions"]["Manager"]).length != 0) {
      }
    }

    else if (login_role == 'Proctor') {
      if (Object.keys(Permissiongroupdata["rolesPermissions"]["Proctor"]).length != 0) {
      }
    }
    else if (login_role == 'Learner') {
      if (Object.keys(Permissiongroupdata["rolesPermissions"]["Learner"]).length != 0) {
      }
    }

  }

  AfterViewInitfn() {
    // get item -- check condition -- if true keep submenu else open side large menu
    let p = localStorage.getItem('minisidemenu');
    this.pi = JSON.parse(p);
    //console.log(this.pi)
    // if side bar collapsed
    if (this.pi === true) {
      // hide fixed sidebar footer
      setTimeout(() => {
        $('#footersidemenudivid').css("display", "none");
        $(".container-fluid").css('padding-left', '5px');
    
        $(".headerrow").css('width', 'calc(100% - 86px)');
        $(".headercol").css('margin-left', '-45px');
        
      }, 10);
      $('#sidebar, #content').toggleClass('active', true).hasClass('active');
    
 
    }
    else {
      // side bar expanded
      // show fixed sidebar footer
      setTimeout(() => {
      $('#footersidemenudivid').css("display", "block");
      $(".container-fluid").css('padding-left', '15px');
  
      $(".headerrow").css('width', 'calc(100% - 17rem)');
      $(".headercol").css('margin-left', '-33px');
     
    }, 10);

    $('#sidebar, #content').toggleClass('active', false).hasClass('active'); 
    }


   }


  // on toggle local store true/false
  toggle_btn() {
    this.p = $('#sidebar, #content').toggleClass('active').hasClass('active');
    if (this.p === false) {
      // show fixed sidebar footer
      // on expand/large sidemenu
      $('#footersidemenudivid').css("display", "block");
      localStorage.setItem("minisidemenu", this.p);

      $(".headerrow").css('width', 'calc(100% - 17rem)');
      $(".headercol").css('margin-left', '-33px');
      $(".container-fluid").css('padding-left', '15px')
    }
    else {
      // on collapse sidemenu
      $('#footersidemenudivid').css("display", "none");
      // adjust the css for header
      $(".headerrow").css('width', 'calc(100% - 86px)');
      $(".headercol").css('margin-left', '-45px');
      $(".container-fluid").css('padding-left', '5px')
    }

  }

  // on click menu store true/false
  menuItem_btn(p) {
    let pp: any;
    pp = $('#sidebar, #content').toggleClass('active', true).hasClass('active');
    localStorage.setItem('minisidemenu', pp);
  }


  addExpandClass(element: any, index: string) {
    var idx = 'idx' + index;
    if (element.path == '') {
      if (this.isLevel1Shown(idx)) {
        // if open -- close
        this.showMenu = null;
        this.showLevel1 = null;
      } else {
        // if close -- open
        // $("menuitem-"+index).addClass('is-active')
        this.showLevel1 = idx;
        this.showMenu = element.title;
      }
    }
    else {
      // close
      this.showMenu = null;
      this.showLevel1 = null;
    }

  }


  // return the level of tree i.e 1,2
  isLevel1Shown(idx: string) {
    return this.showLevel1 === idx;
  }

  // mobile side bar overlay
  sidenavtoggle() {
    document.getElementById("mySidenav").style.width = "17rem";
    // hide fixed sidebar footer
    $('#footersidemenudivmobileid').css("display", "block");
  }

  closeNav() {
    document.getElementById("mySidenav").style.width = "0";
    // show fixed sidebar footer
    $('#footersidemenudivmobileid').css("display", "none");
  }

  logout() {

    localStorage.removeItem('minisidemenu');
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("loginId");
    localStorage.removeItem('role');
    localStorage.removeItem('loginRole');
    localStorage.removeItem("ImpersonateprofileUrl");
    this.router.navigate(["/login"]);


    // clear userlist filter data
    localStorage.removeItem('filterdata');
    localStorage.removeItem('chipdisplayfilterdata');
    localStorage.removeItem('clearfilteritemkey');
    localStorage.removeItem('filterapibodycontent');

    localStorage.removeItem("selectTabIndex");
    localStorage.removeItem("selectTabIndexName");
    localStorage.removeItem("selectTabBreadCrumbs");

    // clear grouplistp filter data
    localStorage.removeItem('groupfilterdata');
    localStorage.removeItem('group_chipdisplayfilterdata');
    localStorage.removeItem('group_clearfilteritemkey');
    localStorage.removeItem('group_filterapibodycontent');

    // clear Manager filter data
    localStorage.removeItem('Manager_filterdata');
    localStorage.removeItem('Manager_chipdisplayfilterdata');
    localStorage.removeItem('Manager_clearfilteritemkey');
    localStorage.removeItem('Manager_filterapibodycontent');

    localStorage.removeItem('user_details');
    localStorage.removeItem('globalcompanyIDselected');
    localStorage.removeItem('globalcompanynameselected');
    localStorage.removeItem('searchvalue');
    localStorage.removeItem('badgeslist');
    localStorage.removeItem("frompage");
    // localStorage.removeItem("iconClick");
    localStorage.removeItem('chipdisplayfilterdataforterms');
    localStorage.removeItem('filterapibodycontentforterms');

    localStorage.removeItem("Permissiongroup");
    localStorage.removeItem("evaluationID");

  }

  search(searchinput) {
    this.filterValue = searchinput.trim().toLowerCase();
    // angular core - output - emit value to main component
    this.searchInputvalue.emit(this.filterValue);
    localStorage.setItem('searchvalue', this.filterValue);
  }

  selectcompanylist(value,name) {
    let modeSelect;
    let modeSelectcompanyname;
    if(value==undefined){
      modeSelect="";
    }
    else{
      modeSelect=value;
    }

    for(let i=0;i<this.companylistdata.length;i++){
      if(this.companylistdata[i]["companyId"]==modeSelect){
        modeSelectcompanyname=this.companylistdata[i]["companyName"]
      }
    }
    this.selectcompanylistvalue.emit(modeSelect);
    localStorage.setItem('globalcompanyIDselected', modeSelect);
    localStorage.setItem('globalcompanynameselected', modeSelectcompanyname);
  }

  matMenubtn(item) {
    //to avoid notification on when the user logged
   localStorage.removeItem("user_loggedIN_terms")
   localStorage.removeItem("user_loggedIN")
   //to avoid notification on when the user logged

    localStorage.setItem('role', item);
    this.role = item;
    localStorage.setItem('loginRole', this.role);
    this.appPages = this.SidemenudataserviceService.GetSidemenuData();

    let rootlink: any = [];
    if (this.appPages == undefined) {
      this.router.navigate(["/AccessDenied"]);
    } else {
      for (var i = 0; i < this.appPages.length; i++) {
        if (this.appPages[i].Showdata == true) {
          rootlink.push(this.appPages[i]);
          break;
        }
      }
      if (rootlink.length == 0) {
        this.router.navigate(["/AccessDenied"]);
      }
    }

    if (rootlink.length > 0) {
      ///  console.log(rootlink[0].path);
      //  console.log(rootlink[0].title)
      this.router.navigate([rootlink[0].path], { queryParams: { menuTitle: rootlink[0].title } });
    }
    // else{
    setTimeout(() => {
      // if(rootlink.length>0){
      this.reloadcomponentforrole(rootlink);
      // }
      // cache value for display breadcrumb and refresh component
    }, 1000);
    // }

  }

  reloadcomponentforrole(rootlink) {
    let currentUrl = this.router.url.split("?");
    let menutitle = this.router.url.split("=");
    // console.log(this.router.url);
    //  console.log(currentUrl);

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    if (rootlink.length > 0) {
      // this.router.navigate([currentUrl[0]], { queryParams: { menuTitle: menutitle[1] } });
      this.router.navigate([rootlink[0].path], { queryParams: { menuTitle: rootlink[0].title } });
    }
    else {
      this.router.navigate([currentUrl[0]], { queryParams: { menuTitle: menutitle[1] } });
    }
  }

  impersonate_close() {
    localStorage.setItem('impersonatemode', 'false');
    localStorage.setItem('role', "System Administrator");
    localStorage.setItem('loginRole', "System Administrator");
    localStorage.removeItem("currentloginId");
    this.SidemenudataserviceService.GetSidemenuData();
    this.router.navigate(['/user/vieworedit_User'], { queryParams: { menuTitle: 'Users' } }).then(() => {
      const currentRoute = this.router.url;
      this.router.navigate([currentRoute]);
    });
    document.querySelector("body").style.cssText = "--cursorval: pointer";
    document.querySelector("body").style.cssText = "--pointerval: all";
    document.querySelector("body").style.cssText = "--formcursorval: pointer";
    document.querySelector("body").style.cssText = "--formpointerval: all";
  }

}
