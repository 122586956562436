import { Component, Inject, OnInit } from '@angular/core';
import { ApiServiceService } from 'src/app/Services/api-service.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { AssignableContentComponent } from 'src/app/Administrator/assignable-content/assignable-content.component';
import { DialogpopupComponent } from 'src/app/Popups/dialog-popup/dialog-popup.component';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-assign-withdraw-title',
  templateUrl: './assign-withdraw-title.component.html',
  styleUrls: ['./assign-withdraw-title.component.scss']
})
export class AssignWithdrawTitleComponent implements OnInit {

  rem_data: any = [];
  sel_data: any = [];
  loadspinner: boolean;
  pagecountform = new FormControl();
  user_data: any = [];
  titles: any[];
  backup_users: any = [];
  backup_data: any = [];
  selected_users = [];
  courseId: any;
  curriculumId: any;
  title: string;
  firstName: string;
  lastName: string;
  companyId: any;
  loginId: string;
  pagesize: any;
  Rowperpage: any;
  sortby: any;
  sortOrder: any;
  dataSource: any;
  ELEMENT_DATA: any = [];
  indexfirst: any;
  indexlast: any;
  totalitemlength: any;
  totalpages: any;
  compId: string;
  constructor(@Inject(MAT_DIALOG_DATA) public data: string, public dialogopen: MatDialog, public dialog: MatDialogRef<AssignableContentComponent>, private formBuilder: FormBuilder, public _Service: ApiServiceService,) {


    // this.curriculumiId= data['curriculumiId'];

    this.courseId = localStorage.getItem("courseId");
    this.curriculumId = localStorage.getItem("curriculumiId")
    this.loginId = localStorage.getItem('loginId');

    this.title = localStorage.getItem("curriculumtitle");

    this.totalitemlength = data['totalitemlength'];


  }

  ngOnInit(): void {
    this.PaginationApicall();

  }
  PaginationApicall() {

    this.loadspinner = true;
    const endpoint1 = 'curriculum';
    const endpoint2 = 'requirements/' + this.courseId;
    this.pagesize = '1';
    this.Rowperpage = parseInt(this.totalitemlength) + 1;
    this.sortby = "title";
    this.sortOrder = "ASC";


    let sort = this.sortby + ',' + this.sortOrder
    let itemPerPage = this.Rowperpage;

    this._Service.curriculamrequirementpost('1', sort, itemPerPage, endpoint1, endpoint2, 'POST')
      .subscribe((response) => {

        this.loadspinner = false;
        this.ELEMENT_DATA = response['curriculumRequirementList'];

        if (this.ELEMENT_DATA != "" && this.ELEMENT_DATA != undefined && this.ELEMENT_DATA != null) {
          for (var i = 0; i < this.ELEMENT_DATA.length; i++) {
            this.ELEMENT_DATA[i].isSelected = false;
          }
        }
        let tempdata = this.ELEMENT_DATA;
        tempdata.forEach(val => {

          val.course_id = val.courseId

        });

        this.selected_users = tempdata;
        this.backup_users = this.selected_users;

        this.listUserApi();

      },
        (err) => {
          this.loadspinner = false;
          //  console.log(err)
        });
  }



  commondialogue(id, index) {
    // const dialogRef = this.dialog.open(DialogpopupComponent, {
    //   width: '530px',
    //   height: '330px',
    //   data: {
    //     from: 'image-size',
    //     title: "",
    //     subtitle: "Total title weightage should not be more then 100"
    //   }
    // });

    let data = {
      status: "Total title weightage should not be more then 100"
    };
    const dialogRef = this.dialogopen.open(DialogpopupComponent, {
      width: '550px',
      autoFocus: false,
      // height: '350px',
      data: {
        from: 'error-popup',
        name: data
      },
      disableClose: true,
      position: {
        // top: '40vh'
        // left: '50%'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      // $('#' + id).val(0);
      this.ELEMENT_DATA[index].titleWeight = 0;
      // console.log(this.ELEMENT_DATA)
    });
  }
  sumofTitleWeight() {
    let p: any;
    let ab: number = 0;
    for (let i = 0; i < this.ELEMENT_DATA.length; i++) {
      p = this.ELEMENT_DATA[i]['titleWeight'];
      console.log(p)
      ab = ab + parseInt(p);
      console.log(ab)
    }
    // if it is a number -- sum of value > 100
    if ((!isNaN(ab))) {
      if (ab > 100) {
        return true
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }

  }
  close() {

    this.dialog.close();
    let element = document.getElementById("vieweditcurriculam-content")
    element.classList.remove('blur-content');

  }
  listUserApi() {

    this.loadspinner = true;
    let coidFoReq = localStorage.getItem("coidForReq");
    let endpoint = '/curriculum/getcoursetitles';
    this.compId = localStorage.getItem('procCompId');
    let cacheddata = localStorage.getItem('role');
    let body
    if (cacheddata === "System Administrator") {
      body = {
        "companyId": ""
      }
    }
    else {
      body = {
        "companyId": this.compId
      }
    }

    this._Service.assign_withdraw_title(endpoint, body, 'POST')
      .subscribe((response) => {
        this.loadspinner = false;
        let temp_data: any = response["courseTitleData"];
        for (var i = 0; i < temp_data.length; i++) {
          temp_data[i].isSelected = false;
        }
        for (var i = 0; i < temp_data.length; i++) {
          for (var j = 0; j < this.selected_users.length; j++) {
            if (temp_data[i].course_id == this.selected_users[j].course_id) {
              temp_data[i].isSelected = true;
            }
          }
        }
        temp_data = temp_data.filter(x => x.isSelected != true);

        this.user_data = temp_data;
        var setValue = this.user_data;
        // console.log(this.user_data, this.username)
        const dataRemoved = setValue.filter((el) => {
          return el.displayName !== this.lastName + "," + this.lastName;
        });
        //console.log(dataRemoved);
        this.user_data = dataRemoved;
        this.loadspinner = false;
        this.backup_data = this.user_data;

        this.loadspinner = false;
      },
        (err) => {
          this.loadspinner = false;
        });
  }

  itemSelected_leftbox(ev) {
    let present = false;
    for (var k = 0; k < this.sel_data.length; k++) {
      if (ev.course_id == this.sel_data[k].course_id) {
        present = true;
        this.sel_data.splice(k, 1);
      }
    }
    if (!present) {
      this.sel_data.push(ev)
    }

    for (var l = 0; l < this.backup_data.length; l++) {
      if (!present) {
        if (ev.course_id == this.backup_data[l].course_id) {
          this.backup_data[l].isSelected = true;
        }
      } else {
        if (ev.course_id == this.backup_data[l].course_id) {
          this.backup_data[l].isSelected = false;
        }
      }
    }
    for (var l = 0; l < this.user_data.length; l++) {
      if (!present) {
        if (ev.course_id == this.user_data[l].course_id) {
          this.user_data[l].isSelected = true;
        }
      } else {
        if (ev.course_id == this.user_data[l].course_id) {
          this.user_data[l].isSelected = false;
        }
      }
    }

  }
  itemSelected_rightbox(ev) {

    let present = false;
    for (var k = 0; k < this.rem_data.length; k++) {
      if (ev.course_id == this.rem_data[k].course_id) {
        present = true;
        this.rem_data.splice(k, 1);
      }
    }
    if (!present) {
      this.rem_data.push(ev)
    }
    for (var l = 0; l < this.backup_users.length; l++) {
      if (!present) {
        if (ev.course_id == this.backup_users[l].course_id) {
          this.backup_users[l].isSelected = true;
        }
      } else {
        if (ev.course_id == this.backup_users[l].course_id) {
          this.backup_users[l].isSelected = false;
        }
      }
    }
    for (var l = 0; l < this.selected_users.length; l++) {
      if (!present) {
        if (ev.course_id == this.selected_users[l].course_id) {
          this.selected_users[l].isSelected = true;
        }
      } else {
        if (ev.course_id == this.selected_users[l].course_id) {
          this.selected_users[l].isSelected = false;
        }
      }
    }
  }
  add_users() {

    if (this.sel_data.length > 0) {

      for (var j = 0; j < this.backup_data.length; j++) {
        if (this.backup_data[j].isSelected) {
          this.selected_users.push(this.backup_data[j])
        }
      }


      this.user_data = this.backup_data;
      this.user_data = this.user_data.filter(x => x.isSelected != true);
      this.sel_data = [];
      for (var m = 0; m < this.backup_data.length; m++) {
        for (var n = 0; n < this.selected_users.length; n++) {
          if (this.selected_users[n].course_id == this.backup_data[m].course_id) {
            this.backup_data[m].isSelected = true;
          }
        }
      }

      this.backup_data = this.backup_data.filter(x => x.isSelected != true);
      this.user_data = this.backup_data;
      for (var j = 0; j < this.selected_users.length; j++) {
        this.selected_users[j].isSelected = false;
      }

      this.backup_users = this.selected_users;
      //setting the serach value
      const name: any = document.getElementById('sid1');
      name.value = "";
    }
  }

  remove_user() {
    //   let element = document.getElementById("vieweditcurriculam1-content")
    //   if(this.rem_data.length!=0){
    //   element.classList.add('blur-content')
    //   let dialogRef = this.dialogopen.open(DialogpopupComponent, {
    //     width: '530px',
    //     height: '330px',
    //     disableClose: true,
    //     data:
    //     {
    //       title : 'Are you sure you want to withdraw?',
    //       subtitle : 'By withdrawing the selected Courses, you will lose the data for the current attempt. Would you like to proceed?',
    //       imagelogo:"assets/images/create-u-ser.svg",
    //       from: "confirm-popup"
    //     },
    //     backdropClass: 'deletePopup',

    //     position: {
    //       top: '27vh'
    //     }
    //   });
    //   dialogRef.afterClosed().subscribe(res => {

    // if(res.method == "delete"){
    if (this.rem_data.length > 0) {

      for (var j = 0; j < this.backup_users.length; j++) {
        if (this.backup_users[j].isSelected) {
          this.user_data.push(this.backup_users[j])
        }
      }

      this.selected_users = this.backup_users;
      this.selected_users = this.selected_users.filter(x => x.isSelected != true);
      this.rem_data = [];
      for (var m = 0; m < this.backup_users.length; m++) {
        for (var n = 0; n < this.user_data.length; n++) {
          if (this.user_data[n].course_id == this.backup_users[m].course_id) {
            this.backup_users[m].isSelected = true;
          }
        }
      }
    }


    this.backup_users = this.backup_users.filter(x => x.isSelected != true);
    this.selected_users = this.backup_users;
    for (var j = 0; j < this.user_data.length; j++) {
      this.user_data[j].isSelected = false;
    }
    this.backup_data = this.user_data;
    const name: any = document.getElementById('sid2');
    name.value = "";
    // }
    // else if(res.method == "close"){
    // }

    //   })
    // }
  }
  search_left(ev) {

    this.user_data = this.backup_data;
    let filterValue = ev;
    this.user_data = this.user_data.filter(option => (option.title.toLowerCase().includes(filterValue.toLowerCase()) || option.title.toLowerCase().includes(filterValue.toLowerCase()) || option.title.toLowerCase().includes(filterValue.toLowerCase())));
  }
  search_right(ev) {

    this.selected_users = this.backup_users;
    let filterValue = ev;
    this.selected_users = this.selected_users.filter(option => (option.title.toLowerCase().includes(filterValue.toLowerCase()) || option.title.toLowerCase().includes(filterValue.toLowerCase()) || option.title.toLowerCase().includes(filterValue.toLowerCase())));
  }
  create_user() {
    this.loadspinner = true;
    let endpoint = '/curriculum/assignwithdrawtitles/' + this.curriculumId;
    let user_list: any = [];

    if (this.backup_users.length > 0) {
      for (var i = 0; i < this.backup_users.length; i++) {

        if (this.backup_users[i].course_id) {
          user_list.push({ 'title': this.backup_users[i].title, 'course_id': this.backup_users[i].course_id })
        } else {
          user_list.push({ 'title': this.backup_users[i].title, 'course_id': this.backup_users[i].courseId })
        }
      }
    }

    let body = {
      "courseTitleData": user_list
    }
    this._Service.assign_withdraw_title(endpoint, body, 'POST')
      .subscribe((response) => {
        this.loadspinner = false;
        if (response["message"] === 'success') {
          //if(this.pageId==1){
          let element = document.getElementById("vieweditcurriculam-content")
          element.classList.add('blur-content')
          this.dialogopen.open(DialogpopupComponent, {
            width: '550px',
            disableClose: true,
            data:
            {
              from: "popup-success",
              // navigateTo: "edit-user";
              successmsg: "Hurray!!",
              successmsgbody: 'List updated successfully ',
              imagelogo: "assets/images/thumbsup.svg"
            },
            position: {
              top: '25vh'
            }
          });
          this.dialog.close('success');
          // let element = document.getElementById("vieweditcurriculam-content")
          // element.classList.remove('blur-content');
        }
        else {
          this.loadspinner = false;

          let data = {
            status: response["message"]
          };
        }
      },
        (err) => {
          this.loadspinner = false;
          //  console.log(err)

        });
  }


}
