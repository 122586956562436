<div id="app">
    <div style="text-align:center"><video #video id="video" width="500" height="500" autoplay></video></div>
    <div class="row row-margin">
        <div class="col-6">
            <button  mat-button class="cancel-btn" style="float:right" (click)="cancel()"> Cancel</button>
        </div>
        <div class="col-6">
            <button  id="snap" mat-button class="save-btn" (click)="capture()"> Snap Photo </button>
        </div>
    </div>
    <canvas #canvas id="canvas" width="500" height="500"></canvas>
</div>