import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserComponent } from 'src/app/Administrator/user/user.component';
import { ApiServiceService } from 'src/app/Services/api-service.service';
import { DialogpopupComponent } from '../../dialog-popup/dialog-popup.component';
import { CommondataserviceService } from 'src/app/Services/commondataservice.service';

@Component({
  selector: 'app-addrequiremnets',
  templateUrl: './addrequiremnets.component.html',
  styleUrls: ['./addrequiremnets.component.scss']
})
export class AddrequiremnetsComponent implements OnInit {
  filterform: FormGroup;
  companylistdata: any = [""];
  statuslist: any = [""];
  loadspinner: any = false;
  groupapibodycontent: any;
  disablebutton: any;
  selected_users: any = [];
  user_data: any = [];
  backup_data: any = [];
  backup_users: any = [];
  sel_data: any = [];
  rem_data: any = [];
  filter_companyid: any = '';
  sel_user_array = [];
  tempIndex: any;
  commondata_details: any;
  companyId: any;
  sequence: { sequenceid: string; sequenceNumber: string; }[];
  sampletooltipdata: any;
  group_selectall = false;
  group_selectall_right = false;
  leftsidesearchvalue: any = "";

  constructor(private CommondataserviceService: CommondataserviceService, @Inject(MAT_DIALOG_DATA) public data: string, public dialogopen: MatDialog, private formBuilder: FormBuilder, private apiService: ApiServiceService, public dialog: MatDialogRef<UserComponent>) {


    this.companyId = localStorage.getItem("companyIdForReq");
    console.log(this.companyId)

    this.tempIndex = data['index'];
    let temp: any;
    if (localStorage.getItem("requirement_Data") != undefined && localStorage.getItem("requirement_Data") != null && localStorage.getItem("requirement_Data") != '') {

      temp = JSON.parse(localStorage.getItem("requirement_Data"));
      this.selected_users = temp;
      console.log(this.selected_users);
      for (var i = 0; i < this.selected_users.length; i++) {
        this.selected_users[i].isSelected = false;
      }
      this.backup_users = this.selected_users;

    }


    this.sequence = [
      { "sequenceid": '0', "sequenceNumber": '0' },
      { "sequenceid": '1', "sequenceNumber": '1' },
      { "sequenceid": '2', "sequenceNumber": '2' },
      { "sequenceid": '3', "sequenceNumber": '3' },
      { "sequenceid": '4', "sequenceNumber": '4' },
      { "sequenceid": '5', "sequenceNumber": '5' },
      { "sequenceid": '6', "sequenceNumber": '6' },
      { "sequenceid": '7', "sequenceNumber": '7' },
      { "sequenceid": '8', "sequenceNumber": '8' },
      { "sequenceid": '9', "sequenceNumber": '9' },
      { "sequenceid": '10', "sequenceNumber": '10' }
    ]
  }

  ngOnInit(): void {
    this.get_userslist();
  }

  get_userslist() {
    this.loadspinner = true;
    const endpoint1 = 'task/getcourseformList';
    //const endpoint2 = this.companyId;
    let compId = localStorage.getItem('procCompId');
    let cacheddata = localStorage.getItem('role');
    let body
    if (cacheddata === "System Administrator") {
      body = {
        "companyId": ""
      }
    } else {
      body = {
        "companyId": compId
      }
    }


    this.apiService.getTaskRequirement_post(endpoint1, body, 'POST')
      .subscribe((response) => {
        this.loadspinner = false;
        let temp_data: any = response['courseFormsListData']
        for (var i = 0; i < temp_data.length; i++) {
          temp_data[i].isSelected = false;
        }
        for (var i = 0; i < temp_data.length; i++) {
          for (var j = 0; j < this.selected_users.length; j++) {
            if (temp_data[i].requirementId == this.selected_users[j].requirementId) {
              temp_data[i].isSelected = true;
            }
          }
        }
        temp_data = temp_data.filter(x => x.isSelected != true);

        this.user_data = temp_data;
        console.log(this.user_data)
        // this.user_data = response["userList"];

        this.backup_data = this.user_data;
        this.backup_data = this.user_data;
      },
        (err) => {
          this.loadspinner = false;
        })


  }

  cancel() {

    localStorage.removeItem("requirementData");
    this.dialog.close('');
    let element = document.getElementById("task-content")
    element.classList.remove('blur-content');
  }

  search_left(ev) {
    this.leftsidesearchvalue = ev;
    this.user_data = this.backup_data;
    let filterValue = ev;
    // this.user_data= this.user_data.filter(option => ( option.requirementName.toLowerCase().includes(filterValue.toLowerCase()) || option.requirementId.toLowerCase().includes(filterValue.toLowerCase()) ));

    this.user_data = this.user_data.filter(option => (option.requirementName.toLowerCase().includes(filterValue.toLowerCase()) || option.requirementId.toLowerCase().includes(filterValue.toLowerCase())));

    for (var l = 0; l < this.user_data.length; l++) {
      if (this.user_data[l].isSelected === true) {
        this.group_selectall = true;
      }
      else {
        this.group_selectall = false;
        break
      }
    }
  }

  search_right(ev) {

    this.selected_users = this.backup_users;
    let filterValue = ev;
    this.selected_users = this.selected_users.filter(option => (option.requirementName.toLowerCase().includes(filterValue.toLowerCase()) || option.requirementId.toLowerCase().includes(filterValue.toLowerCase())));

    for (var l = 0; l < this.selected_users.length; l++) {
      if (this.selected_users[l].isSelected === true) {
        this.group_selectall_right = true;
      }
      else {
        this.group_selectall_right = false;
        break
      }
    }

  }

  itemSelected_leftbox(ev) {
    let instance = this;
    instance.group_selectall = null;
    // this.group_selectall=null;
    let present = false;
    for (var k = 0; k < this.sel_data.length; k++) {
      if (ev.requirementId == this.sel_data[k].requirementId) {
        present = true;
        this.sel_data.splice(k, 1);
      }
    }
    if (!present) {
      this.sel_data.push(ev)
    }


    for (var l = 0; l < this.backup_data.length; l++) {
      if (!present) {
        if (ev.requirementId == this.backup_data[l].requirementId) {
          this.backup_data[l].isSelected = true;
        }
      } else {
        if (ev.requirementId == this.backup_data[l].requirementId) {
          this.backup_data[l].isSelected = false;
        }
      }
    }
    for (var l = 0; l < this.user_data.length; l++) {
      if (!present) {
        if (ev.requirementId == this.user_data[l].requirementId) {
          this.user_data[l].isSelected = true;

        }
      } else {
        if (ev.requirementId == this.user_data[l].requirementId) {
          this.user_data[l].isSelected = false;

        }
      }
    }

    for (var l = 0; l < this.user_data.length; l++) {
      if (this.user_data[l].isSelected === true) {
        this.group_selectall = true;
      }
      else {
        this.group_selectall = false;
        break
      }
    }
  }
  itemSelected_rightbox(ev) {

    this.group_selectall_right = false;
    let present = false;
    for (var k = 0; k < this.rem_data.length; k++) {
      if (ev.requirementId == this.rem_data[k].requirementId) {
        present = true;
        this.rem_data.splice(k, 1);
      }
    }
    if (!present) {
      this.rem_data.push(ev)
    }
    for (var l = 0; l < this.backup_users.length; l++) {
      if (!present) {
        if (ev.requirementId == this.backup_users[l].requirementId) {
          this.backup_users[l].isSelected = true;
        }
      } else {
        if (ev.requirementId == this.backup_users[l].requirementId) {
          this.backup_users[l].isSelected = false;
        }
      }
    }
    for (var l = 0; l < this.selected_users.length; l++) {
      if (!present) {
        if (ev.requirementId == this.selected_users[l].requirementId) {
          this.selected_users[l].isSelected = true;
        }
      } else {
        if (ev.requirementId == this.selected_users[l].requirementId) {
          this.selected_users[l].isSelected = false;
        }
      }
    }
    // if(this.selected_users.length == this.rem_data.length){
    //   this.group_selectall_right=true;
    // }
    for (var l = 0; l < this.selected_users.length; l++) {
      if (this.selected_users[l].isSelected === true) {
        this.group_selectall_right = true;
      }
      else {
        this.group_selectall_right = false;
        break
      }
    }
    // if (present && this.group_selectall_right == true) {
    //   this.group_selectall_right = false;
    //   this.selected_users[l].isSelected =false
    // } else {
    //   this.group_selectall_right = true;
    //   this.selected_users[l].isSelected =true
    // }
  }

  add_users() {

    this.group_selectall = null;
    this.group_selectall_right = null;
    if (this.sel_data.length > 0) {
      for (var j = 0; j < this.backup_data.length; j++) {
        if (this.backup_data[j].isSelected) {
          this.selected_users.push(this.backup_data[j])
        }
      }


      this.user_data = this.backup_data;
      this.user_data = this.user_data.filter(x => x.isSelected != true);
      this.sel_data = [];
      for (var m = 0; m < this.backup_data.length; m++) {
        for (var n = 0; n < this.selected_users.length; n++) {
          if (this.selected_users[n].requirementId == this.backup_data[m].requirementId) {
            this.backup_data[m].isSelected = true;
          }
        }
      }

      this.backup_data = this.backup_data.filter(x => x.isSelected != true);
      this.user_data = this.backup_data;
      for (var j = 0; j < this.selected_users.length; j++) {
        this.selected_users[j].isSelected = false;
      }

      this.backup_users = this.selected_users;
      console.log(this.selected_users)
      //setting the serach value
      const name: any = document.getElementById('sid1');
      name.value = "";
    }
  }

  remove_user() {

    if (this.rem_data.length > 0) {
      console.log(this.rem_data)
      let element = document.getElementById("taskpopup-content")
      element.classList.add('blur-content')
      let dialogRef = this.dialogopen.open(DialogpopupComponent, {
        width: '530px',
        height: '330px',
        disableClose: true,
        data:
        {
          title: 'Are you sure you want to Remove?',
          subtitle: 'By removing the selected course, you will lose the data for the users course attempt, Would you like to proceed?',
          imagelogo: "assets/images/create-u-ser.svg",
          from: "confirm-popup"
        },
        backdropClass: 'deletePopup',

        position: {
          top: '27vh'
        }
      });


      dialogRef.afterClosed().subscribe(res => {
        let element = document.getElementById("taskpopup-content")
        element.classList.remove('blur-content');
        if (res != undefined) {
          if (this.rem_data.length > 0) {
            this.group_selectall = null;
            this.group_selectall_right = null;
            for (var j = 0; j < this.backup_users.length; j++) {
              if (this.backup_users[j].isSelected) {
                this.user_data.push(this.backup_users[j])
              }
            }

            this.selected_users = this.backup_users;
            this.selected_users = this.selected_users.filter(x => x.isSelected != true);
            this.rem_data = [];
            for (var m = 0; m < this.backup_users.length; m++) {
              for (var n = 0; n < this.user_data.length; n++) {
                if (this.user_data[n].requirementId == this.backup_users[m].requirementId) {
                  this.backup_users[m].isSelected = true;
                }
              }
            }
          }
          this.backup_users = this.backup_users.filter(x => x.isSelected != true);
          this.selected_users = this.backup_users;
          for (var j = 0; j < this.user_data.length; j++) {
            this.user_data[j].isSelected = false;
          }
          this.backup_data = this.user_data;
          const name: any = document.getElementById('sid2');
          name.value = "";
        }
      })
    }

  }

  
  groupAll(ev) {
    if (this.group_selectall == true) {
      this.user_data.forEach(element => {
        element.isSelected = true;
      })

      if (this.leftsidesearchvalue.trim() === "") {
        this.sel_data = [];
        this.backup_data = [];
        this.user_data.forEach(element => {
          this.sel_data.push(element);
          this.backup_data.push(element);
        })
      }
      else {
        // check uniqueneess in array and push
        this.user_data.forEach(element => {
          let sel_data = [];
          let backup_data = [];
          sel_data.push(element);
          backup_data.push(element);
          for (var i = 0; i < sel_data.length; i++) {
            if (this.sel_data.indexOf(sel_data[i]) == -1) {
              this.sel_data.push(sel_data[i]);
            }
          }
          for (var i = 0; i < backup_data.length; i++) {
            if (this.backup_data.indexOf(backup_data[i]) == -1) {
              this.backup_data.push(backup_data[i]);
            }
          }
        })
      }


    } else if (this.group_selectall == false) {
      this.user_data.forEach(element => {
        element.isSelected = false;
      })
       debugger
      this.sel_data = [];
      this.backup_data = this.user_data;
      this.user_data.forEach(element => {
      })
    }

  }

  groupAll_right(ev) {

    if (this.group_selectall_right == true) {
      this.selected_users.forEach(element => {
        element.isSelected = true;
      })
      this.rem_data = [];
     // this.backup_users = [];
      this.selected_users.forEach(element => {
        this.rem_data.push(element);
       // this.backup_users.push(element)
      })

    } else if (this.group_selectall_right == false) {
      this.selected_users.forEach(element => {
        element.isSelected = false;
      })

      this.rem_data = [];
     // this.backup_users = this.selected_users;
      this.selected_users.forEach(element => {
        // this.rem_data.push(element);
        // this.backup_users.push(element)
      })
    }

  }

  getTooltip(tooltipdata) {
    let match = tooltipdata.split(',')
    this.sampletooltipdata = match.join("\r\n");
    //console.log(match1);
    //console.log(match)
    return this.sampletooltipdata
    //this.sampletooltipdata = match.join("\r\n");
  }
  truncate(source) {
    const size = 28
    return source.length > size ? source.slice(0, size - 1) + "…" : source;
  }
  truncated(source) {
    const size = 28
    return source.length > size ? source.slice(0, size - 1) + "…" : source;
  }


  save() {
    let requirement_list: any = [];
    if (this.backup_users.length > 0) {
      for (var i = 0; i < this.backup_users.length; i++) {
        delete this.backup_users[i]['isSelected'];
        this.backup_users[i]['initial'] = true
        this.backup_users[i]['subsequent'] = false
        this.backup_users[i]['sequence'] = this.sequence
        this.backup_users[i]['sequenceSelected'] = "0"
        requirement_list.push(this.backup_users[i])
      }

    }
    console.log(requirement_list)
    //   for(var i=0; i<this.backup_users.length; i++){
    //     for(var j=0; j<this.selected_users.length; j++){
    //       if(this.backup_users[i].requirementId==this.selected_users[j].requirementId){
    //         delete this.backup_users[i]['isSelected'];
    //        }
    //   }
    //  }
    // console.log(requirement_list)
    var storedreqs = localStorage.setItem("requirement_list", JSON.stringify(requirement_list))
    this.dialog.close('success');
  }

  cancel_user() {
    let element = document.getElementById("CreateCompany-content")
    element.classList.add('blur-content')
    const dialogRef = this.dialogopen.open(DialogpopupComponent, {
      width: '530px',
      height: '330px',
      disableClose: true,
      data: {
        from: 'image-cancel'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      let element = document.getElementById("CreateCompany-content")
      element.classList.remove('blur-content');
    });
  }


}