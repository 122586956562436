<div class="spinner_overlay" *ngIf="loadspinner===true">
</div>
<mat-spinner class="spinner" *ngIf="loadspinner===true"></mat-spinner>



<div class="usermaincontainer formstructure" id="user-content">
<div class="col-12">
<mat-card class="example-card mat-elevation-z8 matcard">

<div class="row">
<div class="col-md-1 col-lg-5 col-xl-5"> </div>

<div class="col-sm-2 col-md-3 col-lg-2 col-xl-2" *ngIf="!expandbtn">
<button mat-button *ngIf="structurelist?.length>1" (click)="openAll()" class="rowbtn collpsebtn">
<img src="assets/images/expand.webp">&#160;&#160;Expand All</button>
</div>
<div class="col-sm-2 col-md-3 col-lg-2 col-xl-2" *ngIf="expandbtn">
<button mat-button *ngIf="structurelist?.length>1" (click)="openAll()" class="expandbtn">
<img src="../../../../../assets/images/bitmap@3x.webp"
style="width: 16px; margin-top: -3px;">&#160;&#160;Expand All</button>
</div>
<div class="col-sm-2 col-md-3 col-lg-2 col-xl-2" *ngIf="collapsebtn">
<button mat-button *ngIf="structurelist?.length>1" (click)="closeAll()" class="expandbtn">
<img src="../../../../../assets/images/collapse1.png"
style="width: 16px; margin-top: -3px;">&#160;&#160;Collapse All</button>
</div>
<div class="col-sm-2 col-md-3 col-lg-2 col-xl-2" *ngIf="!collapsebtn">
<button mat-button *ngIf="structurelist?.length>1" (click)="closeAll()" class="rowbtn">
<img src="assets/images/collapse.webp">&#160;&#160;Collapse All</button>
</div>
<div class="col-sm-2 col-md-3 col-lg-2 col-xl-2">
<button  mat-button (click)="Addsteps()" class="rowbtn formstaructure_view addsteps" [disabled]="savebutton" >
<img src="../../../../../assets/images/addsteps.png" style="width: 14px; margin-top: -2px;">
&#160;Add Steps </button>
</div>
<div class="col-sm-2 col-md-1 col-lg-1 col-xl-1 margin_space">

<span class="exporticon" *ngIf="A_C_FORMS_STRUCTURE_VIEW!=false"  title="Edit">
<img src="assets/images/edit_active.png" alt="filter" title="Edit"
style="width: 45px; cursor: pointer; margin-top: -2px; display: none;"
id="edit_active">
<img *ngIf="sharedForm == 'No'" src="assets/images/edit.svg" alt="filter" title="Edit"
style="width: 45px; cursor: pointer;  margin-top: -2px; " id="view_active"
(click)="enable_edit()">
<img *ngIf="sharedForm != 'No'" src="assets/images/edit.svg" alt="filter" title="Edit"
style="width: 45px; cursor: pointer;  margin-top: -2px; opacity:0.5;cursor: not-allowed;" id="view_active"
>
</span>

<!-- <span class="exporticon" *ngIf="A_C_FORMS_STRUCTURE_VIEW!=false">
    <img src="assets/images/edit_active.png" alt="filter" title="Edit" style="width: 50px;display: none;" id="edit_active">
    <img *ngIf="sharedForm == 'No'" src="assets/images/edit.svg" alt="filter" title="Edit" style="width: 50px; cursor: pointer;" id="view_active" (click)="enable_edit()">
    <img *ngIf="sharedForm != 'No'" src="assets/images/edit.svg" alt="filter" title="Edit" style="width: 50px; cursor: pointer;opacity:0.5;cursor: not-allowed;" id="view_active" >
    </span> -->

</div>
</div>

<br> <br>

<div class="row success_div" id="save_sucess" style="display: none;">
<div class="col-lg-4 col-md-4"> </div>
<div class="col-sm-12 col-lg-8 col-md-8">
<div class="alert success_tab" role="alert">
<span> SUCCESSFULLY. Saved the Form Structure. </span>
</div>
</div>
</div>

<div class="row" class="publish_div" style="display: none;" id="publish_sucess">
<div class="col-lg-4 col-md-4"> </div>
<div class="col-12 col-lg-8 col-md-8">
<div class="alert success_tab" role="alert">
<span> SUCCESSFULLY. Published the Form Structure. </span>
</div>
</div>
</div>


<form [formGroup]="structureForm" class="formclass">
<mat-accordion #accordion cdkDropList #unassignedList="cdkDropList" [cdkDropListData]="structurelist"
(cdkDropListDropped)="drop($event)" class="example-headers-align" multi>
<mat-expansion-panel *ngFor="let steps of structurelist let i=index;" cdkDrag>
<mat-expansion-panel-header style="box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);">
<mat-panel-title> <img src="../../../../../assets/images/bitmap.webp" class="drag_img">
&#160;&#160;
Step {{i+1}}
</mat-panel-title>
</mat-expansion-panel-header>
<br>

<!-- start Form -->

<div class="row">
<mat-label class="col-sm-12 col-md-2 normal">Image Capturing</mat-label>
<div class="col-sm-11">

<input type="radio" id="yes" [checked]="steps.camera == true" name="imagecap-{{i}}"
value="true" [disabled]="savebutton">&#160;&#160; <span> Show Camera </span>&#160;&#160;
<input type="radio" id="yes" [checked]="steps.camera == false" name="imagecap-{{i}}"
value="false" [disabled]="savebutton">&#160;&#160; <span> Hide Camera </span>

</div>
<div class="col-sm-1">
<div class="delete_icon formstaructure_view" style="float: right;" (click)="delete_steps(i)" [disabled]="savebutton" > 
<img width="22" height="25" src="assets/images/delete_list.svg" alt="filter">
</div>
</div>
</div>

<div class="row">
<mat-label class="col-sm-10 normal">Performance Rating Not Applicable</mat-label>
<mat-checkbox labelPosition="after" class="labelname create_select"
[checked]="steps.performanceRatingNA" name="performance{{i}}"  [disabled]="savebutton"> </mat-checkbox>
</div>

<div class="row">
<div class="col-11">
<mat-card class="step_card">
<mat-label class="col-3">Enter Step Text</mat-label>
<mat-form-field appearance="outline" class="formfield" style="width: 100%;">
<input matInput placeholder="Enter Step Text" class="textinput" 
name="steptext{{i}}" value="{{steps.stepText.label}}" [disabled]="savebutton">
</mat-form-field>
<br>
<mat-label class="col-3"> Enter Step Description </mat-label> <br>
<mat-form-field appearance="outline" class="formfield" style="width: 100%;">
<textarea matInput placeholder="Enter Step Description"  class="textinput" name="stepdesc{{i}}" [disabled]="savebutton">{{steps.stepDescription.label}}</textarea>
</mat-form-field>
</mat-card>
</div>
<div class="col-1">
</div>
</div>


<button type="button" class="Addlevel_btn formstaructure_view" (click)="Addlevel(i)" id="addlevel_btn{{i}}"
style="display: none;" (click)="addleveltext(i)">
<img src="../../../../../assets/images/Addlevel.png" width="32px" height="32px"> &#160; Add
Level
</button>

<div class="row" id="addlevel_div{{i}}">
<br> &#160; <br>
<div class="col-12" *ngFor="let leveltext of steps.levels let j=index;">

<!-- connection line div starts -->
<svg class="firstline_top">
<line x1="7" y1="0" x2="7" y2="350" stroke="#eaaa00" />
</svg>
<div class="level_dot">
<svg class="level_bottom">
<line x1="0" y1="50%" x2="100" y2="50%" style="stroke:#eaaa00;stroke-width:1" />
</svg>
</div>
<svg width="500" height="400" style="margin-top:-17%">
<line *ngIf="addline && j != steps.levels.length-1" x1="7" y1="0" x2="7" y2="500"
stroke="#eaaa00" />
</svg>
<!-- Connection line div ends -->


<!-- level div starts -->
<div class="row level_top">
<div class="col-11">
<mat-card class="card_level">
<mat-label class="col-3">Level Text</mat-label> <br>
<mat-form-field appearance="outline" class="formfield" style="width: 100%;">
<textarea matInput placeholder="Level Text" class="textinput"
name="leveltext{{i}}-{{j}}"
  [disabled]="savebutton"> {{leveltext.label}} </textarea>
</mat-form-field>
</mat-card>
</div>
<div class="col-1 addanddelete_levelbtn">
<button type="button" class="Addlevel_btn formstaructure_view" (click)="deleteleveltext(i, j)" [disabled]="savebutton" >
<img src="assets/images/group-34-copy.svg" class="level_btn"> </button>
<br> <br> <br>
<button type="button" class="Addlevel_btn formstaructure_view" (keydown.enter)="$event.preventDefault()" (click)="addleveltext(i)" [disabled]="savebutton" > <img
src="assets/images/Addlevel.png" class="level_btn"> </button>
</div>
</div>
<!-- levels div ends -->


<!-- sublevels div start -->
<div class="row" *ngFor="let subleveltext of leveltext.subLevel let k=index;">
<div class="col-11">


<!-- sublevel connection line div starts -->
<svg class="sublevel_line">
<line x1="54" y1="0" x2="54" y2="350" stroke="#143249" />
</svg>
<div class="sublevel_dot">
<svg class="sublevel_bottom">
<line x1="0" y1="50%" x2="100" y2="50%"
style="stroke:#143249;stroke-width:1" />
</svg>
</div>
<svg class="subline_bottom">
<line class="sublevel_bottonline" *ngIf="addsubline && k != leveltext.subLevel.length-1" x1="54" y1="0"
x2="54" y2="350" stroke="#143249" />
</svg>

<!-- sublevel Connection line div ends -->



<mat-card class="sublevel_card">
<mat-label class="col-3">SubLevel Text</mat-label> <br>
<mat-form-field appearance="outline" class="formfield" style="width: 100%;">
<textarea matInput placeholder="SubLevel Text" class="textinput"
name="sublevel{{i}}-{{j}}-{{k}}"
 [disabled]="savebutton"> {{subleveltext.label}}    </textarea>
</mat-form-field>
</mat-card>
</div>
<div class="col-1 addanddelete_sublevelbtn">
<button type="button" class="Addlevel_btn formstaructure_view" (click)="deletesubleveltext(i, j, k)" [disabled]="savebutton"> 
<img src="assets/images/group-34-copy-4.svg" class="level_btn"> </button>
<br> <br> <br>
<button type="button" class="Addlevel_btn formstaructure_view" (click)="Addsublevels(i, j)" [disabled]="savebutton">
<img src="assets/images/add-sublevel.png" class="level_btn"> </button>
</div>
</div>
<br> &#160; <br>
<!-- sublevel div ends -->

</div>
</div>

</mat-expansion-panel>
</mat-accordion>
</form>



</mat-card>
<br> <br>
<div class="row" id="btn_div" style="display: none;">
<div class="col-lg-2 col-md-2 col-xl-3"> </div>
<div class="col-lg-3 col-md-3 col-xl-2">
<button mat-button class="savebtn" (click)="save('Draft')" [disabled]="savebutton">
<mat-icon class="createbtnicon" alt="clear">done</mat-icon>
Save
</button>
</div>
<div class="col-lg-3 col-md-3 col-xl-2">
<button mat-button class="cancelbtn" (click)="cancel()" [disabled]="cancelbutton">
<mat-icon class="Cancelicon" alt="clear">clear</mat-icon>
Cancel
</button>
</div>
<div class="col-lg-3 col-md-3 col-xl-2">
<button mat-button class="savebtn" (click)="save('Published')" [disabled]="publishbutton" *ngIf="A_C_FORMS_STRUCTURE_PUBLISH!=false">
<mat-icon class="createbtnicon">done</mat-icon>
Publish
</button>
</div>
<div class="col-lg-1 col-md-1 col-xl-3"> </div>
</div>
<br>
</div>
</div>
