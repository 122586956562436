import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AssignableContentComponent } from 'src/app/Administrator/assignable-content/assignable-content.component';
import { ApiServiceService } from 'src/app/Services/api-service.service';

@Component({
  selector: 'app-assignablecontentlistfilter',
  templateUrl: './assignablecontentlistfilter.component.html',
  styleUrls: ['./assignablecontentlistfilter.component.scss'],
  providers: [DatePipe]

})
export class AssignablecontentlistfilterComponent implements OnInit {
  filterform: FormGroup;
  companylistdata: any = [""];
  statuslist: any = [""];
  category: any = [""];
  type: any = [""];
  createdBy: any = [""];
  modifiedBy: any = [""];
  customField: any = [""];
  groupapibodycontent: any;
  compId:any='';
  assgnCatlog:any=[];

  loadspinner: any = false;
  apibodycontent: any;
  disablebutton: any;
  isDisabled: boolean;
  constructor(private datePipe: DatePipe, private formBuilder: FormBuilder, private apiService: ApiServiceService, public dialog: MatDialogRef<AssignableContentComponent>) {
    this.filterform = this.formBuilder.group({
      CompanyId: [''],
      CompanyStatus: [''],
      CreationStartdate: [''],
      CreationEnddate: [''],
      LastUpdateStartdate: [''],
      LastUpdateEnddate: [''],
      CreatedBy: [''],
      ModifiedBy: [''],
      Customfield: [''],
      CompanyType: [''],
      Category: [''],
      CourseID:[''],
      CourseTitle:[''],
      assignCatalog:['']
    });
    this.apicallgetdefaultdropdown();
  }


  ngOnInit(): void {
  }

  getbodycontent() {
    
    let daterange = "";
    let startdate = "";
    let enddate = "";
    let apidaterange = "";
    let apistartdate = "";
    let apienddate = "";

    let daterangeLastUpdate = "";
    let startdateLastUpdate = "";
    let enddateLastUpdate = "";
    let apidaterangeLastUpdate = "";
    let apistartdateLastUpdate = "";
    let apienddateLastUpdate = "";

    if (this.filterform.value.CreationStartdate === "" || this.filterform.value.CreationStartdate === undefined || this.filterform.value.CreationStartdate === null) {
      daterange = "";
    }
    else {
      startdate = this.datePipe.transform(this.filterform.value.CreationStartdate, 'MM/dd/yyyy');
      enddate = this.datePipe.transform(this.filterform.value.CreationEnddate, 'MM/dd/yyyy');
      daterange = startdate + " - " + enddate;
      apistartdate = this.datePipe.transform(this.filterform.value.CreationStartdate, 'yyyy-MM-dd');
      apienddate = this.datePipe.transform(this.filterform.value.CreationEnddate, 'yyyy-MM-dd');
      apidaterange = '2021-07-25'
    }

    if (this.filterform.value.LastUpdateStartdate === "" || this.filterform.value.LastUpdateStartdate === undefined || this.filterform.value.LastUpdateStartdate === null) {
      daterangeLastUpdate = "";
    }
    else {
      startdateLastUpdate = this.datePipe.transform(this.filterform.value.LastUpdateStartdate, 'MM/dd/yyyy');
      enddateLastUpdate = this.datePipe.transform(this.filterform.value.LastUpdateEnddate, 'MM/dd/yyyy');
      daterangeLastUpdate = startdateLastUpdate + " - " + enddateLastUpdate;
      apistartdateLastUpdate = this.datePipe.transform(this.filterform.value.LastUpdateStartdate, 'yyyy-MM-dd');
      apienddateLastUpdate = this.datePipe.transform(this.filterform.value.LastUpdateEnddate, 'yyyy-MM-dd');
      apidaterangeLastUpdate = '2021-07-25'
    }

    let CompanyID="";
    let rolecacheddata = localStorage.getItem('role');
    if (rolecacheddata != "System Administrator") {
      CompanyID="";
    }
    else{
      let globalcompanyIDselected = localStorage.getItem('globalcompanyIDselected');
      if(globalcompanyIDselected=='' || globalcompanyIDselected==undefined || globalcompanyIDselected==null){
        CompanyID=this.filterform.value.CompanyId
      }
      else{
        CompanyID="";
      }
    }

    let catalogname= "";
    let catalogdata:any=this.filterform.value.assignCatalog;
    
    
   for(var i=0; i<catalogdata.length; i++){
    if(i==0){
      catalogname=catalogdata[i].catalogName
    }else{
      catalogname=catalogname+','+catalogdata[i].catalogName
    }
   }

    let filterdata = {
      "Status": this.filterform.value.CompanyStatus.trim(),
      "Owner / Company ID": CompanyID,
      "Created By": this.filterform.value.CreatedBy.trim(),
      "Modified By": this.filterform.value.ModifiedBy.trim(),
      "Custom Field": this.filterform.value.Customfield.trim(),
      "Creation Date Range": daterange,
      "Last updated Date Range": daterangeLastUpdate,
      "Type": this.filterform.value.CompanyType.trim(),
      "Category": this.filterform.value.Category.trim(),
      "Course ID": this.filterform.value.CourseID.trim(),
      "Course Title": this.filterform.value.CourseTitle.trim(),
      "catalogs":catalogname
    }
    this.getapibodycontent(apistartdate, apienddate, apistartdateLastUpdate, apienddateLastUpdate);
    return filterdata

  }

  getapibodycontent(apistartdate, apienddate, apistartdateLastUpdate, apienddateLastUpdate) {
    let companylistdataid = "";
    for (let i = 0; i < this.companylistdata.length; i++) {
      if (this.companylistdata[i].companyName + ' ' + '(' + this.companylistdata[i].companyId + ')' === this.filterform.value.CompanyId) {
        companylistdataid = this.companylistdata[i].companyId;
      }
    }

    let createdbyuser="";
    for (let i = 0; i < this.createdBy.length; i++) {
      if (this.createdBy[i].displayName + ' ' + '(' + this.createdBy[i].loginName + ')' === this.filterform.value.CreatedBy) {
        createdbyuser = this.createdBy[i].loginName;
      }
    }

    let modifiedbyuser="";
    for (let i = 0; i < this.modifiedBy.length; i++) {
      if (this.modifiedBy[i].displayName + ' ' + '(' + this.modifiedBy[i].loginName + ')' === this.filterform.value.ModifiedBy) {
        modifiedbyuser = this.modifiedBy[i].loginName;
      }
    }
    let catalogdataid = "";
    let catalogdata:any=this.filterform.value.assignCatalog;
    
    
   for(var i=0; i<catalogdata.length; i++){
    if(i==0){
      catalogdataid=catalogdata[i].catalogId
    }else{
      catalogdataid=catalogdataid+','+catalogdata[i].catalogId
    }
   }
   
    let cacheddata = localStorage.getItem('role');
    if (cacheddata === "System Administrator") {
      let globalcompanyIDselected = localStorage.getItem('globalcompanyIDselected');
        if(globalcompanyIDselected=='' || globalcompanyIDselected==undefined || globalcompanyIDselected==null){
          this.groupapibodycontent = {
            "companyIdShared": companylistdataid,
            "sharedFlag":'0',
            "category": this.filterform.value.Category.trim(),
            "type": this.filterform.value.CompanyType.trim(),
            "status": this.filterform.value.CompanyStatus.trim(),
            "startCreationDate": apistartdate,
            "endCreationDate": apienddate,
            "startUpdationDate": apistartdateLastUpdate,
            "endUpdationDate": apienddateLastUpdate,
            "createdBy": createdbyuser,
            "updatedBy": modifiedbyuser,
            "Customfield": this.filterform.value.Customfield.trim(),
            "courseId": this.filterform.value.CourseID.trim(),
            "title": this.filterform.value.CourseTitle.trim(),
            "catalogs":catalogdataid
          }
            }
            else{
              this.groupapibodycontent = {
                "companyIdShared": companylistdataid,
                "category": this.filterform.value.Category.trim(),
                "type": this.filterform.value.CompanyType.trim(),
                "status": this.filterform.value.CompanyStatus.trim(),
                "startCreationDate": apistartdate,
                "endCreationDate": apienddate,
                "startUpdationDate": apistartdateLastUpdate,
                "endUpdationDate": apienddateLastUpdate,
                "createdBy": createdbyuser,
                "updatedBy": modifiedbyuser,
                "Customfield": this.filterform.value.Customfield.trim(),
                "courseId": this.filterform.value.CourseID.trim(),
                "title": this.filterform.value.CourseTitle.trim(),
                "catalogs":catalogdataid
              }
            }
    }
    else {
      this.groupapibodycontent = {
        "companyIdShared": companylistdataid,
        "category": this.filterform.value.Category.trim(),
        "type": this.filterform.value.CompanyType.trim(),
        "status": this.filterform.value.CompanyStatus.trim(),
        "startCreationDate": apistartdate,
        "endCreationDate": apienddate,
        "startUpdationDate": apistartdateLastUpdate,
        "endUpdationDate": apienddateLastUpdate,
        "createdBy": createdbyuser,
        "updatedBy": modifiedbyuser,
        "Customfield": this.filterform.value.Customfield.trim(),
        "courseId": this.filterform.value.CourseID.trim(),
        "title": this.filterform.value.CourseTitle.trim(),
        "catalogs":catalogdataid
      }
    }
  }

  apicallgetdefaultdropdown() {
    // get default value on load companylist -- api call
    this.loadspinner = true;
    const endpoint1 = 'lookup';
    this.apiService.Simpleget(endpoint1, 'GET')
      .subscribe((response) => {
       
        this.companylistdata = response["companies"];
        this.statuslist = [
          { "status_id": 1, "statusName": "Active" },
          { "status_id": 2, "statusName": "Inactive" },
        ]
        this.category = [
          { "categoryName": "eCourse" },
          { "categoryName": "Curriculum" },
        ]
        this.type = [
          { "typeName": "TNG" },
          { "typeName": "KNT" },
          { "typeName": "Video" },
          { "typeName": "Other" },
        ]
        this.get_userslist();
        // this.createdBy = [
        //   { "createdByName": "Andy Finnigan" },
        // ]
        // this.modifiedBy = [
        //   { "modifiedByName": "Andy Finnigan" },
        // ]
        this.customField = [
          { "customFieldName": "Custom Field 01" },
        ]
       
      },
        (err) => {
          this.loadspinner = false;
          // console.log(err)

        });
  }

  get_userslist(){
    
    this.loadspinner = true;
    //role based seggregation
    let companyId:any='';
    let compId = localStorage.getItem('procCompId');
    let compName = localStorage.getItem('proccompanyName');
    let rolecacheddata = localStorage.getItem('role');
    if (rolecacheddata === "System Administrator") {
      let globalcompanyIDselected = localStorage.getItem('globalcompanyIDselected');
      if(globalcompanyIDselected=='' || globalcompanyIDselected==undefined || globalcompanyIDselected==null){
        companyId='';
      }
      else{
        companyId= globalcompanyIDselected
      }
    }
    else {
      companyId=compId
       
    }
  //role based seggregation
    const endpoint1 = 'users';
    const endpoint2 = 'searchusersfilter';
    let body = {
      "filters": {
        "firstName": "",
        "lastName": "",
        "loginName":"",
        "displayName": "",
        "email":"",
        "companyId":companyId,
        "empCode": "",
        "jobPositionName": "",
        "groupName": "",
        "roleName":"",
        "managerName":""
        }
        }
    
        this.apiService.manager_filterpost(endpoint1, endpoint2, body, 'POST')
        .subscribe((response) => {
       // this.loadspinner = false;
       
         let temp_data:any=response["userListFilter"];
      
      //  this.user_data= temp_data.filter(option => ( option.companyId.toLowerCase().includes(filterid.toLowerCase())));
       // this.user_data = response["userList"];
      this.createdBy=temp_data;
      this.modifiedBy=temp_data;
       this.getCatalogList()
      },
      (err) => {
        this.loadspinner = false;
      })
  }

  getCatalogList(){
    const endpoint1 = 'course';
    const endpoint2 = 'getcatalogs';
    let login_role = localStorage.getItem("loginRole");
    this.compId = localStorage.getItem('procCompId');
    let body;
    if (login_role == 'System Administrator') {
      body = {
        "companyId":""
      }
    }else {
      body = {
        "companyId":this.compId
      }
    }
    this.apiService.getcataloglist(endpoint1, endpoint2, body, 'POST')
      .subscribe((response) => {
        this.assgnCatlog = response['catalogsList'];
        this.loadspinner = false;
        this.setdefaultdropdownvalues();
      },
        (err) => {
          this.loadspinner = false;
        });
  }

  setdefaultdropdownvalues() {
    let cacheddata = localStorage.getItem('Manager_filterdata');
    if (cacheddata === '' || cacheddata === undefined || cacheddata === null) {
      this.disablebutton = true;
    }
    // if cached data present -- once filter btn was clicked
    else {
      this.disablebutton = false;
      let data = JSON.parse(cacheddata);
      
      let formcatalogvalues:any=[];
      let catalognames=data['catalogs'];
      let values=catalognames.split(',');
      for(var i=0; i<this.assgnCatlog.length; i++){
        for(var j=0; j<values.length; j++){
          if(values[j]==this.assgnCatlog[i].catalogName){
            formcatalogvalues.push(this.assgnCatlog[i]);
          }
        }
        
      }
    
      this.filterform.controls['CompanyStatus'].setValue(data["Status"]);
      this.filterform.controls['CompanyId'].setValue(data["Owner / Company ID"]);
      this.filterform.controls['CreatedBy'].setValue(data["Created By"]);
      this.filterform.controls['ModifiedBy'].setValue(data["Modified By"]);
      this.filterform.controls['CompanyType'].setValue(data["Type"]);
      this.filterform.controls['Category'].setValue(data["Category"]);
      this.filterform.controls['CourseID'].setValue(data["Course ID"]);
      this.filterform.controls['CourseTitle'].setValue(data["Course Title"]);
      this.filterform.controls['assignCatalog'].setValue(formcatalogvalues);

      // for date range
      let a = data["Creation Date Range"].split('-')[0];
      let b = data["Creation Date Range"].split('-')[1];
      //  console.log(a);
      if (data["Creation Date Range"] === null || data["Creation Date Range"] === undefined || data["Creation Date Range"] === "") {
        this.filterform.controls['CreationStartdate'].setValue("");
        this.filterform.controls['CreationEnddate'].setValue("");
      } else {
        this.filterform.controls['CreationStartdate'].setValue(new Date(a));
        this.filterform.controls['CreationEnddate'].setValue(new Date(b));
      }

      // for date range
     
      let c = data["Last updated Date Range"].split('-')[0];
      let d = data["Last updated Date Range"].split('-')[1];
      //  console.log(a);
      if (data["Last updated Date Range"] === null || data["Last updated Date Range"] === undefined || data["Last updated Date Range"] === "") {
        this.filterform.controls['LastUpdateStartdate'].setValue("");
        this.filterform.controls['LastUpdateEnddate'].setValue("");
      } else {
        this.filterform.controls['LastUpdateStartdate'].setValue(new Date(c));
        this.filterform.controls['LastUpdateEnddate'].setValue(new Date(d));
      }
      this.clearfilterfunction();
    }
    let compId = localStorage.getItem('procCompId');
    let compName = localStorage.getItem('proccompanyName');
    let rolecacheddata = localStorage.getItem('role');
    if (rolecacheddata != "System Administrator") {
      let data = compName + ' ' + '(' + compId + ')'
      this.filterform.controls['CompanyId'].setValue(data);
      this.isDisabled = true;
      this.disablebutton = false;
    }
    else {
      let globalcompanyIDselected = localStorage.getItem('globalcompanyIDselected');
      let globalcompanynameselected = localStorage.getItem('globalcompanynameselected');
        if(globalcompanyIDselected=='' || globalcompanyIDselected==undefined || globalcompanyIDselected==null){
          this.isDisabled=false;
        }
        else{
          let data = globalcompanynameselected + ' ' + '(' + globalcompanyIDselected + ')'
          this.filterform.controls['CompanyId'].setValue(data);
          this.isDisabled=true;
          this.disablebutton=false;
        }
    }
  }

  clearfilterfunction() {
    // if filterchip to remove filter is selcted remove the data on modal
    let clearfilteritemkey = JSON.parse(localStorage.getItem('Manager_clearfilteritemkey'));
    if (clearfilteritemkey === "" || clearfilteritemkey === undefined || clearfilteritemkey === null) {
    }
    else {
      for (let i = 0; i < clearfilteritemkey.length; i++) {
        if (clearfilteritemkey[i] === "Owner / Company ID") {
          this.filterform.controls['CompanyId'].setValue("");
        }
        else if (clearfilteritemkey[i] === "Type") {
          this.filterform.controls['CompanyType'].setValue("");
        }
        else if (clearfilteritemkey[i] === "Status") {
          this.filterform.controls['CompanyStatus'].setValue("");
        }
        else if (clearfilteritemkey[i] === "Creation Date Range") {
          this.filterform.controls['CreationStartdate'].setValue("");
          this.filterform.controls['CreationEnddate'].setValue("");
        }
        else if (clearfilteritemkey[i] === "Last updated Date Range") {
          this.filterform.controls['LastUpdateStartdate'].setValue("");
          this.filterform.controls['LastUpdateEnddate'].setValue("");
        }
        else if (clearfilteritemkey[i] === "Created By") {
          this.filterform.controls['CreatedBy'].setValue("");
        }
        else if (clearfilteritemkey[i] === "Modified By") {
          this.filterform.controls['ModifiedBy'].setValue("");
        }
        else if (clearfilteritemkey[i] === "Custom Field") {
          this.filterform.controls['Customfield'].setValue("");
        }
        else if (clearfilteritemkey[i] === "Category") {
          this.filterform.controls['Category'].setValue("");
        }
        else if (clearfilteritemkey[i] === "Course ID") {
          this.filterform.controls['CourseID'].setValue("");
        }
        else if (clearfilteritemkey[i] === "Course Title") {
          this.filterform.controls['CourseTitle'].setValue("");
        }
        else {
          this.filterform.controls[clearfilteritemkey[i]].setValue("");
        }

      }
    }
  }

  selectCategory(value) {
    if (value === "" || value === undefined || value === null) {
      // if none select set value as ''
      this.filterform.controls['Category'].setValue("");
      this.dynamicEnableDisablebtn()
    }
    else {
      this.checkformvalue();

      // let formempty;
      // if(this.filterform.value.CreationStartdate != "" || this.filterform.value.LastUpdateStartdate != "" || this.filterform.value.CreationEnddate != "" || this.filterform.value.LastUpdateEnddate != ""){
      //     if(this.filterform.value.CreationEnddate === null || this.filterform.value.LastUpdateEnddate === null || this.filterform.value.CreationStartdate === null || this.filterform.value.LastUpdateStartdate === null){
      //       formempty=true;
      //       return formempty;
      //     }
      //   } else {
      // this.disablebutton=false;
      //   }
    }
  }

  selectType(value) {
    if (value === "" || value === undefined || value === null) {
      // if none select set value as ''
      this.filterform.controls['CompanyType'].setValue("");
      this.dynamicEnableDisablebtn()
    }
    else {
      this.checkformvalue();
      // let formempty;
      // if(this.filterform.value.CreationStartdate != "" || this.filterform.value.LastUpdateStartdate != "" || this.filterform.value.CreationEnddate != "" || this.filterform.value.LastUpdateEnddate != ""){
      //     if(this.filterform.value.CreationEnddate === null || this.filterform.value.LastUpdateEnddate === null || this.filterform.value.CreationStartdate === null || this.filterform.value.LastUpdateStartdate === null){
      //       formempty=true;
      //       return formempty;
      //     }
      //   } else {
      // this.disablebutton=false;
      //   }
    }
  }

  selectStatusName(value) {
    if (value === "" || value === undefined || value === null) {
      // if none select set value as ''
      this.filterform.controls['CompanyStatus'].setValue("");
      this.dynamicEnableDisablebtn()
    }
    else {
      // let formempty;
      // if(this.filterform.value.CreationStartdate != "" || this.filterform.value.LastUpdateStartdate != "" || this.filterform.value.CreationEnddate != "" || this.filterform.value.LastUpdateEnddate != ""){
      //     if(this.filterform.value.CreationEnddate === null || this.filterform.value.LastUpdateEnddate === null || this.filterform.value.CreationStartdate === null || this.filterform.value.LastUpdateStartdate === null){
      //       formempty=true;
      //       return formempty;
      //     }
      //   } else {
      // this.disablebutton=false;
      //   }
      this.checkformvalue();

    }
  }

  selectCreatedBy(value) {
    if (value === "" || value === undefined || value === null) {
      // if none select set value as ''
      this.filterform.controls['CreatedBy'].setValue("");
      this.dynamicEnableDisablebtn()
    }
    else {
      // let formempty;
      // if(this.filterform.value.CreationStartdate != "" || this.filterform.value.LastUpdateStartdate != "" || this.filterform.value.CreationEnddate != "" || this.filterform.value.LastUpdateEnddate != ""){
      //     if(this.filterform.value.CreationEnddate === null || this.filterform.value.LastUpdateEnddate === null || this.filterform.value.CreationStartdate === null || this.filterform.value.LastUpdateStartdate === null){
      //       formempty=true;
      //       return formempty;
      //     }
      //   } else {
      // this.disablebutton=false;
      //   }
      this.checkformvalue();

    }
  }

  selectModifiedBy(value) {
    if (value === "" || value === undefined || value === null) {
      // if none select set value as ''
      this.filterform.controls['ModifiedBy'].setValue("");
      this.dynamicEnableDisablebtn()
    }
    else {
      // let formempty;
      // if(this.filterform.value.CreationStartdate != "" || this.filterform.value.LastUpdateStartdate != "" || this.filterform.value.CreationEnddate != "" || this.filterform.value.LastUpdateEnddate != ""){
      //     if(this.filterform.value.CreationEnddate === null || this.filterform.value.LastUpdateEnddate === null || this.filterform.value.CreationStartdate === null || this.filterform.value.LastUpdateStartdate === null){
      //       formempty=true;
      //       return formempty;
      //     }
      //   } else {
      // this.disablebutton=false;
      //   }
      this.checkformvalue();

    }
  }

  selectCustomfield(value) {
    if (value === "" || value === undefined || value === null) {
      // if none select set value as ''
      this.filterform.controls['Customfield'].setValue("");
      this.dynamicEnableDisablebtn()
    }
    else {
      // let formempty;
      // if(this.filterform.value.CreationStartdate != "" || this.filterform.value.LastUpdateStartdate != "" || this.filterform.value.CreationEnddate != "" || this.filterform.value.LastUpdateEnddate != ""){
      //     if(this.filterform.value.CreationEnddate === null || this.filterform.value.LastUpdateEnddate === null || this.filterform.value.CreationStartdate === null || this.filterform.value.LastUpdateStartdate === null){
      //       formempty=true;
      //       return formempty;
      //     }
      //   } else {
      // this.disablebutton=false;
      //   }
      this.checkformvalue();

    }
  }


  cancelbtn() {
    // if filterdata not present --- on first load cancel - clear data
    let data = JSON.parse(localStorage.getItem('Manager_clearfilteritemkey'));
    if (data === "" || data === undefined || data === null) {
    }
    else {
      let groupfilterdata = this.getbodycontent();
      localStorage.setItem('Manager_filterdata', JSON.stringify(groupfilterdata));
      localStorage.removeItem('Manager_clearfilteritemkey');
    }
    this.dialog.close({ method: 'close' })
  }

  selectCompanyID(value) {
    if (value === "" || value === undefined || value === null) {
      // if none select set value as ''
      this.filterform.controls['CompanyId'].setValue("");
      this.dynamicEnableDisablebtn();
    }
    else {
      // let formempty;
      // if(this.filterform.value.CreationStartdate != "" || this.filterform.value.LastUpdateStartdate != "" || this.filterform.value.CreationEnddate != "" || this.filterform.value.LastUpdateEnddate != ""){
      //     if(this.filterform.value.CreationEnddate === null || this.filterform.value.LastUpdateEnddate === null || this.filterform.value.CreationStartdate === null || this.filterform.value.LastUpdateStartdate === null){
      //       formempty=true;
      //       return formempty;
      //     }
      //   } else {
      // this.disablebutton=false;
      //   }
      this.checkformvalue()
    }
  }

  inputchangeID0(value) {
    this.dynamicEnableDisablebtn()
  }

  inputchangeID1(value) {
    this.dynamicEnableDisablebtn()
  }

  checkformvalue() {

    if (this.filterform.value.CreationStartdate != "" && this.filterform.value.CreationEnddate != "" && this.filterform.value.LastUpdateEnddate != "" && this.filterform.value.LastUpdateStartdate != "") {
      if (this.filterform.value.CreationStartdate != null && this.filterform.value.CreationEnddate != null && this.filterform.value.LastUpdateEnddate != null && this.filterform.value.LastUpdateStartdate != null) {
        this.disablebutton = false;
        this.dynamicEnableDisablebtn();
      }
    }

    let formempty;
    if (this.filterform.value.CreationStartdate != "" || this.filterform.value.LastUpdateStartdate != "" || this.filterform.value.CreationEnddate != "" || this.filterform.value.LastUpdateEnddate != "") {
      if (this.filterform.value.CreationEnddate === null || this.filterform.value.LastUpdateEnddate === null || this.filterform.value.CreationStartdate === null || this.filterform.value.LastUpdateStartdate === null) {
        formempty = true;
        // return formempty;
      }
    } else {
      this.disablebutton = false;
    }

    if (this.filterform.value.Category.trim() === "" && this.filterform.value.CompanyId.trim() === "" &&
      this.filterform.value.CompanyType.trim() === "" && this.filterform.value.CompanyStatus.trim() === ""
      && this.filterform.value.CreationStartdate === "" && this.filterform.value.CreationEnddate === ""
      && this.filterform.value.LastUpdateStartdate === "" && this.filterform.value.LastUpdateEnddate === ""
      && this.filterform.value.CreatedBy.trim() === "" && this.filterform.value.ModifiedBy.trim() === ""
      && this.filterform.value.Customfield.trim() === "" && this.filterform.value.CourseID.trim() === ""
      && this.filterform.value.CourseTitle.trim() === "") {
        
      formempty = true;
    }
    else {
      formempty = false;
    }
    return formempty;
  }

  Filter() {
    
    if (this.filterform.valid) {
      localStorage.removeItem('Manager_filterdata');
      localStorage.removeItem('Manager_chipdisplayfilterdata');
      localStorage.removeItem('Manager_clearfilteritemkey');
      let groupfilterdata = this.getbodycontent();
      localStorage.setItem('Manager_filterdata', JSON.stringify(groupfilterdata));
      this.dialog.close({ data: groupfilterdata, groupapibodycontent: this.groupapibodycontent, method: 'filter' });
    }

  }



  dynamicEnableDisablebtn() {
    let checkformfield = this.checkformvalue();
    if (checkformfield === true) {
      this.disablebutton = true;
    }
    else {
      this.disablebutton = false;
    }

  }

  changeStartDateCreation(event) {
    if (event.value != null) {
      // if(this.filterform.value.CreationStartdate != "" && this.filterform.value.CreationEnddate != "" && this.filterform.value.LastUpdateEnddate != "" && this.filterform.value.LastUpdateStartdate != "" ){
      //   if(this.filterform.value.CreationStartdate != null && this.filterform.value.CreationEnddate != null && this.filterform.value.LastUpdateEnddate != null && this.filterform.value.LastUpdateStartdate != null){
      //     this.disablebutton=false;
      //  this.dynamicEnableDisablebtn();
      //     }
      //   }
      this.checkformvalue()
    }
    else {
      this.disablebutton = true;
    }
    if (event.value === null) {
      this.disablebutton = true;
    }
    else {
      this.dynamicEnableDisablebtn();
    }

  }
  changeEndDateCreation(event) {
    if (event.value != null) {
      // if(this.filterform.value.CreationStartdate != "" && this.filterform.value.CreationEnddate != "" && this.filterform.value.LastUpdateEnddate != "" && this.filterform.value.LastUpdateStartdate != "" ){
      // if(this.filterform.value.CreationStartdate != null && this.filterform.value.CreationEnddate != null && this.filterform.value.LastUpdateEnddate != null && this.filterform.value.LastUpdateStartdate != null){
      //   this.disablebutton=false;
      //     // console.log("insideendcreation")
      //   }}
      this.checkformvalue()

    }
    else {
      this.disablebutton = true;

    }
    if (event.value === null) {
      this.disablebutton = true;
    }
    else {
      this.dynamicEnableDisablebtn();
    }

  }

  changeStartDateLastUpdate(event) {
    if (event.value != null) {
      // if(this.filterform.value.CreationStartdate != "" && this.filterform.value.CreationEnddate != "" && this.filterform.value.LastUpdateEnddate != "" && this.filterform.value.LastUpdateStartdate != "" ){
      //   if(this.filterform.value.CreationStartdate != null && this.filterform.value.CreationEnddate != null && this.filterform.value.LastUpdateEnddate != null && this.filterform.value.LastUpdateStartdate != null){
      //   this.disablebutton=false;
      //     this.dynamicEnableDisablebtn();
      //   }}
      this.checkformvalue()

    }
    else {
      this.disablebutton = true;
    }
    if (event.value === null) {
      this.disablebutton = true;
    }
    else {
      this.dynamicEnableDisablebtn();
    }

  }
  changeEndDateLastUpdate(event) {

    if (event.value != null) {
      // if(this.filterform.value.CreationStartdate != "" && this.filterform.value.CreationEnddate != "" && this.filterform.value.LastUpdateEnddate != "" && this.filterform.value.LastUpdateStartdate != "" ){
      //   if(this.filterform.value.CreationStartdate != null && this.filterform.value.CreationEnddate != null && this.filterform.value.LastUpdateEnddate != null && this.filterform.value.LastUpdateStartdate != null){
      // this.disablebutton=false;
      //     this.dynamicEnableDisablebtn();
      //   }}
      this.checkformvalue()

    }
    else {
      this.disablebutton = true;

    }
    if (event.value === null) {
      this.disablebutton = true;
    }
    else {
      this.dynamicEnableDisablebtn();
    }
  }

  EnterSubmit(event) {
    // console.log(event)
    if (event.keyCode === 13) {
      //this.Filter();
      //  this.dynamicEnableDisablebtn();
      if (this.disablebutton === true) {
        event.preventDefault();
      }
      else {
        event.preventDefault();
        this.Filter();
      }

    }
  }

  catalog_none(){
    let value='None'
    let temp_data:any=[];
    temp_data.push(value)
    this.filterform.controls['assignCatalog'].setValue(temp_data);
  }

catalog_other(){
  let value = this.filterform.controls.assignCatalog.value;
  for(let i=0; i<value.length; i++){
    if(value[i]=='None' ){
      value.splice(i, 1)
    }
  }
  let temp_data:any=[];
  value.forEach(element => {
    temp_data.push(element)
  }) 
  this.filterform.controls['assignCatalog'].setValue(temp_data);
}


}
