<div class="spinner_overlay" *ngIf="loadspinner===true">
</div>
<mat-spinner class="spinner" *ngIf="loadspinner===true"></mat-spinner>



<div class="filtermaincontainer" (keydown)="EnterSubmit($event)">
  <div class="row headerrow">
    <div class="col-10">
      <img src="assets/images/filter-filled-tool-symbol.svg" alt="filter">
      <span class="heading">&nbsp;&nbsp;Filter</span>
    </div>

    <div class="col-2 cleariconcol">
      <mat-icon class="clearicon" alt="clear" (click)="cancelbtn()">clear</mat-icon>
    </div>
  </div>

  <form [formGroup]="filterform">

    <div class="row">
      <div class="col-12 col-md-12 inputcolumn">
        <mat-label id="usernameid" class="labelname">Title</mat-label>
        <mat-form-field appearance="outline" id="formfieldusernameid" class="formfieldusertable">
          <input matInput placeholder="Enter here" autocomplete="off" #inpID0 (keyup)="inputchangeID0(inpID0.value)" class="textinputmedium"
            formControlName="Title">
        </mat-form-field>
      </div>

      <div class="col-12 col-md-12 inputcolumn">
        <mat-label class="labelname">Start Date</mat-label><br>
        <mat-form-field floatLabel="always" appearance="none" class="formfieldusertable">

          <mat-date-range-input [rangePicker]="picker" (click)="picker.open()"  style="cursor: pointer;" class="textinputdate" placeholder="mm/dd/yyyy"
          readonly>
            <input matStartDate placeholder="mm/dd/yyyy" formControlName="start" (dateChange)="startChange($event)" style="cursor: pointer;" readonly>
            <input matEndDate placeholder="mm/dd/yyyy" formControlName="end" (dateChange)="endChange($event)" style="cursor: pointer;" readonly>
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>

      </div>
      <div class="col-12 col-md-12 inputcolumn"></div>
      <div class="col-12 col-md-12 inputcolumn">
        <mat-label class="labelname">End Date</mat-label><br>
        <mat-form-field floatLabel="always" appearance="none" class="formfieldusertable">

          <mat-date-range-input [rangePicker]="picker1" (click)="picker1.open()"  style="cursor: pointer;" class="textinputdate" placeholder="mm/dd/yyyy"
          readonly>
            <input matStartDate placeholder="mm/dd/yyyy" formControlName="startApproveddenied" (dateChange)="startChangeApproveddenied($event)" style="cursor: pointer;" readonly>
            <input matEndDate placeholder="mm/dd/yyyy" formControlName="endApproveddenied" (dateChange)="endChangeApproveddenied($event)" style="cursor: pointer;" readonly>
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-date-range-picker #picker1></mat-date-range-picker>
        </mat-form-field>

      </div>

    </div>

    <div class="row">
      <div class="col-6 cancelbtncol"><br><br>
        <button mat-button id="btncancelid" class="cancelbtn" (click)="cancelbtn()">
          <mat-icon class="Cancelicon" alt="clear">clear</mat-icon>
          Cancel
        </button>
      </div>
      <div class="col-6 Filterbtncol"><br><br>
        <button mat-button id="btnfiltertermid" class="Filterbtn" [disabled]="disablebutton" (click)="Filter()">
          <mat-icon class="filterbtnicon">done</mat-icon>
          Filter
        </button>
      </div>
    </div>
  </form>

</div>
