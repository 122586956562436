<div class="spinner_overlay" *ngIf="loadspinner===true">
</div>
<mat-spinner class="spinner" *ngIf="loadspinner===true"></mat-spinner>



<div class="usermaincontainer formstructure" id="user-content">
<div class="col-12">
<mat-card class="example-card mat-elevation-z8 matcard">

<div class="row">
<div class="col-md-5"> </div>

<div class="col-md-2" *ngIf="!expandbtn">
<button mat-button *ngIf="structurelist?.length>1" (click)="openAll()" class="rowbtn collpsebtn">
<img src="assets/images/expand.webp">&#160;&#160;Expand All</button>
</div>
<div class="col-md-2" *ngIf="expandbtn">
<button mat-button *ngIf="structurelist?.length>1" (click)="openAll()" class="expandbtn">
<img src="../../../../../assets/images/bitmap@3x.webp"
style="width: 16px; margin-top: -3px;">&#160;&#160;Expand All</button>
</div>
<div class="col-md-2" *ngIf="collapsebtn">
<button mat-button *ngIf="structurelist?.length>1" (click)="closeAll()" class="expandbtn">
<img src="../../../../../assets/images/collapse1.png"
style="width: 16px; margin-top: -3px;">&#160;&#160;Collapse All</button>
</div>
<div class="col-md-2" *ngIf="!collapsebtn">
<button mat-button *ngIf="structurelist?.length>1" (click)="closeAll()" class="rowbtn">
<img src="assets/images/collapse.webp">&#160;&#160;Collapse All</button>
</div>
<div class="col-md-2">
<!-- <button mat-button (click)="Addsteps()" class="rowbtn formstaructure_view"
style="margin-right: 10%;">
<img src="../../../../../assets/images/addsteps.png" style="width: 14px; margin-top: -2px;">
&#160;
Add Steps </button> -->
</div>
<div class="col-md-1">

    <!-- <span class="exporticon" *ngIf="A_C_FORMS_STRUCTURE_VIEW!=false">
        <img src="assets/images/edit_active.png" alt="filter" title="Edit"
        style="width: 45px; cursor: pointer; margin-top: -2px; display: none;"
        id="edit_active">
        <img *ngIf="sharedForm == 'No'" src="assets/images/edit.svg" alt="filter" title="Edit"
        style="width: 45px; cursor: pointer;  margin-top: -2px;" id="view_active"
        (click)="enable_edit()">
        <img *ngIf="sharedForm != 'No'" src="assets/images/edit.svg" alt="filter" title="Edit"
        style="width: 45px; cursor: pointer;  margin-top: -2px; opacity:0.5;cursor: not-allowed;" id="view_active"
        >
        </span> -->

</div>
</div>

<br> <br>

<div class="row" style="margin-bottom: -5%; display: none;" id="save_sucess">
<div class="col-8"> </div>
<div class="col-4">
<!-- <div class="alert success_tab" role="alert">
<span> SUCCESSFULLY. Saved the Form Structure. </span>
</div> -->
</div>
</div>

<div class="row" style="margin-bottom: -5%; display: none;" id="publish_sucess">
<div class="col-8"> </div>
<div class="col-4">
<!-- <div class="alert success_tab" role="alert">
<span> SUCCESSFULLY. Published the Form Structure. </span>
</div> -->
</div>
</div>


<form [formGroup]="structureForm" style="margin: 1.5%;">
<mat-accordion #accordion cdkDropList #unassignedList="cdkDropList" [cdkDropListData]="structurelist"
(cdkDropListDropped)="drop($event)" class="example-headers-align" multi>
<mat-expansion-panel *ngFor="let steps of structurelist let i=index;" cdkDrag>
<mat-expansion-panel-header style="box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);">
<mat-panel-title> <img src="../../../../../assets/images/bitmap.webp" class="drag_img">
&#160;&#160;
Step {{i+1}}
</mat-panel-title>
</mat-expansion-panel-header>
<br>

<!-- start Form -->

<div class="row">
<mat-label class="col-2 normal">Image Capturing</mat-label>
<div class="col-8">


<input type="radio" id="yes" [checked]="steps.camera == true" name="imagecap-{{i}}"
value="true">&#160;&#160; <span> Show Camera </span>&#160;&#160;
<input type="radio" id="yes" [checked]="steps.camera == false" name="imagecap-{{i}}"
value="false">&#160;&#160; <span> Hide Camera </span>






</div>
<div class="col-2">
<!-- <div class="delete_icon formstaructure_view" style="float: right;"
(click)="delete_steps(i)"> <img width="22" height="25"
src="assets/images/delete_list.svg" alt="filter">
</div> -->
</div>
</div>

<div class="row">
<mat-label class="col-3 normal">Performance Rating Not Applicable</mat-label>
<mat-checkbox labelPosition="after" class="labelname create_select"
[checked]="steps.performanceRatingNA" name="performance{{i}}"> </mat-checkbox>
</div>

<div class="row">
<div class="col-11">
<mat-card style="border-left: 10px solid #eaaa00; margin-left: 0.2%;">
<mat-label class="col-3">Enter Step Text</mat-label>
<mat-form-field appearance="outline" class="formfield" style="width: 100%;">
<input matInput placeholder="Enter Step Text" class="textinput" maxlength="300"
name="steptext{{i}}" value="{{steps.stepText.label}}">
</mat-form-field>
<br>
<mat-label class="col-3"> Enter Step Description </mat-label> <br>
<mat-form-field appearance="outline" class="formfield" style="width: 100%;">
<textarea matInput placeholder="Enter Step Description" maxlength="300" class="textinput" name="stepdesc{{i}}">{{steps.stepDescription.label}}</textarea>
</mat-form-field>
</mat-card>
</div>
<div class="col-1">
</div>
</div>




<!-- <button class="Addlevel_btn formstaructure_view" (click)="Addlevel(i)" id="addlevel_btn{{i}}"
style="display: none;" (click)="addleveltext(i)">
<img src="../../../../../assets/images/Addlevel.png" width="32px" height="32px"> &#160; Add
Level
</button> -->

<div class="row" id="addlevel_div{{i}}">
<br> &#160; <br>
<div class="col-12" *ngFor="let leveltext of steps.levels let j=index;">

<!-- connection line div starts -->
<svg width="500" height="120" style="margin-top: -5%;">
<line x1="7" y1="0" x2="7" y2="350" stroke="#eaaa00" />
</svg>
<div
style="display: list-item; list-style-type: disc; list-style-position: inside; color: #eaaa00; margin-left: 2px; font-size: 35px; margin-top: -67px;">
<svg style="margin-left: -37px;">
<line x1="0" y1="50%" x2="60" y2="50%" style="stroke:#eaaa00;stroke-width:1" />
</svg>
</div>
<svg width="500" height="400" style="margin-top:-17%">
<line *ngIf="addline && j != steps.levels.length-1" x1="7" y1="0" x2="7" y2="500"
stroke="#eaaa00" />
</svg>
<!-- Connection line div ends -->


<!-- level div starts -->
<div class="row" style="margin-top: -35%;">
<div class="col-11">
<mat-card style="border-left: 10px solid #143249; margin-left: 5%; ">
<mat-label class="col-3">Level Text</mat-label> <br>
<mat-form-field appearance="outline" class="formfield" style="width: 100%;">
<textarea matInput placeholder="Level Text" class="textinput"
name="leveltext{{i}}-{{j}}"
maxlength="300"> {{leveltext.label}} </textarea>
</mat-form-field>
</mat-card>
</div>
<div class="col-1" style="margin-top: 2%;">
<!-- <button class="Addlevel_btn formstaructure_view"
(click)="deleteleveltext(i, j)">
<img src="assets/images/group-34-copy.svg" width="32px"> </button> -->
<br> <br> <br>
<!-- <button class="Addlevel_btn formstaructure_view" (click)="addleveltext(i)"> <img
src="assets/images/Addlevel.png" width="32px"> </button> -->
</div>
</div>
<!-- levels div ends -->


<!-- sublevels div start -->
<div class="row" *ngFor="let subleveltext of leveltext.subLevel let k=index;">
<div class="col-11">


<!-- sublevel connection line div starts -->
<svg width="500" height="120" style="margin-top: 0%;">
<line x1="54" y1="0" x2="54" y2="350" stroke="#143249" />
</svg>
<div
style="display: list-item; list-style-type: disc; list-style-position: inside; color: #143249; margin-left: 48px; font-size: 35px; margin-top: -67px;">
<svg style="margin-left: -37px;">
<line x1="0" y1="50%" x2="60" y2="50%"
style="stroke:#143249;stroke-width:1" />
</svg>
</div>
<svg width="500" height="120" style="margin-top: -11%;">
<line *ngIf="addsubline && k != leveltext.subLevel.length-1" x1="54" y1="0"
x2="54" y2="350" stroke="#143249" />
</svg>

<!-- sublevel Connection line div ends -->



<mat-card
style="border-left: 10px solid #8cc1da; margin-left: 10%; margin-top: -19%;">
<mat-label class="col-3">SubLevel Text</mat-label> <br>
<mat-form-field appearance="outline" class="formfield" style="width: 100%;">
<textarea matInput placeholder="SubLevel Text" class="textinput"
name="sublevel{{i}}-{{j}}-{{k}}"
maxlength="300"> {{subleveltext.label}}    </textarea>
</mat-form-field>
</mat-card>
</div>
<div class="col-1" style="margin-top: 6%;">
<!-- <button class="Addlevel_btn formstaructure_view"
(click)="deletesubleveltext(i, j, k)"> <img
src="assets/images/group-34-copy-4.svg" width="32px"> </button> -->
<br> <br> <br>
<!-- <button class="Addlevel_btn formstaructure_view" (click)="Addsublevels(i, j)">
<img src="assets/images/add-sublevel.png" width="32px"> </button> -->
</div>
</div>
<br> &#160; <br>
<!-- sublevel div ends -->

</div>
</div>

</mat-expansion-panel>
</mat-accordion>
</form>



</mat-card>
<br> <br>
<div class="row" id="btn_div" style="display: none;">
<div class="col-3"> </div>
<div class="col-2">
<!-- <button mat-button class="savebtn" (click)="save('Draft')" [disabled]="savebutton">
<mat-icon class="createbtnicon" alt="clear">done</mat-icon>
Save
</button> -->
</div>
<div class="col-2">
<!-- <button mat-button class="cancelbtn" (click)="cancel()" [disabled]="cancelbutton">
<mat-icon class="Cancelicon" alt="clear">clear</mat-icon>
Cancel
</button> -->
</div>
<div class="col-2">
<!-- <button mat-button class="savebtn" (click)="save('Published')" [disabled]="publishbutton" *ngIf="A_C_FORMS_STRUCTURE_PUBLISH!=false">
<mat-icon class="createbtnicon">done</mat-icon>
Publish
</button> -->
</div>
<div class="col-2"> </div>
</div>
<br>
</div>
</div>
