import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CompanyManagementComponent } from 'src/app/Administrator/company-management/company-management.component';
import { ApiServiceService } from 'src/app/Services/api-service.service';

@Component({
  selector: 'app-termsandconditionlistfilter',
  templateUrl: './termsandconditionlistfilter.component.html',
  styleUrls: ['./termsandconditionlistfilter.component.scss'],
  providers: [DatePipe]
})
export class TermsandconditionlistfilterComponent implements OnInit {

  filterform: FormGroup;
  companylistdata: any = [""];
  categorylist: any = [""];
  loadspinner: any = false;
  apibodycontent: any;
  disablebutton: any;
  today = new Date();
  enddateconsistnull: any;
  enddateconsistnullApproveddenied: any;
  constructor(private datePipe: DatePipe, private formBuilder: FormBuilder, private apiService: ApiServiceService, public dialog: MatDialogRef<CompanyManagementComponent>) {
    this.filterform = this.formBuilder.group({
      Title: [''],
      start: [''],
      end: [''],
      startApproveddenied: [''],
      endApproveddenied: [''],


    });
    this.apicallgetdefaultdropdown();
  }

  ngOnInit(): void {
  }

  apicallgetdefaultdropdown() {

    this.setdefaultdropdownvalues();

  }

  setdefaultdropdownvalues() {
    let cacheddata = localStorage.getItem('Manager_filterdata');
    if (cacheddata === '' || cacheddata === undefined || cacheddata === null) {
      this.disablebutton = true;
    }
    // if cached data present -- once filter btn was clicked
    else {
      this.disablebutton = false;
      let data = JSON.parse(cacheddata);
      this.filterform.controls['Title'].setValue(data["Title"]);

      // for date range date selected
      let a = data["Start Date"].split('-')[0];
      let b = data["Start Date"].split('-')[1];
      if (data["Start Date"] === null || data["Start Date"] === undefined || data["Start Date"] === "") {
        this.filterform.controls['start'].setValue('');
        this.filterform.controls['end'].setValue("");

      } else {
        this.filterform.controls['start'].setValue(new Date(a));
        this.filterform.controls['end'].setValue(new Date(b));
      }

      // for date range date approved/denied
      let c = data["End Date"].split('-')[0];
      let d = data["End Date"].split('-')[1];
      if (data["End Date"] === null || data["End Date"] === undefined || data["End Date"] === "") {
        this.filterform.controls['startApproveddenied'].setValue("");
        this.filterform.controls['endApproveddenied'].setValue("");
      } else {
        this.filterform.controls['startApproveddenied'].setValue(new Date(c));
        this.filterform.controls['endApproveddenied'].setValue(new Date(d));
      }
      this.clearfilterfunction();
    }
  }

  clearfilterfunction() {
    // if filterchip to remove filter is selcted remove the data on modal
    let clearfilteritemkey = JSON.parse(localStorage.getItem('Manager_clearfilteritemkey'));
    if (clearfilteritemkey === "" || clearfilteritemkey === undefined || clearfilteritemkey === null) {
    }
    else {
      for (let i = 0; i < clearfilteritemkey.length; i++) {
        if (clearfilteritemkey[i] === "Title") {
          this.filterform.controls['Title'].setValue("");
        }

        else if (clearfilteritemkey[i] === "Start Date") {
          this.filterform.controls['start'].setValue("");
          this.filterform.controls['end'].setValue("");
        }

        else if (clearfilteritemkey[i] === "End Date") {
          this.filterform.controls['startApproveddenied'].setValue("");
          this.filterform.controls['endApproveddenied'].setValue("");
        }

        else {
          this.filterform.controls[clearfilteritemkey[i]].setValue("");
        }

      }
    }
  }



  inputchangeID0(value) {
    this.dynamicEnableDisablebtn()
  }

  startChange(event) {
    console.log("startChange", event.value)
    //console.log(this.filterform.value.end);
  }

  endChange(event) {
    console.log("endChange", event.value);
    if (event.value === null) {
      this.disablebutton = true;
      this.enddateconsistnull = true;
    }
    else {
      this.enddateconsistnull = false;
      this.dynamicEnableDisablebtn();
    }
    //
  }

  startChangeApproveddenied(event) {
    console.log("startChange", event.value)
    //console.log(this.filterform.value.end);
  }

  endChangeApproveddenied(event) {
    console.log("endChange", event.value);
    if (event.value === null) {
      this.disablebutton = true;
      this.enddateconsistnullApproveddenied = true;
    }
    else {
      this.enddateconsistnullApproveddenied = false;
      this.dynamicEnableDisablebtn();
    }
    //
  }

  selectTitle(value) {
    if (value === "" || value === undefined || value === null) {
      // if none select set value as ''
      //this.filterform.controls['Category'].setValue("");
    }
    else {
      this.disablebutton = false;
    }
    this.dynamicEnableDisablebtn();
  }


  cancelbtn() {
    // if filterdata not present --- on first load cancel - clear data
    let data = JSON.parse(localStorage.getItem('Manager_clearfilteritemkey'));
    if (data === "" || data === undefined || data === null) {
    }
    else {
      let filterdata = this.getbodycontent();
      localStorage.setItem('Manager_filterdata', JSON.stringify(filterdata));
      localStorage.removeItem('Manager_clearfilteritemkey');
    }
    this.dialog.close({ method: 'close' })
  }

  Filter() {
    if (this.filterform.valid) {
      localStorage.removeItem('Manager_filterdata');
      localStorage.removeItem('Manager_chipdisplayfilterdata');
      localStorage.removeItem('Manager_clearfilteritemkey');
      let filterdata = this.getbodycontent();
      localStorage.setItem('Manager_filterdata', JSON.stringify(filterdata));
      this.dialog.close({ data: filterdata, apibodycontent: this.apibodycontent, method: 'filter' });
    }

  }



  EnterSubmit(event) {
    if (event.keyCode === 13) {
      //this.Filter();
      this.dynamicEnableDisablebtn();
      if (this.disablebutton === true) {
        event.preventDefault();
      }
      else {
        event.preventDefault();
        this.Filter();
      }

    }
  }

  getbodycontent() {
    let daterange = "";
    let startdate = "";
    let enddate = "";
    let apistartdate = "";
    let apienddate = "";

    let startApprovedDeniedDate = "";
    let endApprovedDeniedDate = "";
    let apistartApprovedDeniedDate = "";
    let apiendApprovedDeniedDate = "";
    let daterangeapproveddenied = "";


    // start date
    if (this.filterform.value.start === "" || this.filterform.value.start === undefined || this.filterform.value.start === null) {
      daterange = "";
    }
    else {
      startdate = this.datePipe.transform(this.filterform.value.start, 'MM/dd/yyyy');
      enddate = this.datePipe.transform(this.filterform.value.end, 'MM/dd/yyyy');
      daterange = startdate + " - " + enddate;

      apistartdate = this.datePipe.transform(this.filterform.value.start, 'yyyy-MM-dd');
      apienddate = this.datePipe.transform(this.filterform.value.end, 'yyyy-MM-dd');
    }

    // end date
    if (this.filterform.value.startApproveddenied === "" || this.filterform.value.startApproveddenied === undefined || this.filterform.value.startApproveddenied === null) {
      daterangeapproveddenied = "";
    }
    else {
      startApprovedDeniedDate = this.datePipe.transform(this.filterform.value.startApproveddenied, 'MM/dd/yyyy');
      endApprovedDeniedDate = this.datePipe.transform(this.filterform.value.endApproveddenied, 'MM/dd/yyyy');
      daterangeapproveddenied = startApprovedDeniedDate + " - " + endApprovedDeniedDate;

      apistartApprovedDeniedDate = this.datePipe.transform(this.filterform.value.startApproveddenied, 'yyyy-MM-dd');
      apiendApprovedDeniedDate = this.datePipe.transform(this.filterform.value.endApproveddenied, 'yyyy-MM-dd');
    }

    let filterdata = {
      "Title": this.filterform.value.Title.trim(),
      "Start Date": daterange,
      "End Date": daterangeapproveddenied,

    }
    this.getapibodycontent(daterange, apistartdate, apienddate, daterangeapproveddenied, apistartApprovedDeniedDate, apiendApprovedDeniedDate);
    return filterdata

  }

  getapibodycontent(daterange, apistartdate, apienddate, daterangeapproveddenied, apistartApprovedDeniedDate, apiendApprovedDeniedDate) {
    let compId = localStorage.getItem('procCompId');
    let compName = localStorage.getItem('proccompanyName');
    let rolecacheddata = localStorage.getItem('role');
    if (rolecacheddata != "System Administrator") {
      this.apibodycontent = {
        "title": this.filterform.value.Title.trim(),
        "termsOwner": compId,
        "startDateBegin": apistartdate,
        "startDateEnd": apienddate,
        "endDateBegin": apistartApprovedDeniedDate,
        "endDateEnd": apiendApprovedDeniedDate
      }
    }
    else {
      this.apibodycontent = {
        "title": this.filterform.value.Title.trim(),
        "startDateBegin": apistartdate,
        "startDateEnd": apienddate,
        "endDateBegin": apistartApprovedDeniedDate,
        "endDateEnd": apiendApprovedDeniedDate
      }
    }

  }

  checkformvalue() {
    let formempty;
    if ((this.filterform.value.Title.trim() === "") &&
      (this.filterform.value.start === "" || this.filterform.value.start === null) &&
      (this.filterform.value.end === "" || this.filterform.value.end === null) &&
      (this.filterform.value.startApproveddenied === "" || this.filterform.value.startApproveddenied === null) &&
      (this.filterform.value.endApproveddenied === "" || this.filterform.value.endApproveddenied === null)) {
      formempty = true;

    }
    else {
      formempty = false;
      if (this.enddateconsistnull === true || this.enddateconsistnullApproveddenied === true) {
        formempty = true;
      }

    }
    return formempty;
  }

  dynamicEnableDisablebtn() {
    let checkformfield = this.checkformvalue();
    if (checkformfield === true) {
      this.disablebutton = true;
    }
    else {
      this.disablebutton = false;
    }
  }

}
