<div *ngIf="screenwidth>1269">

  <!-- <div *ngIf="impersonate_div===true" style="margin-left: 1%; margin-right: -2%;">
    <div class="alert alert-danger alert-dismissible fade show" role="alert" style="padding: 0.35rem 1.25rem; margin-bottom:0%;">
     <span style="color:#ffff"> You're viewing in <strong>Impersonate Mode </strong>as {{ImpersonatedUser}} !</span>
      <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="impersonate_close()" style="padding: 0.35rem 1.25rem;">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div> -->

  <!-- header -->
  <div class="headermaincontainer" *ngIf="hideHeader==true">
  <div class="row headerrow">
    <div class="col-sm-12"  *ngIf="impersonate_div===true" style="background-color: red;">
      <div class="alert alert-danger alert-dismissible fade show" role="alert" style="padding: 0.25rem 0.25rem; margin-bottom:0%;">
        <span style="color:#ffff"> You're viewing in <strong>Impersonate Mode </strong>as {{ImpersonatedUser}}.</span>
         <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="impersonate_close()" style="padding: 0.25rem 0.25rem;color: #fff;">
           <span aria-hidden="true">&times;</span>
         </button>
       </div>
      </div>
    <div class="col-sm-1 headercol">
      <button size="medium" mat-mini-fab (click)="toggle_btn()" class="togglebtn">
        <img [src]="p ? 'assets/images/keyboard_arrow_right-24px.svg' : 'assets/images/keyboard_arrow_left-24px.svg'"
          alt="togglebutton">
      </button>
    </div>

    <div class="col-sm-5 headerrolecol">
      <div class="Headertextrole" id="headerrole">
        <!-- {{headerrole.objName}} -->
      </div>

    </div>

    <div class="col-sm-3">
      <!-- <div (click)="logout()">logout</div> -->
      <mat-form-field appearance="outline" class="matformfieldheader header-field" *ngIf="role==='System Administrator'">
        <!-- <mat-label>Favorite food</mat-label> -->
        <mat-select [(ngModel)]="modeSelect" placeholder="Companies" class="selectvalueclass" panelClass="myPanelmatselectClass" (selectionChange)=selectcompanylist(modeSelect) disableOptionCentering>
          <mat-option [value]="" class="none_style">  None </mat-option>
          <mat-option *ngFor="let item of companylistdata" [value]="item.companyId" >
            <div class="matoptiontxt">{{item.companyName}}</div>
          </mat-option>
        </mat-select>
      </mat-form-field>

    </div>

    <div style="display:none" class="dividerline" *ngIf="role==='System Administrator'">

    </div>

    <div class="col-sm-3">

      <mat-form-field appearance="outline" class="matformfieldheader header-field" style="display: none;">
        <input type="text" #searchinput matInput [(ngModel)]="filterValue" class="textsearchinput" placeholder="Search..." autocomplete="off">
        <mat-icon matSuffix (click)="search(searchinput.value)" class="searchbtn" style="top: 0px!important;">search</mat-icon>
      </mat-form-field>

    </div>
    <div class="col-12 ">
      <div class="Headertextsubrole" id="header_breadcrumb">
        <!-- {{header_breadcrumb}} -->
      </div>
    </div>
  </div>



  <!-- side mini bar -->

  <div class="sidebar-mini">
    <div class="row sidebarlogorow">
      <div class="col-sm-12" style="margin-top: 10px;">
        <!-- <img src="assets/images/logoonlyimage.png" height="55px" alt="MEA logo" /> -->
        <img  *ngIf="companylogo=='https://uat.stellar-oqcs.com/assets/images/mea-logo.svg'" src="assets/images/logoonlyimage.png" height="55px" alt="MEA logo"/>
        <img  *ngIf="companylogo!='https://uat.stellar-oqcs.com/assets/images/mea-logo.svg'" src="{{companylogo}}" alt="MEA logo" class="sidemenulogoicon" style="width: 80%; height: 55px;"/>
      </div>

    </div>

    <br>



    <div class="col-sm-12 minisidebarprofilecol">
      <!-- <div class="menutext"> -->
      <div class="row">
        <div class="col-sm-12">
          <img src={{logo}}  class="sidemenuiconprofile">
        </div>
      </div>
    </div>


    <div class="miniscroll">
      <mat-nav-list style="margin-bottom: 10%;">
        <div *ngFor="let data of appPages;let i=index;">
          <div class="nested-menu " *ngIf="data.Showdata===true" >
          <br>
          <!-- side menu no submenu exist-->
          <div class="menutext" mat-list-item [routerLinkActive]="'is-active'" #rla="routerLinkActive"
            (click)="menuItem_btn(data)" [routerLink]="data.path" [queryParams]="{ menuTitle: data.title }" *ngIf="data.path !=''">
            <mat-grid-list cols="1" rowHeight="50px">
              <mat-grid-tile>
                <div class="left-align-forced" #tooltip="matTooltip"  matTooltipClass="minisidemenu-tooltip" matTooltip={{data.sideMenutitle}}>
                  <img src="{{rla.isActive ? data.Icon1 : data.Icon}}" alt={{data.title}} style="width:auto;height:inherit;" />
                </div>
              </mat-grid-tile>
            </mat-grid-list>
          </div>
      </div>



          <!-- side menu if submenu exist-- show icon -->
          <div class="menutext" id="menuitem-{{i}}" mat-list-item [routerLinkActive]="'is-active'"
            (click)="addExpandClass(data, i)" *ngIf="data.path===''">
            <mat-grid-list cols="1" rowHeight="50px">
              <mat-grid-tile>
                <div class="left-align-forced">
                  <img src={{data.Icon}} alt={{data.title}}> &nbsp;
                  <!-- <img [src]="isLevel1Shown('idx'+i) ? 'assets/images/arrowup.png' : 'assets/images/downarrow.svg'"
                    style="margin-left: 30px;"> -->

                </div>
              </mat-grid-tile>
            </mat-grid-list>
          </div>


          <ul class="nested submmenu" id="menuitem-{{i}}-child" *ngFor="let sub of data.SubMenu"
            [class.expand]="showMenu === data.title">
            <li>
              <div class="menutext" [routerLink]="sub.path"  [queryParams]="{ menuTitle: data.title }" [routerLinkActive]="'is-active'" #rla="routerLinkActive"
                (click)="menuItem_btn(sub)">
                <mat-grid-list cols="1" rowHeight="50px">
                  <mat-grid-tile>
                    <div class="left-align-forced">
                      <img src="{{rla.isActive ? data.Icon1 : data.Icon}}"/>
                      <!-- <img src={{sub.Icon}} style="margin-left: 10px;"> &nbsp;&nbsp; -->

                    </div>
                  </mat-grid-tile>
                </mat-grid-list>
              </div>


            </li>
          </ul>

        </div>
      </mat-nav-list>
    </div>


    <div class="fixed-bottom footersidemenudiv" id="footersidemenudivid">
      <div class="row" *ngFor="let sub of footerlist">
        <div class="col-6" style="text-align: left;" [routerLink]="sub.path" [queryParams]="{ menuTitle: sub.title }" [routerLinkActive]="'is-activefooter'" #rla="routerLinkActive" 
        *ngIf="(SETTING_MENU!=false) && (cacheddata==='System Administrator'|| cacheddata==='Primary Administrator'|| cacheddata==='Company Administrator')">
          <br>
          <div>
          <div class="settingbtn">
            <!-- <img src="assets/images/settings_white_24dp.svg" alt="settingbtn" class="settingicons"> -->
            <img src="{{rla.isActive ? sub.Icon1 : sub.Icon}}" alt={{sub.title}} class="settingicons">
          </div>
          </div>
        </div>

        <div class="col-6"  *ngIf="(SETTING_MENU!=false) && (cacheddata!='System Administrator'&& cacheddata!='Primary Administrator'&& cacheddata!='Company Administrator')">
          <div >
           
         </div>
       </div>
     
         <div class="col-6"  *ngIf="(SETTING_MENU===false) && (cacheddata!='System Administrator'&& cacheddata!='Primary Administrator'&& cacheddata!='Company Administrator')">
          <div >
          
         </div>
       </div>
     
          <div class="col-6" *ngIf="(SETTING_MENU===false) && (cacheddata==='System Administrator'|| cacheddata==='Primary Administrator'|| cacheddata==='Company Administrator')">
          <div >
             
           </div>
         </div>



        <div class="col-6" style="text-align: left;"><br>
          <div class="powerbtn" (click)="logout()">
            <img src="assets/images/power_settings_new_white_24dp.svg" alt="powerbtn" class="settingicons">
          </div>
        </div>


        <div class="col-12" style="text-align: center;margin-top: 10%">
          <img src="https://uat.stellar-oqcs.com/assets/images/logo.png" alt="MEA footer logo" style="height: 30px;">
        </div>

        <div class="col-12" style="text-align: center;margin-top: 2%">
          <div class="version">Version 21.2.4.3</div>
        </div>

      </div>
    </div>



  </div>

</div>
</div>




<!-- side menu small screen --- Mobile and tab devices -->

<!-- side menu small screen --- header -->
<mat-toolbar class="fix-nav" *ngIf="screenwidth<1270">

  <div>
    <button mat-icon-button (click)="sidenavtoggle()">
      <mat-icon alt="togglebutton">menu</mat-icon>
    </button>
  </div>


  <div class="col-10 breadcrumpcol">
    <div class="Headertextrole" id="headerrole">
      <!-- {{headerrole}} -->
    </div>

    <div class="Headertextsubrole" id="header_breadcrumb">
      <!-- {{headerrole}} -->

    </div>

    <div class="tooltiptext" id="tooltiptextId"></div>

  </div>

  <div class="col-xs-12" style="width: -webkit-fill-available; display: none;" >
    <mat-form-field appearance="outline" class="matformfieldheader header-field" *ngIf="role==='System Administrator'">
      <!-- <mat-label>Favorite food</mat-label> -->
      <mat-select [(ngModel)]="modeSelect" placeholder="Companies" class="selectvalueclass" panelClass="myPanelmatselectClass" (selectionChange)=selectcompanylist(modeSelect) disableOptionCentering>
        <mat-option [value]="" class="none_style">  None </mat-option>
        <mat-option *ngFor="let item of companylistdata" [value]="item.companyId" >
          <div class="matoptiontxt">{{item.companyName}}</div>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="col-4 searchcol"  style="display:none;">
    <mat-form-field appearance="outline" class="matformfieldheader header-field">
      <input type="text" #searchinput matInput [(ngModel)]="filterValue" class="textsearchinput" placeholder="Search..." autocomplete="off">
      <mat-icon matSuffix (click)="search(searchinput.value)" class="searchbtn" style="top: 0px!important;">search</mat-icon>
    </mat-form-field>

  </div>

</mat-toolbar>



<div id="mySidenav" class="sidenavmobile" *ngIf="screenwidth<1270">
  <a href="javascript:void(0)" class="closebtn" (click)="closeNav()">&times;</a>


  <!-- side menu small screen --- body -->

  <div class="row sidebarlogorowmobile">
    <div class="col-12">
      <div class="menutext">
        <!-- <img src="assets/images/logo.png" alt="MEA logo" style="margin-left: 30px;margin-top: 5%;" /> -->
        <img  *ngIf="companylogo=='https://uat.stellar-oqcs.com/assets/images/mea-logo.svg'" src="{{companylogo}}" alt="MEA logo"  style="width: 180px; height: 48px;margin-left: 30px;margin-top: 5%;"/>
        <img  *ngIf="companylogo!='https://uat.stellar-oqcs.com/assets/images/mea-logo.svg'" src="{{companylogo}}" alt="MEA logo"  style="height: 48px;margin-left: 30px;margin-top: 5%;"/>
      </div>
    </div>

  </div>



  <div class="col-12" style="color:white;background-color: #143f5f;margin-top:8%">
    <div class="row">
      <div class="col-4">
        <img src={{logo}} class="sidemenuiconprofile">
      </div>

      <div class="col-6" style="margin-top:5%">
        <span class="candidatename">{{name}}</span> <br>
        <span class="candidateprofile">{{role}}</span>
      </div>
      <div class="col-2" style="margin-top:7%;cursor: pointer;">
        <img src="assets/images/downarrow.svg" alt="downarrow" [matMenuTriggerFor]="menu" />

        <mat-menu #menu="matMenu" class="matmenusidemenu">
          <button mat-menu-item *ngFor="let item of rolearray" (click)="matMenubtn(item)"
            [ngClass]="{ 'selected': item == role }">
              <span class="icontext"> {{item}}</span>
            </button>
        </mat-menu>
      </div>
    </div>

  </div>

  <div class="scrollabledivmobile">
    <mat-nav-list>
      <div class="nested-menu " *ngFor="let data of appPages;let i=index;">
        <br>
        <div class="menutext" mat-list-item [routerLinkActive]="'is-active'" #rla="routerLinkActive"
          (click)="addExpandClass(data, i)" [routerLink]="data.path" [queryParams]="{ menuTitle: data.title }" *ngIf="data.path !=''" (click)="closeNav()">
          <mat-grid-list cols="1" rowHeight="50px">
            <mat-grid-tile>
              <div class="left-align-forced">
                <img src="{{rla.isActive ? data.Icon1 : data.Icon}}" alt={{data.title}} class="sidemenuicon"  />
                <!-- <img src={{data.Icon}} style="margin-left: 30px;"> &nbsp;&nbsp; -->
                &nbsp;&nbsp;
                <span class="sidemenutxtocol">{{data.sideMenutitle}}</span>


              </div>
            </mat-grid-tile>
          </mat-grid-list>
        </div>

        <div class="menutext" mat-list-item [routerLinkActive]="'is-active'" (click)="addExpandClass(data, i)"
          *ngIf="data.path===''">
          <mat-grid-list cols="1" rowHeight="50px">
            <mat-grid-tile>
              <div class="left-align-forced">
                <img src={{data.Icon}} alt={{data.title}} style="margin-left: 30px;"> &nbsp;&nbsp;
                <span class="sidemenutxtocol">{{data.title}}</span>
                <img [src]="isLevel1Shown('idx'+i) ? 'assets/images/arrowup.png' : 'assets/images/downarrow.svg'"
                  style="margin-left: 30px;">
                <!-- <img [src]="isLevel1Shown('idx'+i) ? 'assets/img/Header dropdown.png' : 'assets/img/sidearrow.png'"
                style="margin-left: 30px;"> -->
              </div>
            </mat-grid-tile>
          </mat-grid-list>
        </div>

        <ul class="nested submmenumobile expand" *ngFor="let sub of data.SubMenu"
          [class.expand]="showMenu === data.title">
          <li>
            <div class="menutext" [routerLink]="sub.path" [queryParams]="{ menuTitle: data.title }" [routerLinkActive]="'is-active'" #rla="routerLinkActive"
              (click)="closeNav()">
              <mat-grid-list cols="1" rowHeight="50px">
                <mat-grid-tile>
                  <div class="left-align-forced">
                    <!-- <img src={{sub.Icon}} style="margin-left: 10px;"> &nbsp;&nbsp; -->
                    <img src="{{rla.isActive ? data.Icon1 : data.Icon}}" style="margin-left: 10px;" />&nbsp;&nbsp;
                    <span class="sidesubmenutxtocol">{{sub.title}}</span>
                  </div>
                </mat-grid-tile>
              </mat-grid-list>
            </div>

          </li>
        </ul>
      </div>
    </mat-nav-list>
  </div>


  <!-- sidebar footer content -->

  <div class="fixed-bottom footersidemenudiv" id="footersidemenudivmobileid">
    <div class="row" *ngFor="let sub of footerlist">
      <div class="col-4"  [routerLink]="sub.path" [queryParams]="{ menuTitle: sub.title }" [routerLinkActive]="'is-activefooter'" #rla="routerLinkActive" 
      *ngIf="(SETTING_MENU!=false) && (cacheddata==='System Administrator'|| cacheddata==='Primary Administrator'|| cacheddata==='Company Administrator')">
        <div class="settingbtn">
          <!-- <mat-icon>home</mat-icon> -->
          <img src="{{rla.isActive ? sub.Icon1 : sub.Icon}}" alt={{sub.title}} class="settingicons"  title="Setting">
        </div>
      </div>

      <div class="col-4"  *ngIf="(SETTING_MENU!=false) && (cacheddata!='System Administrator'&& cacheddata!='Primary Administrator'&& cacheddata!='Company Administrator')">
        <div >
         
       </div>
     </div>
   
       <div class="col-4"  *ngIf="(SETTING_MENU===false) && (cacheddata!='System Administrator'&& cacheddata!='Primary Administrator'&& cacheddata!='Company Administrator')">
        <div >
        
       </div>
     </div>
   
        <div class="col-4" *ngIf="(SETTING_MENU===false) && (cacheddata==='System Administrator'|| cacheddata==='Primary Administrator'|| cacheddata==='Company Administrator')">
        <div >
           
         </div>
       </div>

      <div class="col-4" style="text-align: center;">
        <img src="https://uat.stellar-oqcs.com/assets/images/logo.png" alt="MEA logo" style="height: 40px;margin-left: -20px;width: auto;">
      </div>

      <div class="col-4" style="text-align: right;">
        <!-- <span style="color:white">cddffg</span> -->
        <div class="powerbtn" (click)="logout()">
          <img src="assets/images/power_settings_new_white_24dp.svg" alt="powerbtn" class="settingicons">
        </div>
      </div>

      <div class="col-12 MEALearningpower" style="text-align: center;">
        <!-- <img src="assets/images/MEAlogoname.svg"> -->
      </div>

      <div class="col-12" style="text-align: center;">
        <div class="copyright">2021. MEA Energy Association</div>
      </div>

      <div class="col-12" style="text-align: center;">
        <div class="version">Version 21.2.4.3</div>
      </div>

    </div>

  </div>
</div>

