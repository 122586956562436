import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DialogpopupComponent } from 'src/app/Popups/dialog-popup/dialog-popup.component';


@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(public dialog: MatDialog, private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token: string = localStorage.getItem('jwtToken');
    const loginId: string = localStorage.getItem('loginId');

    if (this.router.url === "/login" || this.router.url === "/ForgotPassword") {
      let loginId = localStorage.getItem('loginId');
      let announcementlistlogin = 'https://u8yieraaxg.execute-api.us-east-1.amazonaws.com/dev/announcement/announcementlistlogin/' + loginId;
      let announcementlistlogin1 = 'https://bwtyozhar5.execute-api.us-east-1.amazonaws.com/uat//announcement/announcementlistlogin/' + loginId;
      let announcementlistlogin2 = 'https://i98bbrpmfd.execute-api.us-east-1.amazonaws.com/test//announcement/announcementlistlogin/' + loginId;
      let announcementlistlogin3 = 'https://tvasax3kf0.execute-api.us-east-1.amazonaws.com/uat//announcement/announcementlistlogin/' + loginId;
      let announcementlistlogin4 = 'https://demo1.stellar-oqcs.com/announcement/announcementlistlogin/' + loginId;

      let getcompanyid = 'https://xw8ollkuag.execute-api.us-east-1.amazonaws.com/dev/course/getcompanyid/' + loginId;
      let getcompanyid1 = 'https://k0z2f9d8a3.execute-api.us-east-1.amazonaws.com/uat/course/getcompanyid/' + loginId;
      let getcompanyid2 = 'https://ul0r0yi3ja.execute-api.us-east-1.amazonaws.com/test/course/getcompanyid/' + loginId;
      let getcompanyid3 = 'https://u48i0yzbi1.execute-api.us-east-1.amazonaws.com/uat/course/getcompanyid/' + loginId;
      let getcompanyid4 = 'https://demo1.stellar-oqcs.com/course/getcompanyid/' + loginId;
      // console.log(request.url)
      // console.log(announcementlistlogin3)
      if (request.url === announcementlistlogin || request.url === announcementlistlogin1 || request.url === announcementlistlogin2 || request.url === announcementlistlogin3 || request.url === announcementlistlogin4 || request.url === getcompanyid
        || request.url === getcompanyid1 || request.url === getcompanyid2 || request.url === getcompanyid3 || request.url === getcompanyid4) {
        // console.log("bhhbhgbvghvgvgfvgfv")

        if (token) {
          request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
          request = request.clone({ headers: request.headers.set('loginId', loginId) });
        }
      }

    }
    else {

      if (token) {
        request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
        request = request.clone({ headers: request.headers.set('loginId', loginId) });
      }
    }


    let selectedUserLogin = localStorage.getItem("selectedUserLogin");
    let DEV_trainingURL = 'https://4b7djluwok.execute-api.us-east-1.amazonaws.com/dev/training/' + selectedUserLogin;
    let UAT_trainingURL = 'https://4v4ll9bftl.execute-api.us-east-1.amazonaws.com/uat/training/' + selectedUserLogin;
    let new_UAT_trainingURL = 'https://rnp1i5fcna.execute-api.us-east-1.amazonaws.com/uat/training/' + selectedUserLogin;
    let TEST_trainingURL = 'https://vaeqlqrvze.execute-api.us-east-1.amazonaws.com/test/training/' + selectedUserLogin;
    let DEV_UpdateTrainingURL = 'https://4b7djluwok.execute-api.us-east-1.amazonaws.com/dev/training/update/' + selectedUserLogin;
    let UAT_UpdateTrainingURL = 'https://4v4ll9bftl.execute-api.us-east-1.amazonaws.com/uat/training/update/' + selectedUserLogin;
    let new_UAT_UpdateTrainingURL = 'https://rnp1i5fcna.execute-api.us-east-1.amazonaws.com/uat/training/update/' + selectedUserLogin;
    let TEST_UpdateTrainingURL = 'https://vaeqlqrvze.execute-api.us-east-1.amazonaws.com/test/training/update/' + selectedUserLogin;
    let DEV_customizebadge = 'https://m5huyceqb5.execute-api.us-east-1.amazonaws.com/dev/companies/addTemplates/' + loginId;
    let UAT_customizebadge = 'https://cs3smfw232.execute-api.us-east-1.amazonaws.com/uat/companies/addTemplates/' + loginId;
    let new_UAT_customizebadge = 'https://kcbq8syvi1.execute-api.us-east-1.amazonaws.com/uat/companies/addTemplates/' + loginId;
    let TEST_customizebadge = 'https://buzvtgvhq2.execute-api.us-east-1.amazonaws.com/test/companies/addTemplates/' + loginId;

    let importcsvdev = "https://ovd0n1sfgf.execute-api.us-east-1.amazonaws.com/dev/users/importcsvfile/" + loginId
    let importcsvtest = "https://7y5we7dgs5.execute-api.us-east-1.amazonaws.com/test/users/importcsvfile/" + loginId
    let importcsvUAT = "https://n5jinrlfa4.execute-api.us-east-1.amazonaws.com/uat/users/importcsvfile/" + loginId
    let importcsvDemo = "https://demo1.stellar-oqcs.com/users/importcsvfile/" + loginId

    let importtrainingcsvdev = "https://4b7djluwok.execute-api.us-east-1.amazonaws.com/dev/training/importcsvfile/" + loginId
    let importtrainingtaskcsvtest = "https://vaeqlqrvze.execute-api.us-east-1.amazonaws.com/test/training/importcsvfile/" + loginId
    let importtrainingtaskcsvUAT = "https://rnp1i5fcna.execute-api.us-east-1.amazonaws.com/uat/training/importcsvfile/" + loginId
    let importtrainingtaskcsvDemo = "https://demo1.stellar-oqcs.com/training/importcsvfile/" + loginId

    let importformscsvdev = "https://qv50ljzka0.execute-api.us-east-1.amazonaws.com/dev/pef/importcsvfile/" + loginId
    let importformscsvtest = "https://3lwajia9rk.execute-api.us-east-1.amazonaws.com/test/pef/importcsvfile/" + loginId
    let importformscsvUAT = "https://c843slq7vc.execute-api.us-east-1.amazonaws.com/uat/pef/importcsvfile/" + loginId
    let importformscsvDemo = "https://demo1.stellar-oqcs.com/pef/importcsvfile/" + loginId

    let importtaskcsvdev = "https://8brrdwq4ff.execute-api.us-east-1.amazonaws.com/dev/task/importcsvfile/" + loginId
    let importtaskcsvtest = "https://zlemmi77s2.execute-api.us-east-1.amazonaws.com/test/task/importcsvfile/" + loginId
    let importtaskcsvUAT = "https://sm0nglpb9e.execute-api.us-east-1.amazonaws.com/uat/task/importcsvfile/" + loginId
    let importtaskcsvDemo = "https://demo1.stellar-oqcs.com/task/importcsvfile/" + loginId

    //Check with both dev, uat and prod url // to avaoid api 400 error with maultipart/form-data
    //Check with both dev, uat and prod url // to avaoid api 400 error with maultipart/form-data
    if (request.url === "https://6w7q2fvvs8.execute-api.us-east-1.amazonaws.com/dev/import/"
      || request.url == 'https://pxm4n7ryk4.execute-api.us-east-1.amazonaws.com/uat/content/upload'
      || request.url === "https://p00amw9f0l.execute-api.us-east-1.amazonaws.com/dev/content/upload"
      || request.url === "https://neqk7avw47.execute-api.us-east-1.amazonaws.com/uat/content/upload"
      || request.url === "https://yh1rupl1q1.execute-api.us-east-1.amazonaws.com/test/content/upload"
      || request.url === DEV_trainingURL || request.url == UAT_trainingURL || request.url == TEST_trainingURL
      || request.url == DEV_UpdateTrainingURL || request.url == UAT_UpdateTrainingURL || request.url == TEST_UpdateTrainingURL
      || request.url == new_UAT_trainingURL || request.url == new_UAT_UpdateTrainingURL || request.url == new_UAT_customizebadge
      || request.url === DEV_customizebadge || request.url === UAT_customizebadge || request.url === TEST_customizebadge
      || request.url === "https://xw8ollkuag.execute-api.us-east-1.amazonaws.com/dev/course"
      || request.url == "https://k0z2f9d8a3.execute-api.us-east-1.amazonaws.com/uat/course"
      || request.url == 'https://u48i0yzbi1.execute-api.us-east-1.amazonaws.com/uat/course'
      || request.url == "https://ul0r0yi3ja.execute-api.us-east-1.amazonaws.com/test/course"
      || request.url === "https://xw8ollkuag.execute-api.us-east-1.amazonaws.com/dev/course/update"
      || request.url == 'https://u48i0yzbi1.execute-api.us-east-1.amazonaws.com/uat/course/update'
      || request.url === "https://cbbr8voemi.execute-api.us-east-1.amazonaws.com/dev/curriculum/update"
      || request.url === "https://k0z2f9d8a3.execute-api.us-east-1.amazonaws.com/uat/course/update"
      || request.url === "https://ul0r0yi3ja.execute-api.us-east-1.amazonaws.com/test/course/update"
      || request.url == "https://xw8ollkuag.execute-api.us-east-1.amazonaws.com/dev/course/uploadFile"
      || request.url === "https://cbbr8voemi.execute-api.us-east-1.amazonaws.com/dev/curriculum"
      || request.url == 'https://ekl9kytp9k.execute-api.us-east-1.amazonaws.com/uat/curriculum'
      || request.url == 'https://ekl9kytp9k.execute-api.us-east-1.amazonaws.com/uat/curriculum/update'
      || request.url === "https://ing5o7jo2i.execute-api.us-east-1.amazonaws.com/uat/curriculum/update"
      || request.url == "https://k0z2f9d8a3.execute-api.us-east-1.amazonaws.com/uat/course/uploadFile"
      || request.url == 'https://u48i0yzbi1.execute-api.us-east-1.amazonaws.com/uat/course/uploadFile'
      || request.url === "https://ing5o7jo2i.execute-api.us-east-1.amazonaws.com/uat/curriculum"
      || request.url == "https://ul0r0yi3ja.execute-api.us-east-1.amazonaws.com/test/course/uploadFile"
      || request.url == "https://7ixtonpioh.execute-api.us-east-1.amazonaws.com/test/curriculum/update"
      || request.url === "https://7ixtonpioh.execute-api.us-east-1.amazonaws.com/test/curriculum"
      || request.url == 'http://44.201.216.252:9091/course/peek'
      || request.url == 'http://44.201.216.252:9091/course/import/'
      || request.url == 'http://54.167.233.223:9091/course/peek'
      || request.url == 'http://54.167.233.223:9091/course/import/'
      || request.url == 'http://54.210.176.49:9091/course/import/'
      || request.url == 'http://54.210.176.49:9091/course/peek'
      || request.url == 'https://import.uat.stellar-oqcs.com/course/import/'
      || request.url == 'https://import.uat.stellar-oqcs.com/course/peek'
      || request.url == 'https://import.dev.stellar-oqcs.com/course/import/'
      || request.url == 'https://import.dev.stellar-oqcs.com/course/peek'
      || request.url == 'https://import.test.stellar-oqcs.com/course/import/'
      || request.url == 'https://import.test.stellar-oqcs.com/course/peek'
      || request.url == 'https://m5huyceqb5.execute-api.us-east-1.amazonaws.com/dev/companies/uploadlogo'
      || request.url == 'https://buzvtgvhq2.execute-api.us-east-1.amazonaws.com/test/companies/uploadlogo'
      || request.url == 'https://kcbq8syvi1.execute-api.us-east-1.amazonaws.com/uat/companies/uploadlogo'
      || request.url === importcsvdev || request.url === importcsvtest || request.url === importcsvUAT || request.url === importcsvDemo
      || request.url === importtaskcsvdev || request.url === importtaskcsvtest || request.url === importtaskcsvUAT || request.url === importtrainingtaskcsvDemo
      || request.url === importtrainingcsvdev || request.url === importtrainingtaskcsvtest || request.url === importtrainingtaskcsvUAT || request.url === importformscsvDemo
      || request.url === importformscsvdev || request.url === importformscsvtest || request.url === importformscsvUAT || request.url === importtaskcsvDemo
      || request.url == 'https://ua68cqu6mg.execute-api.us-east-1.amazonaws.com/dev/evaluator/evaluationsubmit/' + loginId
      || request.url == 'https://xxrmcaaj31.execute-api.us-east-1.amazonaws.com/test/evaluator/evaluationsubmit/' + loginId
      || request.url == 'https://bm1b49l2t4.execute-api.us-east-1.amazonaws.com/uat/evaluator/evaluationsubmit/' + loginId
      || request.url === "https://import.dev.stellar-oqcs.com/course/import/revisioncontent"
      || request.url === "https://import.test.stellar-oqcs.com/course/import/revisioncontent"
      || request.url === "https://import.uat.stellar-oqcs.com/course/import/revisioncontent") {


    }
    else {
      request = request.clone({
        headers: request.headers.set('Content-Type', 'application/json')
      });
      request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

    }


    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          //console.log('event--->>>', event);
        }
        return event;
      }),

      catchError((error: HttpErrorResponse) => {
        let data = {};
        // console.log( error)
        //this.errorDialogService.openDialog(data);
        if (this.router.url === "/login" || this.router.url === "/ForgotPassword") {

        }
        else {
          // console.log(error);
          if (error.status != 200) {
            if (error.status === 403) {
              data = {
                status: "Not authorized to access the requested content"
              };
            }
            else if (error.status === 502) {
              data = {
                status: error.error.message
              };
            }
            else if (error.status === 500) {
              data = {
                status: "Internal Server Error"
              };
            }
            else if (error.status === 504) {
              data = {
                status: "Gateway Timeout Error"
              };
            }
            else {
              data = {
                status: "Something went wrong. Please try after some time."
              };
            }


            this.dialog.open(DialogpopupComponent, {
              width: '550px',
              autoFocus: false,
              // height: '350px',
              data: {
                from: 'error-popup',
                name: data
              },
              disableClose: true,
              position: {
                top: '40vh'
                // left: '50%'
              }
            });
          }
        }

        return throwError(error);
      })
    );
  }
}


