import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UserComponent } from 'src/app/Administrator/user/user.component';
import { ApiServiceService } from 'src/app/Services/api-service.service';

@Component({
  selector: 'app-grouplist-filter',
  templateUrl: './grouplist-filter.component.html',
  styleUrls: ['./grouplist-filter.component.scss']
})
export class GrouplistFilterComponent implements OnInit {
  filterform: FormGroup;
  companylistdata: any = [""];
  statuslist: any = [""];
  loadspinner: any = false;
  groupapibodycontent: any;
  disablebutton:any;
  isDisabled: boolean;

  constructor(private formBuilder: FormBuilder, private apiService: ApiServiceService, public dialog: MatDialogRef<UserComponent>) {
    this.filterform = this.formBuilder.group({
      GroupName: [''],
      GroupID: [''],
      CompanyId: [''],
      Status: [''],
    });
    this.apicallgetdefaultdropdown();
  }

  ngOnInit(): void {
  }

  setdefaultdropdownvalues() {
    let cacheddata = localStorage.getItem('groupfilterdata');
    if (cacheddata === '' || cacheddata === undefined || cacheddata === null) {
      this.disablebutton=true;
    }
    // if cached data present -- once filter btn was clicked
    else {
      this.disablebutton=false;
      let data = JSON.parse(cacheddata);
      this.filterform.controls['GroupName'].setValue(data["Group Name"]);
      this.filterform.controls['GroupID'].setValue(data["Group ID"]);
      this.filterform.controls['CompanyId'].setValue(data["Company (COID)"]);
      this.filterform.controls['Status'].setValue(data["Status"]);
      this.clearfilterfunction();
    }
    let compId = localStorage.getItem('procCompId');
    let compName = localStorage.getItem('proccompanyName');
    let rolecacheddata = localStorage.getItem('role');
    if (rolecacheddata != "System Administrator") {
      let data = compName + ' ' + '(' + compId + ')'
      this.filterform.controls['CompanyId'].setValue(data);
      this.isDisabled=true;
      this.disablebutton=false;
    }
    else{
      let globalcompanyIDselected = localStorage.getItem('globalcompanyIDselected');
      let globalcompanynameselected = localStorage.getItem('globalcompanynameselected');
        if(globalcompanyIDselected=='' || globalcompanyIDselected==undefined || globalcompanyIDselected==null){
          this.isDisabled=false;
        }
        else{
          let data = globalcompanynameselected + ' ' + '(' + globalcompanyIDselected + ')'
          this.filterform.controls['CompanyId'].setValue(data);
          this.isDisabled=true;
          this.disablebutton=false;
        }
    }
  }

  clearfilterfunction() {
    // if filterchip to remove filter is selcted remove the data on modal
    let clearfilteritemkey = JSON.parse(localStorage.getItem('group_clearfilteritemkey'));
    if (clearfilteritemkey === "" || clearfilteritemkey === undefined || clearfilteritemkey === null) {
    }
    else {
      for (let i = 0; i < clearfilteritemkey.length; i++) {
        if(clearfilteritemkey[i]==="Company (COID)"){
          this.filterform.controls['CompanyId'].setValue("");
        }
        else if(clearfilteritemkey[i]==="Group ID"){
          this.filterform.controls['GroupID'].setValue("");
        }
        else if(clearfilteritemkey[i]==="Group Name"){
          this.filterform.controls['GroupName'].setValue("");
        }
        else{
          this.filterform.controls[clearfilteritemkey[i]].setValue("");
        }

      }
    }
  }

  apicallgetdefaultdropdown() {
    // get default value on load companylist -- api call
    this.loadspinner = true;
    const endpoint1 = 'lookup';
    this.apiService.Simpleget(endpoint1, 'GET')
      .subscribe((response) => {
        this.loadspinner = false;
        this.companylistdata = response["companies"];
        this.statuslist = [
          { "status_id": 1, "statusName": "Active" },
          { "status_id": 2, "statusName": "Inactive" }
        ]
        this.setdefaultdropdownvalues();
      },
        (err) => {
          this.loadspinner = false;
         // console.log(err)

        });
  }


  // on keyup enable filter button
  inputchangegroupname(value){
   this.dynamicEnableDisablebtn()
  }

  inputchangegroupID(value){
  this.dynamicEnableDisablebtn()
  }

  selectCompanyID(value) {
    if(value==="" || value === undefined || value===null){
      // if none select set value as ''
      this.filterform.controls['CompanyId'].setValue("");
      this.dynamicEnableDisablebtn()
    }
    else{
      this.disablebutton=false;
    }
  }

  selectStatusName(value){
    if(value==="" || value === undefined || value===null){
      // if none select set value as ''
      this.filterform.controls['Status'].setValue("");
      this.dynamicEnableDisablebtn()
    }
    else{
      this.disablebutton=false;
    }
  }

  cancelbtn() {
    // if filterdata not present --- on first load cancel - clear data
    let data = JSON.parse(localStorage.getItem('group_clearfilteritemkey'));
    if (data === "" || data === undefined || data === null) {
    }
    else {
      let groupfilterdata = this.getbodycontent();
      localStorage.setItem('groupfilterdata', JSON.stringify(groupfilterdata));
      localStorage.removeItem('group_clearfilteritemkey');
    }
    this.dialog.close({ method: 'close' })
  }

  EnterSubmit(event){
    if(event.keyCode === 13){
     //this.Filter();
     this.dynamicEnableDisablebtn();
     if(this.disablebutton===true){
      event.preventDefault();
     }
     else{
      event.preventDefault();
      this.Filter();
     }

    }
  }


  Filter() {
    if (this.filterform.valid) {
      localStorage.removeItem('groupfilterdata');
      localStorage.removeItem('group_chipdisplayfilterdata');
      localStorage.removeItem('group_clearfilteritemkey');
      let groupfilterdata = this.getbodycontent();
      localStorage.setItem('groupfilterdata', JSON.stringify(groupfilterdata));
      this.dialog.close({ data: groupfilterdata, groupapibodycontent: this.groupapibodycontent, method: 'filter' });
    }

  }

  getbodycontent() {
    let CompanyID="";
    let rolecacheddata = localStorage.getItem('role');
    if (rolecacheddata != "System Administrator") {
      CompanyID="";
    }
    else{
     let globalcompanyIDselected = localStorage.getItem('globalcompanyIDselected');
      if(globalcompanyIDselected=='' || globalcompanyIDselected==undefined || globalcompanyIDselected==null){
        CompanyID=this.filterform.value.CompanyId
      }
      else{
        CompanyID="";
      }
    }
    let groupfilterdata = {
      "Group Name": this.filterform.value.GroupName.trim(),
      "Group ID": this.filterform.value.GroupID.trim(),
      "Company (COID)": CompanyID,
      "Status": this.filterform.value.Status.trim(),
    }
    this.getapibodycontent();
    return groupfilterdata

  }

  getapibodycontent() {
    let companylistdataid= "";
    for (let i = 0; i < this.companylistdata.length; i++) {
      if (this.companylistdata[i].companyName + ' ' + '('+ this.companylistdata[i].companyId + ')' === this.filterform.value.CompanyId) {
        companylistdataid = this.companylistdata[i].companyId;
      }
    }
    this.groupapibodycontent = {
      "groupName": this.filterform.value.GroupName.trim(),
      "groupId": this.filterform.value.GroupID.trim(),
      "companyId": companylistdataid,
      "groupStatus": this.filterform.value.Status.trim(),
    }
  }

  checkformvalue(){
    let formempty;
    if(this.filterform.value.GroupName.trim()==="" && this.filterform.value.GroupID.trim()==="" &&
    this.filterform.value.CompanyId.trim()==="" && this.filterform.value.Status.trim()==="" ){
      formempty=true;
    }
    else {
      formempty = false;
    }
    return formempty;
  }

  dynamicEnableDisablebtn(){
    let checkformfield= this.checkformvalue();
    if(checkformfield===true){
      this.disablebutton=true;
    }
    else{
      this.disablebutton=false;
    }
  }
}
