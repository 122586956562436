
<div class="spinner_overlay" *ngIf="loadspinner===true">
</div>
<mat-spinner class="spinner" *ngIf="loadspinner===true"></mat-spinner>

<div class="import-course" >
  
   <div class="row headerrow"> 
    <div class="col-10">
      <img src="assets/images/import-file.svg" alt="filter">
      <span class="heading">&nbsp;&nbsp;Import Content</span>
    </div>

    <div class="col-2 cleariconcol">
      <mat-icon class="clearicon" alt="clear" (click)="cancelbtn()">clear</mat-icon>
    </div>
  </div>

 <div *ngIf="show_screen=='import'">
  <div class="row box-row">
<div class="content">Note : Selected course package must be zipped in order to be uploaded in the following supported formats.
 <br> SCORM 1.2 or 2004 content package, xAPI (Tin Can) content package</div>
  </div>
  <div class="row">
    <div class="import-file">
<span>Browse File &nbsp; &nbsp; : &nbsp; &nbsp; <button type="button" class="file-button" mat-raised-button (click)="fileInput.click()"> <img src="assets/images/file-icon.svg" alt="filter">Import File</button>
  <input hidden (change)="onFileSelect($event)" #fileInput type="file" id="file"><span *ngIf="fileName!=''" class="margin-file">{{fileName}}</span>
  </span>
    </div>
  </div>
  <div class="row button-cls">
    <div class="col-6 cancelbtncol"><br><br>
      <button mat-button id="btncancelid" class="cancelbtn" (click)="cancel_image()">
        <mat-icon class="Cancelicon" alt="clear">clear</mat-icon>
        Cancel
      </button>
    </div>
    <div class="col-6 Filterbtncol"><br><br>
      <button mat-button id="btnfiltertermid" class="Filterbtn" [disabled]="disablebutton" (click)="import(from)">
        <mat-icon class="filterbtnicon">done</mat-icon>
        Import
      </button>
    </div>
  </div>
  </div> 

 <div *ngIf="show_screen=='progress'">
  <div class="row">
    <div class="import-file-progress">
<span>Browse File &nbsp; &nbsp; : &nbsp; &nbsp; {{fileName}}
  </span>
    </div>

  </div>
  <div class="row progress-div">
    <div class="progress-subdiv">
    <mat-progress-bar *ngIf="!complete_progress" class="progress-bar" mode="indeterminate" ></mat-progress-bar>
    <mat-progress-bar *ngIf="complete_progress" class="progress-bar" mode="determinate" value="100" ></mat-progress-bar>
  </div>
  </div>
</div>

<div *ngIf="from=='importcontent'">
<div *ngIf="show_screen=='next'">
  <div class="row">
    <div class="import-file-progress">
<span>Course ID &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp; : &nbsp; &nbsp; 
  </span>
  <mat-form-field appearance="outline"  class="form-field2">
    <input matInput placeholder="Course ID" [(ngModel)]="courseid"  class="textinput" 
     maxlength="250">
  </mat-form-field>
  <div
    *ngIf="idError" class="errors">
    <img src="assets/images/error.svg" class="errormsgicon" alt="error icon">
    &nbsp;Please enter Course ID
  </div>
    </div>

  </div>
  <div class="row course-name">
    <div class="import-file-progress">
<span>Course Name &nbsp; &nbsp; : &nbsp; &nbsp; 
  </span>
  <mat-form-field appearance="outline"  class="form-field2">
    <input matInput placeholder="Course Name" [(ngModel)]="coursename"  class="textinput" 
     maxlength="250">
  </mat-form-field>
    </div>

  </div>
  <div class="row button-cls-next">
    <div class="col-6 cancelbtncol"><br><br>
      <button mat-button id="btncancelid" class="cancelbtn" (click)="cancel_image()">
        <mat-icon class="Cancelicon" alt="clear">clear</mat-icon>
        Cancel
      </button>
    </div>
    <div class="col-6 Filterbtncol"><br><br>
      <button mat-button id="btnfiltertermid" class="Filterbtn"  (click)="convert()">
        <mat-icon class="filterbtnicon">done</mat-icon>
        Next
      </button>
    </div>
  </div>
</div>
</div> 


<!-- importRevisedcontent -->

<form [formGroup]="filterform" *ngIf="from=='importRevisedcontent'">
<div>
  <div *ngIf="show_screen=='next'">
    <div class="row">
         <div class="col-12 leftaligncolumn">
          <div class="labeltext">
            Versioning Option
          </div>
         </div>
    
     
         <div class="col-3 leftaligncolumn">
          <mat-label class="labelname">Effective Date &nbsp;&nbsp; :</mat-label>
         </div>
         
         <div class="col-9" style="margin-top: 15px;">
          <mat-form-field floatLabel="always" appearance="none" class="formfieldusertable">
            <input matInput [matDatepicker]="picker" (dateChange)=changeCreationDate($event.value) (click)="picker.open()"
              [min]="today" style="cursor: pointer;" class="textinputusertablefilterdate" placeholder="mm/dd/yyyy"
              formControlName="CreationDate" readonly>
            <mat-datepicker-toggle matSuffix [for]="picker"> </mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="col-12 leftaligncolumn">
          <div class="labelname">
            This version will be effective for the users with the training status
          </div>
         </div>

         <div class="col-12 " style="margin-top:5px"> 
         
        <div class="wrapperdiv">
        <mat-checkbox class="checkboxheading" formControlName="NotStarted">
        </mat-checkbox>
        <span class="checkboxlabel"> &nbsp; &nbsp;Not Started
          &nbsp;&nbsp;&nbsp;
          <img class="warning-cls" src="assets/images/information.svg">&nbsp;&nbsp;
          <span class="labelnamewarning hide">User will receive old content until effective date</span>
        </span>
      </div>
 
      <div class="wrapperdiv">
        <mat-checkbox class="checkboxheading" formControlName="InProgress">
        </mat-checkbox>
        <span class="checkboxlabel1"> &nbsp; &nbsp;In Progress
          &nbsp;&nbsp;&nbsp;
          <img class="warning-cls" src="assets/images/information.svg">&nbsp;&nbsp;
            <span class="labelnamewarning hide">User will lose current progress and be required to start over</span>
        </span>
        </div>


        <div class="wrapperdiv">
        <mat-checkbox class="checkboxheading" formControlName="Completed">
        </mat-checkbox>
        <span class="checkboxlabel2"> &nbsp; &nbsp;Completed
          &nbsp;&nbsp;&nbsp;
          <img class="warning-cls" src="assets/images/information.svg">&nbsp;&nbsp;
          <span class="labelnamewarning hide">User will have access to new content in review mode</span>
        </span>
        </div>
      </div>

      <div class="col-9 leftaligncolumn">
          <mat-label class="labelname">Comments</mat-label>
          <br>
          <mat-form-field appearance="outline" id="formfieldusernameid" class="formfieldusertablecomments">
              <textarea matInput class="textarea" formControlName="Comments" style="margin-top: 15px;">
              </textarea>
          </mat-form-field>
      </div>

      <div class="col-3 leftaligncolumncommentradio">
        &nbsp;&nbsp;&nbsp;&nbsp;
        <img class="warning-cls" src="assets/images/information.svg">&nbsp;&nbsp;
        <span class="labelnamewarningcomment hide">Make sure to review your comments. Once published, comments cannot be modified.</span>
      </div>



    </div>

    <div class="row">
      <div class="col-6 cancelbtncol"><br><br>
        <button mat-button id="btncancelid" class="cancelbtn" (click)="cancelbtnimportRevisedcontent()">
          <mat-icon class="Cancelicon" alt="clear">clear</mat-icon>
          Cancel
        </button>
      </div>
      <div class="col-6 Filterbtncol"><br><br>
        <button mat-button id="btnfiltertermid" class="Filterbtn"  (click)="Publish()">
          <mat-icon class="filterbtnicon">done</mat-icon>
          Publish
        </button>
      </div>
    </div>

  </div>


  </div>

  </form>
  
</div>








