import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { filter } from 'rxjs/operators';
import { UserComponent } from 'src/app/Administrator/user/user.component';
import { ApiServiceService } from 'src/app/Services/api-service.service';

@Component({
  selector: 'app-formslistfilter',
  templateUrl: './formslistfilter.component.html',
  styleUrls: ['./formslistfilter.component.scss'],
  providers: [DatePipe]
})
export class FormslistfilterComponent implements OnInit {
  filterform: FormGroup;
  statuslist: any = [""];
  loadspinner: any = false;
  companylistdata: any = [""];
  apibodycontent: any;
  disablebutton: any;
  enddateconsistnull: any;
  today = new Date();
  isDisabled: boolean;
  constructor(private datePipe: DatePipe, private formBuilder: FormBuilder, private apiService: ApiServiceService, public dialog: MatDialogRef<UserComponent>) {
    console.log("kkk")
    this.filterform = this.formBuilder.group({
      FormName: [''],
      FormID: [''],
      Owner: [''],
      Status: [''],
      start: [''],
      end: [''],
      ISTEMPLATE: ['']
    });
    this.apicallgetdefaultdropdown();
  }
  apicallgetdefaultdropdown() {
    // get default value on load companylist -- api call
    this.loadspinner = true;
    const endpoint1 = 'lookup';
    this.apiService.Simpleget(endpoint1, 'GET')
      .subscribe((response) => {
        this.loadspinner = false;
        this.statuslist = [
          { "status_id": 1, "statusName": "Published" },
          { "status_id": 2, "statusName": "Draft" },
        ]
        this.companylistdata = response["companies"];
        this.setdefaultdropdownvalues();
      },
        (err) => {
          this.loadspinner = false;
          // console.log(err)

        });


    this.setdefaultdropdownvalues();

  }

  ngOnInit(): void {
  }

  setdefaultdropdownvalues() {
    let cacheddata = localStorage.getItem('groupfilterdata');
    if (cacheddata === '' || cacheddata === undefined || cacheddata === null) {
      this.disablebutton = true;
      // this.filterform.controls['ISTEMPLATE'].setValue(false);
    }
    // if cached data present -- once filter btn was clicked
    else {
      this.disablebutton = false;
      let data = JSON.parse(cacheddata);
      this.filterform.controls['FormName'].setValue(data["FormName"]);
      this.filterform.controls['FormID'].setValue(data["FormID"]);
      this.filterform.controls['Owner'].setValue(data["Owner"]);
      this.filterform.controls['Status'].setValue(data["Status"]);
      this.filterform.controls['ISTEMPLATE'].setValue(data["Template"]);

      // for date range
      let a = data["Publish Date Range"].split('-')[0];
      let b = data["Publish Date Range"].split('-')[1];

      if (data["Publish Date Range"] === null || data["Publish Date Range"] === undefined || data["Publish Date Range"] === "") {
        this.filterform.controls['start'].setValue("");
        this.filterform.controls['end'].setValue("");
      } else {
        this.filterform.controls['start'].setValue(new Date(a));
        this.filterform.controls['end'].setValue(new Date(b));
      }
      this.clearfilterfunction();
    }

    let compId = localStorage.getItem('procCompId');
    let compName = localStorage.getItem('proccompanyName');
    let rolecacheddata = localStorage.getItem('role');
    if (rolecacheddata != "System Administrator") {
      let data = compName + ' ' + '(' + compId + ')'
      this.filterform.controls['Owner'].setValue(data);
      this.isDisabled = true;
      this.disablebutton = false;
    }
    else {
      let globalcompanyIDselected = localStorage.getItem('globalcompanyIDselected');
	    let globalcompanynameselected = localStorage.getItem('globalcompanynameselected');
      if(globalcompanyIDselected=='' || globalcompanyIDselected==undefined || globalcompanyIDselected==null){
        this.isDisabled=false;
      }
      else{
        let data = globalcompanynameselected + ' ' + '(' + globalcompanyIDselected + ')'
        this.filterform.controls['Owner'].setValue(data);
        this.isDisabled=true;
        this.disablebutton=false;
      }
    }
  }


  clearfilterfunction() {
    // if filterchip to remove filter is selcted remove the data on modal
    let clearfilteritemkey = JSON.parse(localStorage.getItem('group_clearfilteritemkey'));
    if (clearfilteritemkey === "" || clearfilteritemkey === undefined || clearfilteritemkey === null) {
    }
    else {
      for (let i = 0; i < clearfilteritemkey.length; i++) {
        if (clearfilteritemkey[i] === "FormName") {
          this.filterform.controls['FormName'].setValue("");
        }
        else if (clearfilteritemkey[i] === "FormID") {
          this.filterform.controls['FormID'].setValue("");
        }
        else if (clearfilteritemkey[i] === "Owner") {
          this.filterform.controls['Owner'].setValue("");
        }
        else if (clearfilteritemkey[i] === "Status") {
          this.filterform.controls['Status'].setValue("");
        }
        else if (clearfilteritemkey[i] === "Template") {
          this.filterform.controls['ISTEMPLATE'].setValue(false);
        }
        else if (clearfilteritemkey[i] === "Publish Date Range") {
          this.filterform.controls['start'].setValue("");
          this.filterform.controls['end'].setValue("");
        }
        else {
          this.filterform.controls[clearfilteritemkey[i]].setValue("");
        }

      }
    }
  }

  // on keyup enable filter button
  selectowner(value) {
    if (value === "" || value === undefined || value === null) {
      // if none select set value as ''
      this.filterform.controls['Owner'].setValue("");
    }
    // else {
    //   this.disablebutton = false;
    // }
    this.dynamicEnableDisablebtn();
  }

  selectStatusName(value) {
    if (value === "" || value === undefined || value === null) {
      // if none select set value as ''
      this.filterform.controls['Status'].setValue("");
    }
    // else {
    //   this.disablebutton = false;
    // }
    this.dynamicEnableDisablebtn();
  }

  inputchangeID1(value) {
    this.dynamicEnableDisablebtn()

  }

  inputchangeID2(value) {
    this.dynamicEnableDisablebtn()

  }

  selectcheckbox(value) {
    let a = value;
    this.filterform.controls['ISTEMPLATE'].setValue(a);
    this.dynamicEnableDisablebtn()
  }

  startChange(event) {
    console.log("startChange", event.value)
    //console.log(this.filterform.value.end);

  }
  endChange(event) {
    console.log("endChange", event.value);
    if (event.value === null) {
      this.enddateconsistnull = true;
      this.disablebutton = true;
    }
    else {
      this.enddateconsistnull = false;
      this.dynamicEnableDisablebtn();

    }
    //
  }

  cancelbtn() {
    // if filterdata not present --- on first load cancel - clear data
    let data = JSON.parse(localStorage.getItem('group_clearfilteritemkey'));
    if (data === "" || data === undefined || data === null) {
    }
    else {
      let filterdata = this.getbodycontent();
      localStorage.setItem('groupfilterdata', JSON.stringify(filterdata));
      localStorage.removeItem('group_clearfilteritemkey');
    }
    this.dialog.close({ method: 'close' })
  }

  Filter() {
    if (this.filterform.valid) {
      localStorage.removeItem('groupfilterdata');
      localStorage.removeItem('group_chipdisplayfilterdata');
      localStorage.removeItem('group_clearfilteritemkey');
      let filterdata = this.getbodycontent();
      localStorage.setItem('groupfilterdata', JSON.stringify(filterdata));
      this.dialog.close({ data: filterdata, apibodycontent: this.apibodycontent, method: 'filter' });
    }

  }

  EnterSubmit(event) {
    if (event.keyCode === 13) {
      //this.Filter();
      this.dynamicEnableDisablebtn();
      if (this.disablebutton === true) {
        event.preventDefault();
      }
      else {
        event.preventDefault();
        this.Filter();
      }

    }
  }


  getbodycontent() {
    let daterange = "";
    let startdate = "";
    let enddate = "";
    let apidaterange = "";
    let apistartdate = "";
    let apienddate = "";
    if (this.filterform.value.start === "" || this.filterform.value.start === undefined || this.filterform.value.start === null) {
      daterange = "";
    }
    else {
      startdate = this.datePipe.transform(this.filterform.value.start, 'MM/dd/yyyy');
      enddate = this.datePipe.transform(this.filterform.value.end, 'MM/dd/yyyy');
      daterange = startdate + " - " + enddate;
      apistartdate = this.datePipe.transform(this.filterform.value.start, 'yyyy-MM-dd');
      apienddate = this.datePipe.transform(this.filterform.value.end, 'yyyy-MM-dd');
    }

    let CompanyID="";
    let sharedFlag='';
    let rolecacheddata = localStorage.getItem('role');
    if (rolecacheddata != "System Administrator") {
      CompanyID="";
      sharedFlag='';
    }
    else{
      let globalcompanyIDselected = localStorage.getItem('globalcompanyIDselected');
      if(globalcompanyIDselected=='' || globalcompanyIDselected==undefined || globalcompanyIDselected==null){
        CompanyID=this.filterform.value.Owner;
        sharedFlag="No";
      }
      else{
        CompanyID="";
        sharedFlag='';
      }
    }


    let filterdata = {
      "FormName": this.filterform.value.FormName.trim(),
      "FormID": this.filterform.value.FormID.trim(),
      "Publish Date Range": daterange,
      "Status": this.filterform.value.Status.trim(),
      "Owner": CompanyID,
      "Template": this.filterform.value.ISTEMPLATE,
     
    }
    this.getapibodycontent(apistartdate, apienddate);
    return filterdata

  }

  getapibodycontent(apistartdate, apienddate) {
    let companylistdataid = "";
    for (let i = 0; i < this.companylistdata.length; i++) {
      if (this.companylistdata[i].companyName + ' ' + '(' + this.companylistdata[i].companyId + ')' === this.filterform.value.Owner) {
        companylistdataid = this.companylistdata[i].companyId;
      }
    }
    let sharedFlag='';
    let rolecacheddata = localStorage.getItem('role');
    if (rolecacheddata == "System Administrator") {
      let globalcompanyIDselected = localStorage.getItem('globalcompanyIDselected');
      if(globalcompanyIDselected=='' || globalcompanyIDselected==undefined || globalcompanyIDselected==null){
        sharedFlag="No";
      }
      else{
        sharedFlag='';
      }
    }
    
    this.apibodycontent = {
      "title": this.filterform.value.FormName.trim(),
      "formId": this.filterform.value.FormID.trim(),
      "status": this.filterform.value.Status.trim(),
      "companyId": companylistdataid,
      'publishedStartDate': apistartdate,
      'publishedEndDate': apienddate,
      "template": this.filterform.value.ISTEMPLATE,
      "sharedFlag":sharedFlag
    }
  }





  checkformvalue() {
    let formempty;
    console.log(this.filterform.value.FormName)
    if ((this.filterform.value.FormName.trim() === "") && (this.filterform.value.FormID.trim() === "") && (this.filterform.value.Status.trim() === "") &&
      (this.filterform.value.ISTEMPLATE === false) && (this.filterform.value.Owner.trim() === "") && (this.filterform.value.start === "" || this.filterform.value.start === null) && (this.filterform.value.end === "" || this.filterform.value.end === null)) {
      formempty = true;
    }
    else {
      formempty = false;
      if (this.enddateconsistnull === true) {
        formempty = true;
      }
    }
    return formempty;
  }

  dynamicEnableDisablebtn() {
    let checkformfield = this.checkformvalue();
    if (checkformfield === true) {
      this.disablebutton = true;
    }
    else {
      this.disablebutton = false;
    }
  }
}
