<div class="spinner_overlay" *ngIf="loadspinner===true">
</div>
<mat-spinner class="spinner" *ngIf="loadspinner===true"></mat-spinner>

<div class="filtermaincontainer" (keydown)="EnterSubmit($event)">
    <div class="row headerrow">
        <div class="col-10">
          <img src="assets/images/filter-filled-tool-symbol.svg" alt="filter">
          <span class="heading">&nbsp;&nbsp;Filter</span>
        </div>
    
        <div class="col-2 cleariconcol">
          <mat-icon class="clearicon" alt="clear" (click)="cancelbtn()">clear</mat-icon>
        </div>
      </div>
      <hr style="width: 549px; margin-top: 10px; margin-left: -23px;">

      <form [formGroup]="filterform">
        <div class="row">
           
              <div class="col-12 col-md-12 inputcolumn">
                <mat-label id="usernameid" class="labelname">Name</mat-label>
                <mat-form-field appearance="outline" id="formfieldusernameid" class="formfieldusertable">
                  <input matInput placeholder="Enter here" autocomplete="off" #inpgroupname (keyup)="inputchangeID1(inpgroupname.value)" class="inputClassText"
                    formControlName="Name">
                </mat-form-field>
              </div>

              <div class="col-6 col-md-6 inputcolumn">
                <mat-label id="usernameid" class="labelname">Owner</mat-label>
                <mat-form-field appearance="outline" class="formfieldusertable">
                  <mat-select formControlName="Owner" placeholder="Owner" style="padding-top: 4px;" panelClass="myPanelmatselectClass" class="inputClass" [disabled]="isDisabled"
                  (selectionChange)=selectowner($event.value) disableOptionCentering>
                    <mat-option [value]="" class="none_style">  None </mat-option>
                    <mat-option *ngFor="let item of companylistdata" [value]="item.companyName + ' ' +'('+ item.companyId + ')'">
                      <div class="matoptiontxt">{{item.companyName}}&nbsp;({{item.companyId}})</div>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-6 col-md-6 inputcolumn">
                <mat-label id="usernameid" class="labelname">Category</mat-label>
                <mat-form-field appearance="outline" class="formfieldusertable">
                  <mat-select formControlName="Category" placeholder="Category" style="padding-top: 4px;" class="inputClass" panelClass="myPanelmatselectClass"
                  (selectionChange)=selectCategory($event.value) disableOptionCentering>
                    <mat-option [value]="" class="none_style">  None </mat-option>
                    <mat-option *ngFor="let item of category" [value]="item.categoryName">
                      <div class="matoptiontxt">{{item.categoryName}}</div>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
        
              <div class="col-6 col-md-6 inputcolumn">
                <mat-label id="usernameid" class="labelname">Type</mat-label>
                <mat-form-field appearance="outline" class="formfieldusertable">
                  <mat-select formControlName="CompanyType" placeholder="Type" style="padding-top: 4px;" class="inputClass" panelClass="myPanelmatselectClass"
                  (selectionChange)=selectType($event.value) disableOptionCentering>
                    <mat-option [value]="" class="none_style">  None </mat-option>
                    <mat-option *ngFor="let item of type" [value]="item.typeName">
                      <div class="matoptiontxt">{{item.typeName}}</div>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-6 col-md-6 inputcolumn">
                <mat-label id="usernameid" class="labelname">Status</mat-label>
                <mat-form-field appearance="outline" class="formfieldusertable">
                  <mat-select formControlName="Status" placeholder="Status" style="padding-top: 4px;" panelClass="myPanelmatselectClass" class="inputClass"
                  (selectionChange)=selectStatusName($event.value) disableOptionCentering>
                    <mat-option [value]="" class="none_style">  None </mat-option>
                    <mat-option *ngFor="let item of statuslist" [value]="item.statusName">
                      <div class="matoptiontxt">{{item.statusName}}</div>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-6 col-md-6 inputcolumn">
                <mat-label id="usernameid" class="labelname">Shared Records</mat-label>
                <mat-form-field appearance="outline" class="formfieldusertable">
                  <mat-select formControlName="sharedRec" placeholder="Status" style="padding-top: 4px;" panelClass="myPanelmatselectClass" class="inputClass"
                  (selectionChange)=selectSharedRec($event.value) disableOptionCentering>
                    <mat-option [value]="" class="none_style">  None </mat-option>
                    <mat-option *ngFor="let item of sharedRec" [value]="item.statusName">
                      <div class="matoptiontxt">{{item.statusName}}</div>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-12 col-md-12 inputcolumn">
                <mat-label class="labelname">Completion Date Range</mat-label><br>
                <mat-form-field floatLabel="always" appearance="none" class="formfieldusertable">

                  <mat-date-range-input [rangePicker]="picker" (click)="picker.open()" [max]="today" style="cursor: pointer; margin-top: -1px;" class="textinputdate" placeholder="mm/dd/yyyy"
                  readonly>
                    <input matStartDate placeholder="mm/dd/yyyy" formControlName="start" (dateChange)="startChange($event)" style="cursor: pointer;" readonly>
                    <input matEndDate placeholder="mm/dd/yyyy" formControlName="end" (dateChange)="endChange($event)" style="cursor: pointer;" readonly>
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
             
              </div>

        </div>
        <div class="row">
            <div class="col-6 cancelbtncol"><br><br>
              <button mat-button id="btncancelid" class="cancelbtn" (click)="cancelbtn()">
                <mat-icon class="Cancelicon" alt="clear">clear</mat-icon>
                Cancel
              </button>
            </div>
            <div class="col-6 Filterbtncol"><br><br>
              <button mat-button id="btnfiltertermid" class="Filterbtn" [disabled]="disablebutton" (click)="Filter()">
                <mat-icon class="filterbtnicon">done</mat-icon>
                Filter
              </button>
            </div>
          </div>
      </form>
</div>