<div class="spinner_overlay" *ngIf="loadspinner===true">
</div>
<mat-spinner class="spinner" *ngIf="loadspinner===true"></mat-spinner>

<div class="CreateCompanymaincontainer">
  <div class="row header">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 lefts-sec">
    <img  src="assets/images/reset.svg" >
     <span class="Reset">Reset Password</span>
     <button type="button" class="close" aria-label="Close" (click)="cancel()"> <mat-icon class="clear-btn">clear</mat-icon></button>
    </div>

    <!-- <div class="col-2 close">
      <mat-icon class="clear-btn" alt="clear" (click)="cancel()">clear</mat-icon>
    </div> -->
  </div>
  

  <hr>
    <form [formGroup]="resetPassForm">
    <div class="contents-sec">

      <div class="col-sm-12 col-md-4 col-lg-4">
        <mat-label id="passwordfiled" class="passwordname"><span class="required"> *
        </span>New Password</mat-label>
        <br>
        
        <mat-form-field appearance="outline" id="formfieldpassword" class="formfield">
        <input type="password" matInput placeholder="Enter Password" autocomplete="off" #inppassword class="textinput"
        formControlName="passwordControl"   (change)="passValidation()"
        [type]="hidePassword ? 'text' : 'password'">
        <mat-icon matSuffix (click)="hidePassword = !hidePassword" class="hideshowicon">
        {{hidePassword ? 'visibility' : 'visibility_off'}}</mat-icon>
        <!-- <mat-error class="errorlabelname"
        *ngIf="resetPassForm.get('passwordControl').hasError('required')">
        <img src="assets/images/error.svg" class="errormsgicon" alt="error icon">
        &nbsp;
        <span>Please enter New Password</span>
        </mat-error>
        <mat-error *ngIf="resetPassForm.get('passwordControl').hasError('pattern')" class="mat-error">
          <img src="assets/images/error.svg" class="errormsgicon" alt="error icon"> &nbsp;
          Please enter a valid Password
        </mat-error>
        
            
        <mat-error *ngIf="((resetPassForm.get('passwordControl').hasError('minlength') ||
        resetPassForm.get('passwordControl').hasError('maxlength'))) && !(resetPassForm.get('passwordControl').hasError('pattern'))" class="mat-error">
        <img src="assets/images/error.svg" class="errormsgicon" alt="error icon">
        &nbsp;
        Password must contain 8 -16 char
        </mat-error> -->
      </mat-form-field>
      <div style="width:300%">
        <span class="errorCls"  *ngIf="policy1ExistError"><img src="assets/images/error.svg" class="errormsgicon" alt="error icon">&nbsp;{{phoneExistErrorMessage}}</span>
        <div *ngIf="spclcharExistError"><span class="errorCls"  *ngIf="spclcharExistError"><img src="assets/images/error.svg" class="errormsgicon" alt="error icon">&nbsp;Unsupported character, please delete.</span></div>
        <div class="errorCls" *ngIf="resetPassForm.get('passwordControl').invalid && (resetPassForm.get('passwordControl').touched || resetPassForm.get('passwordControl').dirty)">
  
          <div *ngIf="resetPassForm.get('passwordControl').hasError('required')">
            <img src="assets/images/error.svg" class="errormsgicon" alt="error icon">
            &nbsp;
            <span>Password is required</span>
          </div>
      
        </div>
      </div>
    </div>
    
    <div class="col-sm-12 col-md-4 col-lg-4" style="margin-top: 33px;">
    
    <mat-label id="confirmpasswordfiled" class="passwordname"><span class="required"> *
    </span>Confirm Password
    </mat-label>
    <br>
    <mat-form-field appearance="outline" id="formfieldconfirmpassword"
    class="formfield">
    <input type="password" matInput placeholder="Enter Confirm Password" autocomplete="off" #inpconfirmpassword
    class="textinput" formControlName="confirmpasswordControl"
    [type]="hideconfirmPassword ? 'text' : 'password'">
    <mat-icon matSuffix (click)="hideconfirmPassword = !hideconfirmPassword"
    class="hideshowicon">
    {{hideconfirmPassword ? 'visibility' : 'visibility_off'}}</mat-icon>
    
    <mat-error class="errorlabelname"
    *ngIf="resetPassForm.get('confirmpasswordControl').hasError('required')">
    <img src="assets/images/error.svg" class="errormsgicon" alt="error icon">
    &nbsp;
    <span>Please Confirm Password</span>
    </mat-error>
    <!-- <mat-error *ngIf="resetPassForm.get('confirmpasswordControl').hasError('pattern')" class="mat-error">
      <img src="assets/images/error.svg" class="errormsgicon" alt="error icon"> &nbsp;
      Please enter a valid Password
    </mat-error> -->
       
    
    <mat-error
    *ngIf=" resetPassForm.get('confirmpasswordControl').hasError('mustMatch')"
    class="mat-error">
    <img src="assets/images/error.svg" class="errormsgicon" alt="error icon">
    &nbsp;
    Password does not match
    </mat-error>
    </mat-form-field>
    </div>
    
    <div class="col-sm-12 col-md-4 col-lg-4" style="margin-top:25px;">
    <mat-checkbox labelPosition="after" style="margin-left: 1px;"  formControlName="changepasswordflagControl"
    id="changepassword_checkbox" [(ngModel)]="myVar1" (change)="update()" class="passwordnames create_select">Change Password At
    Next Login
    </mat-checkbox>
    </div>
    </div>
    <div class="row"  style="margin-top: 30px;">
      <div class="col-xl-2 col-lg-2 col-md-2 col-sm-0 col-xs-12"></div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12 cancel-btn-col"><br>
        <button mat-button  class="cancel" (click)="cancel()">
          <mat-icon class="Cancelicon" alt="clear">clear</mat-icon>
          Cancel</button>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12 Filterbtncol"><br>
        <button mat-button  type="submit" class="reset" (click)="onSubmits()">
          <mat-icon class="filterbtnicon">done</mat-icon>
          Reset</button>
      </div>
      <div class="col-xl-2 col-lg-2 col-md-2 col-sm-0 col-xs-12"></div>
    </div>
  

</form>
</div>